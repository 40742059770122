<template>
    <div class="examText">

        <!-- 头部 -->
        <div class="examText_header">
            <div class="examText_header_box">
                <p>
                    <!-- <img src="@/assets/logo.png" alt=""> -->
                    <!-- <span>萨恩教育</span> -->
                    <span style="margin-left:0;">中国会计网</span>
                </p>
                <p>
                    <span v-if="tabIndex=='3' || tabIndex=='4' || tabIndex=='5' || twoIindex=='1'">试卷总分{{examTextObj.paperTotalScore}}分</span>
                    <span v-if="tabIndex=='3' || tabIndex=='4' || tabIndex=='5' || twoIindex=='1'">答题时间{{examTextObj.paperAnswerTime}}分钟</span>
                </p>
            </div>
        </div>

        <!-- title -->
        <div class="examText_title">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/examcenter',query:{secondId:this.secondId,firstId:this.firstId} }">在线题库</el-breadcrumb-item>
                <el-breadcrumb-item v-if="tabIndex==3 || tabIndex==4 || tabIndex==5">{{examInfo.testPaperName}}</el-breadcrumb-item>
                <el-breadcrumb-item v-else>{{examInfo.nodeName}}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="examText_title_time">
                <img src="@/assets/exam/time.png" alt="">
                <span class="examText_title_time_text">已耗时：{{str}}</span>
                <el-button type="danger" @click="submit()">交卷</el-button>
            </div>
        </div>

        <!-- main -->
        <div class="examText_main">
            <!-- main_left -->
            <div class="examText_main_left">
                <!-- 单选-->
                <div class="examText_main_left_list" v-if="radioExamList.length>0">
                    <div class="examText_main_left_list_header">单项选择题</div>
                    <div class="examText_main_left_list_item">
                        <div v-for="(item,index) in radioExamList" :key="index" class="examText_main_left_list_item_box">
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_view'" @click="tabExamQuestion(item,1,index)" v-if="item.doneAnswerIds=='' || item.doneAnswerIds==null || item.doneAnswerIds==undefined">{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_viewing'" @click="tabExamQuestion(item,1,index)" v-else>{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <!--多选-->
                <div class="examText_main_left_list" v-if="checkboxExamList.length>0">
                    <div class="examText_main_left_list_header">多项选择题</div>
                    <div class="examText_main_left_list_item">
                        <div v-for="(item,index) in checkboxExamList" :key="index" class="examText_main_left_list_item_box">

                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_view'" @click="tabExamQuestion(item,2,index)" v-if="item.doneAnswerIds=='' || item.doneAnswerIds==null || item.doneAnswerIds==undefined">{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_viewing'" @click="tabExamQuestion(item,2,index)" v-else>{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <!--判断-->
                <div class="examText_main_left_list" v-if="estimateList.length>0">
                    <div class="examText_main_left_list_header">判断题</div>
                    <div class="examText_main_left_list_item">
                        <div v-for="(item,index) in estimateList" :key="index" class="examText_main_left_list_item_box">

                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_view'" @click="tabExamQuestion(item,3,index)" v-if="item.doneAnswerIds=='' || item.doneAnswerIds==null || item.doneAnswerIds==undefined">{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_viewing'" @click="tabExamQuestion(item,3,index)" v-else>{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>

                        </div>
                    </div>
                </div>
                <!--不定向 -->
                <div class="examText_main_left_list" v-if="budingxiang.length>0">
                    <div class="examText_main_left_list_header">不定项选择</div>
                    <div class="examText_main_left_list_item">
                        <div v-for="(item,index) in budingxiang" :key="index" class="examText_main_left_list_item_box">

                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_view'" @click="tabExamQuestion(item,4,index)" v-if="item.doneAnswerIds=='' || item.doneAnswerIds==null || item.doneAnswerIds==undefined">{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_viewing'" @click="tabExamQuestion(item,4,index)" v-else>{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <!--计算分析 -->
                <div class="examText_main_left_list" v-if="jisuanfenxi.length>0">
                    <div class="examText_main_left_list_header">计算分析</div>
                    <div class="examText_main_left_list_item">
                        <div v-for="(item,index) in jisuanfenxi" :key="index" class="examText_main_left_list_item_box">

                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_view'" @click="tabExamQuestion(item,5,index)" v-if="item.doneAnswer=='' || item.doneAnswer==null || item.doneAnswer==undefined">{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_viewing'" @click="tabExamQuestion(item,5,index)" v-else>{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <!--综合题 -->
                <div class="examText_main_left_list" v-if="synthesisList.length>0">
                    <div class="examText_main_left_list_header">综合题</div>
                    <div class="examText_main_left_list_item">
                        <div v-for="(item,index) in synthesisList" :key="index" class="examText_main_left_list_item_box">
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_view'" @click="tabExamQuestion(item,6,index)" v-if="item.doneAnswer=='' || item.doneAnswer==null || item.doneAnswer==undefined">{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_viewing'" @click="tabExamQuestion(item,6,index)" v-else>{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <!--简答题 -->
                <div class="examText_main_left_list" v-if="shortAnswerList.length>0">
                    <div class="examText_main_left_list_header">简答题</div>
                    <div class="examText_main_left_list_item">
                        <div v-for="(item,index) in shortAnswerList" :key="index" class="examText_main_left_list_item_box">
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_view'" @click="tabExamQuestion(item,7,index)" v-if="item.doneAnswer=='' || item.doneAnswer==null || item.doneAnswer==undefined">{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_viewing'" @click="tabExamQuestion(item,7,index)" v-else>{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <!--计算回答 -->
                <div class="examText_main_left_list" v-if="jisuanhuida.length>0">
                    <div class="examText_main_left_list_header">计算回答</div>
                    <div class="examText_main_left_list_item">
                        <div v-for="(item,index) in jisuanhuida" :key="index" class="examText_main_left_list_item_box">
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_view'" @click="tabExamQuestion(item,8,index)" v-if="item.doneAnswer=='' || item.doneAnswer==null || item.doneAnswer==undefined">{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_viewing'" @click="tabExamQuestion(item,8,index)" v-else>{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <!--案例分析 -->
                <div class="examText_main_left_list" v-if="anlifenxi.length>0">
                    <div class="examText_main_left_list_header">案例分析</div>
                    <div class="examText_main_left_list_item">
                        <div v-for="(item,index) in anlifenxi" :key="index" class="examText_main_left_list_item_box">
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_view'" @click="tabExamQuestion(item,9,index)" v-if="item.doneAnswer=='' || item.doneAnswer==null || item.doneAnswer==undefined">{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_viewing'" @click="tabExamQuestion(item,9,index)" v-else>{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <!--计算 -->
                <div class="examText_main_left_list" v-if="jisuan.length>0">
                    <div class="examText_main_left_list_header">计算</div>
                    <div class="examText_main_left_list_item">
                        <div v-for="(item,index) in jisuan" :key="index" class="examText_main_left_list_item_box">
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_view'" @click="tabExamQuestion(item,10,index)" v-if="item.doneAnswer=='' || item.doneAnswer==null || item.doneAnswer==undefined">{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_viewing'" @click="tabExamQuestion(item,10,index)" v-else>{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <!--综合分析 -->
                <div class="examText_main_left_list" v-if="zonghefenxi.length>0">
                    <div class="examText_main_left_list_header">综合分析</div>
                    <div class="examText_main_left_list_item">
                        <div v-for="(item,index) in zonghefenxi" :key="index" class="examText_main_left_list_item_box">
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_view'" @click="tabExamQuestion(item,11,index)" v-if="item.doneAnswer=='' || item.doneAnswer==null || item.doneAnswer==undefined">{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_viewing'" @click="tabExamQuestion(item,11,index)" v-else>{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                        </div>
                    </div>
                </div>

                <!-- <div class="examText_main_left_list" v-if="shortAnswerList.length>0">
                    <div class="examText_main_left_list_header">简答题</div>
                    <div class="examText_main_left_list_item" >
                        <div v-for="(item,index) in shortAnswerList" :key="index" class="examText_main_left_list_item_box">
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_view'" 
                                @click="tabExamQuestion(item,4,index)" v-if="item.doneAnswer=='' || item.doneAnswer==null || item.doneAnswer==undefined">{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                            <p :class="checkedExamId==item.subjectId?'examText_main_left_list_item_box_checked':'examText_main_left_list_item_box_viewing'" 
                                @click="tabExamQuestion(item,4,index)" v-else>{{index+1}}
                                <span style="" v-if="item.isSign==1"></span>
                            </p>
                        </div>
                    </div>
                </div> -->

                <div class="examText_main_left_explain">
                    <div class="examText_main_left_explain_header">
                        <span>注：</span><span>以题为例</span>
                    </div>
                    <div class="examText_main_left_explain_view">
                        <div class="examText_main_left_explain_view_item">
                            <p>当前题</p>
                            <p></p>
                        </div>
                        <div class="examText_main_left_explain_view_item">
                            <p>未做</p>
                            <p></p>
                        </div>
                    </div>
                    <div class="examText_main_left_explain_view">
                        <div class="examText_main_left_explain_view_item">
                            <p>已做</p>
                            <p></p>
                        </div>
                        <div class="examText_main_left_explain_view_item">
                            <p>标记题</p>
                            <p style="border:1px solid #DDDDDD;border-radius:50%;position:relative;overflow: hidden;width:18px;height:18px;">
                                <span style="width: 5px;height: 4px;font-size:8px;line-height:32px;background:orange;position:absolute;right:0;bottom:0;z-Index:2;padding:0 2em;-webkit-transform-origin:left top;-moz-transform-origin:left top;transform-origin:left top;-webkit-transform:translate(29.29%,100%) rotate(-45deg);-moz-transform:translate(29.29%,100%) rotate(-45deg);transform:translate(29.29%,100%) rotate(-45deg);text-indent:0;"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- main_right -->
            <div class="examText_main_right">

                <!-- 单选和判断 -->
                <div class="examText_main_right_radio" v-if="pageExamObj.classify=='1' || pageExamObj.classify=='3'">
                    <div class="examText_main_right_radio_header" v-if="pageExamObj.classify=='1'">单项选择题</div>
                    <div class="examText_main_right_radio_header" v-if="pageExamObj.classify=='3'">判断题</div>
                    <div class="examText_main_right_radio_question">
                        <div class="examText_main_right_radio_question_title"><span>{{indexIng+1}}、</span>{{pageExamObj.subjectTitle}}
                            <img v-if="pageExamObj.subjectTitlePic" :src="pageExamObj.subjectTitlePic" class="subjectTitlePic">
                        </div>
                        <div class="examText_main_right_radio_question_item">
                            <el-radio-group v-model="radio">
                                <el-radio class="f-v-c" v-for="(item,index) in pageExamObj.answerList" :key="index" :label="item.answerId">
                                    {{item.indexes}}、<span v-if="item.type==1" class="textWrap">{{item.answerName}}</span>
                                    <img :src="item.answerPic" alt="" class="answerPic" v-if="item.type==2">
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div class="examText_main_right_radio_answer" v-if="isShowAnalysis==true">
                        <!-- && pageExamObj.doneAnswerIds-->
                        <div class="examText_main_right_radio_answer_header">
                            <p>
                                <span>您的作答：</span><span>{{pageExamObj.myAnswer}}</span>
                            </p>
                            <p>
                                <span>参考答案：</span><span>{{pageExamObj.refertoAnswer}}</span>
                            </p>
                            <p v-if="analysisTestObj.totalAccuracy!='' && analysisTestObj.totalAccuracy!=null && analysisTestObj.totalAccuracy!=undefined">
                                <span>全站正确率：</span>
                                <span>{{analysisTestObj.totalAccuracy}}</span>
                            </p>
                        </div>
                        <div class="examText_main_right_radio_answer_summary" v-if="JSON.stringify(analysisTestObj)!='{}' && analysisTestObj!=null && analysisTestObj!=undefined">
                            <div class="examText_main_right_radio_answer_summary_title">本次总答题{{analysisTestObj.countNumber}}次</div>
                            <div class="examText_main_right_radio_answer_summary_box">
                                <p>答对：{{analysisTestObj.rightNumber}}次</p>
                                <p>答错：{{analysisTestObj.errorNumber}}次</p>
                                <p>正确率：{{analysisTestObj.accuracy}}%</p>
                            </div>
                        </div>
                        <div class="examText_main_right_radio_answer_analysis" v-if="pageExamObj.analysisText!='' && pageExamObj.analysisText!=undefined && pageExamObj.analysisText!=null">
                            <div class="examText_main_right_radio_answer_analysis_title">答案解析：</div>
                            <div class="examText_main_right_radio_answer_analysis_text" v-html="pageExamObj.analysisText"></div>
                        </div>
                        <div class="examText_main_right_radio_answer_video" v-if="pageExamObj.analysisVideo!=null && pageExamObj.analysisVideo!='' && pageExamObj.analysisVideo!=undefined">
                            <div class="examText_main_right_radio_answer_video_title">视频解析：</div>
                            <img src="@/assets/course/play_icon.png" alt="" @click="goVideo()">
                            <!-- <video :src="pageExamObj.analysisVideo"></video> -->
                        </div>
                        <div class="examText_main_right_radio_answer_testing" v-if="JSON.stringify(pageExamObj.knowledgeList)!='[]' && pageExamObj.knowledgeList!=undefined && pageExamObj.knowledgeList!=null">
                            <p>考点：</p>
                            <p>
                                <span v-for="(item,key) in pageExamObj.knowledgeList" :key="key">{{item.knowledgeName}}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <!-- 多选 -->
                <div class="examText_main_right_checkBox" v-if="pageExamObj.classify=='2'">
                    <div class="examText_main_right_checkBox_header">多项选择题</div>
                    <div class="examText_main_right_checkBox_question">
                        <div class="examText_main_right_checkBox_question_title"><span>{{indexIng+1}}、</span>{{pageExamObj.subjectTitle}}
                            <img v-if="pageExamObj.subjectTitlePic" :src="pageExamObj.subjectTitlePic" class="subjectTitlePic">
                        </div>
                        <div class="examText_main_right_checkBox_question_item">
                            <el-checkbox-group v-model="checkList">
                                <el-checkbox class="f-v-c" v-for="(item,index) in pageExamObj.answerList" :key="index" :label="item.answerId">
                                    {{item.indexes}}、{{item.answerName}}<img :src="item.answerPic" class="answerPic" alt="" v-if="item.type==2">
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                    <div class="examText_main_right_checkBox_answer" v-if="isShowAnalysis==true">
                        <!-- && pageExamObj.doneAnswerIds!='' && pageExamObj.doneAnswerIds!=null && pageExamObj.doneAnswerIds!=undefined" -->
                        <div class="examText_main_right_checkBox_answer_header">
                            <p>
                                <span>您的作答：</span><span>{{pageExamObj.myAnswer}}</span>
                            </p>
                            <p>
                                <span>参考答案：</span><span>{{pageExamObj.refertoAnswer}}</span>
                            </p>
                            <p v-if="analysisTestObj.totalAccuracy!='' && analysisTestObj.totalAccuracy!=null && analysisTestObj.totalAccuracy!=undefined">
                                <span>全站正确率：{{analysisTestObj.totalAccuracy}}</span>
                            </p>
                        </div>
                        <div class="examText_main_right_checkBox_answer_summary" v-if="JSON.stringify(analysisTestObj)!='{}' && analysisTestObj!=null && analysisTestObj!=undefined">
                            <div class="examText_main_right_checkBox_answer_summary_title">本次总答题{{analysisTestObj.countNumber}}次</div>
                            <div class="examText_main_right_checkBox_answer_summary_box">
                                <p>答对：{{analysisTestObj.rightNumber}}次</p>
                                <p>答错：{{analysisTestObj.errorNumber}}次</p>
                                <p>正确率：{{analysisTestObj.accuracy}}%</p>
                            </div>
                        </div>
                        <div class="examText_main_right_checkBox_answer_analysis" v-if="pageExamObj.analysisText!='' && pageExamObj.analysisText!=undefined && pageExamObj.analysisText!=null">
                            <div class="examText_main_right_checkBox_answer_analysis_title">答案解析：</div>
                            <div class="examText_main_right_checkBox_answer_analysis_text" v-html="pageExamObj.analysisText"></div>
                        </div>
                        <div class="examText_main_right_checkBox_answer_video" v-if="pageExamObj.analysisVideo!='' && pageExamObj.analysisVideo!=undefined && pageExamObj.analysisVideo!=null">
                            <div class="examText_main_right_checkBox_answer_video_title">视频解析：</div>
                            <img src="@/assets/course/play_icon.png" alt="" @click="goVideo()">
                            <!-- <video :src="pageExamObj.analysisVideo"></video> -->
                        </div>
                        <div class="examText_main_right_checkBox_answer_testing" v-if="JSON.stringify(pageExamObj.knowledgeList)!='[]' && pageExamObj.knowledgeList!=undefined && pageExamObj.knowledgeList!=null">
                            <p>考点：</p>
                            <p>
                                <span v-for="(item,key) in pageExamObj.knowledgeList" :key="key">{{item.knowledgeName}}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <!-- 不定项 -->
                <div class="examText_main_right_synthesis" v-if="pageExamObj.classify=='4'">
                    <div class="examText_main_right_synthesis_header">不定项选择</div>
                    <div class="examText_main_right_synthesis_question">
                        <div class="examText_main_right_synthesis_question_title update_font_size" >
                            <span>{{indexIng+1}}、</span>
                            <span v-html="pageExamObj.subjectTitle"></span>
                            <img v-if="pageExamObj.subjectTitlePic" :src="pageExamObj.subjectTitlePic" class="subjectTitlePic">
                        </div>
                    </div>
                    <div class="examText_main_right_synthesis_inputBox">
                        <div class="examText_main_right_synthesis_inputBox_header">
                            <div class="examText_main_right_synthesis_inputBox_header_item">
                                <span v-for="(item,index) in pageExamObj.subjectList" :key="index" :class="smallQuestionIndex == item.subjectId?'checked':''" @click="tabSmallQuestion(item,index)">第{{index+1}}小题</span>
                            </div>

                            <!-- <div class="examText_main_right_synthesis_inputBox_header_item">
                                <p>
                                    <img src="" alt="">
                                    <span>公式</span>
                                </p>
                                <p>标记文字</p>
                                <p>取消文字</p>
                            </div> -->
                        </div>
                        <!-- <el-input  type="textarea"  :rows="6"  placeholder="请输入内容"  v-model="synthesisTextarea"></el-input> -->
                    </div>
                    <div class="examText_main_right_synthesis_question_text" style="text-align: left;margin: 20px 1em;" v-if="pageExamObj.subjectList && pageExamObj.subjectList.length">
                        <!-- v-for="(item,index) in pageExamObj.subjectList" :key="index"({{index+1}}) -->
                        {{smallQuestionObj.subjectTitle}}
                        <img v-if="smallQuestionObj.subjectTitlePic" :src="smallQuestionObj.subjectTitlePic" class="subjectTitlePic">
                    </div>
                    <div class="examText_main_right_checkBox_question_option">
                        <div class="examText_main_right_checkBox_question_item">
                            <el-checkbox-group v-model="checkList" @change="save_subject_Tab">
                                <el-checkbox class="f-v-c" v-for="(item,index) in smallQuestionObj.answerList" :key="index" :label="item.answerId">{{item.indexes}}、{{item.answerName}}<img :src="item.answerPic" class="answerPic" alt="" v-if="item.type==2"></el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                    <div class="examText_main_right_synthesis_answer" v-if="isShowAnalysis==true ">
                        <div class="examText_main_right_synthesis_answer_header">
                            <p>
                                <span>您的作答：</span><span>{{pageExamObj.myAnswer}}</span>
                            </p>
                            <p>
                                <span>参考答案：</span><span>{{pageExamObj.refertoAnswer}}</span>
                            </p>
                            <p v-if="analysisTestObj.totalAccuracy!='' && analysisTestObj.totalAccuracy!=null && analysisTestObj.totalAccuracy!=undefined">
                                <span>全站正确率：{{analysisTestObj.totalAccuracy}}</span>
                            </p>
                        </div>
                        <div class="examText_main_right_synthesis_answer_summary" v-if="JSON.stringify(analysisTestObj)!='{}' && analysisTestObj!=null && analysisTestObj!=undefined">
                            <div class="examText_main_right_synthesis_answer_summary_title">本次总答题{{analysisTestObj.countNumber}}次</div>
                            <div class="examText_main_right_synthesis_answer_summary_box">
                                <p>答对：{{analysisTestObj.rightNumber}}次</p>
                                <p>答错：{{analysisTestObj.errorNumber}}次</p>
                                <p>正确率：{{analysisTestObj.accuracy}}%</p>
                            </div>
                        </div>
                        <div class="examText_main_right_shortAnswer_answer_analysis">
                            <div class="examText_main_right_shortAnswer_answer_analysis_title">答案解析：</div>
                            <!-- <div class="examText_main_right_shortAnswer_answer_analysis_text" v-html="pageExamObj.analysisText"></div> -->
                            <div class="examText_main_right_shortAnswer_answer_analysis_text" v-for="(item,index) in pageExamObj.subjectList" :key="index">
                                <p v-if="smallQuestionIndex == item.subjectId" v-html="item.analysisText"></p>
                            </div>
                        </div>
                        <div class="examText_main_right_shortAnswer_answer_video" v-if="pageExamObj.analysisVideo!='' && pageExamObj.analysisVideo!=undefined && pageExamObj.analysisVideo!=null">
                            <div class="examText_main_right_shortAnswer_answer_video_title">视频解析：</div>
                            <img src="@/assets/course/play_icon.png" alt="" @click="goVideo()">
                            <!-- <video :src="pageExamObj.analysisVideo"></video> -->
                        </div>
                        <div class="examText_main_right_shortAnswer_answer_testing" v-if="JSON.stringify(pageExamObj.knowledgeList)!='[]' && pageExamObj.knowledgeList!=undefined && pageExamObj.knowledgeList!=null">
                            <p>考点：</p>
                            <p>
                                <span v-for="(item,key) in pageExamObj.knowledgeList" :key="key">{{item.knowledgeName}}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <!-- 分析题 -->
                <div class="examText_main_right_shortAnswer " v-if="pageExamObj.classify=='5' || pageExamObj.classify=='6' || pageExamObj.classify=='7' || pageExamObj.classify=='8' || pageExamObj.classify=='9' || pageExamObj.classify=='10' || pageExamObj.classify=='11'">
                    <div class="examText_main_right_shortAnswer_header" v-if="pageExamObj.classify=='5'">计算分析</div>
                    <div class="examText_main_right_shortAnswer_header" v-if="pageExamObj.classify=='6'">综合题</div>
                    <div class="examText_main_right_shortAnswer_header" v-if="pageExamObj.classify=='7'">简答题</div>
                    <div class="examText_main_right_shortAnswer_header" v-if="pageExamObj.classify=='8'">计算回答</div>
                    <div class="examText_main_right_shortAnswer_header" v-if="pageExamObj.classify=='9'">案例分析</div>
                    <div class="examText_main_right_shortAnswer_header" v-if="pageExamObj.classify=='10'">计算</div>
                    <div class="examText_main_right_shortAnswer_header" v-if="pageExamObj.classify=='11'">综合分析</div>
                    
                    <div class="examText_main_right_shortAnswer_question">
                        <div class="examText_main_right_shortAnswer_question_title update_font_size " id="">
                            <span>{{indexIng+1}}、</span>
                            <span v-html="pageExamObj.subjectTitle"></span>
                            <img v-if="pageExamObj.subjectTitlePic" :src="pageExamObj.subjectTitlePic" class="subjectTitlePic">
                        </div>
                    </div>
                    <div class="examText_main_right_synthesis_inputBox">
                        <div class="examText_main_right_synthesis_inputBox_header">
                            <div class="examText_main_right_synthesis_inputBox_header_item">
                                <span v-for="(item,index) in pageExamObj.subjectList" :key="index" :class="smallQuestionIndex==item.subjectId?'checked':''" @click="tabSmallQuestion(item,index)">第{{index+1}}小题</span>
                            </div>

                            <!-- <div class="examText_main_right_synthesis_inputBox_header_item">
                                <p>
                                    <img src="" alt="">
                                    <span>公式</span>
                                </p>
                                <p>标记文字</p>
                                <p>取消文字</p>
                            </div> -->
                        </div>
                        <div style="display: flex;margin-top: 10px;text-align: left;" v-if="pageExamObj.subjectList.length>0">{{smallQuestionObj.subjectTitle}}</div>
                        <!-- <el-input type="textarea" :rows="10" placeholder="请输入内容" v-model="testTextarea"></el-input> -->
                        <div class="m-tb-10">
                            <vue-ueditor-wrap v-model="testTextarea" :config="myConfig"></vue-ueditor-wrap>
                        </div>

                        <div class="examText_main_right_synthesis_inputBox_score" v-if="tabIndex=='3' || tabIndex=='4' || tabIndex=='5'">
                            <el-input v-model="score" placeholder="请输入自评分" maxlength="3" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                        </div>
                    </div>
                    <div class="examText_main_right_shortAnswer_answer" v-if="isShowAnalysis==true">
                        <div class="examText_main_right_shortAnswer_answer_analysis" v-if="pageExamObj.analysisText!='' && pageExamObj.analysisText!=undefined && pageExamObj.analysisText!=null">
                            <div class="examText_main_right_shortAnswer_answer_analysis_title">答案解析：</div>
                            <div class="examText_main_right_shortAnswer_answer_analysis_text" v-html="pageExamObj.analysisText"></div>
                        </div>
                        <div class="examText_main_right_shortAnswer_answer_video" v-if="pageExamObj.analysisVideo!='' && pageExamObj.analysisVideo!=undefined && pageExamObj.analysisVideo!=null">
                            <div class="examText_main_right_shortAnswer_answer_video_title">视频解析：</div>
                            <img src="@/assets/course/play_icon.png" alt="" @click="goVideo()">
                            <!-- <video :src="pageExamObj.analysisVideo"></video> -->
                        </div>
                        <div class="examText_main_right_shortAnswer_answer_testing" v-if="JSON.stringify(pageExamObj.knowledgeList)!='[]' && pageExamObj.knowledgeList!=undefined && pageExamObj.knowledgeList!=null">
                            <p>考点：</p>
                            <p>
                                <span v-for="(item,key) in pageExamObj.knowledgeList" :key="key">{{item.knowledgeName}}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <!-- 上一题 下一题 标记 计算器 收藏 -->
                <div class="examText_main_right_operate">
                    <div class="examText_main_right_operate_btn">
                        <el-button @click="uptopic()">上一题</el-button>
                        <el-button type="primary" @click="save_subject()" v-if="!isEndSubject">下一题</el-button>
                    </div>
                    <div class="examText_main_right_operate_other">
                        <p @click="stamp()">
                            <img src="" alt="">
                            <span v-if="pageExamObj.isSign==2">标记此题</span>
                            <span v-if="pageExamObj.isSign==1">取消标记</span>
                        </p>

                        <!-- 计算器 -->
                        <div>
                            <el-popover placement="top" width="300" trigger="hover" v-model="visible">
                                <div id="box">
                                    <table>
                                        <tr>
                                            <td><input type="button" value="del" @click="del()"></td>
                                            <td><input type="button" value="C" @click="clean()"></td>
                                            <td colspan="2"><input type="text" style="width: 100px" value="" v-model="rel"></td>

                                        </tr>
                                        <tr>
                                            <td><input type="button" value="7" @click="add('7')"></td>
                                            <td><input type="button" value="8" @click="add('8')"></td>
                                            <td><input type="button" value="9" @click="add('9')"></td>
                                            <td><input type="button" value="/" @click="add('/')"></td>
                                        </tr>

                                        <tr>
                                            <td><input type="button" value="4" @click="add('4')"></td>
                                            <td><input type="button" value="5" @click="add('5')"></td>
                                            <td><input type="button" value="6" @click="add('6')"></td>
                                            <td><input type="button" value="*" @click="add('*')"></td>
                                        </tr>

                                        <tr>
                                            <td><input type="button" value="1" @click="add('1')"></td>
                                            <td><input type="button" value="2" @click="add('2')"></td>
                                            <td><input type="button" value="3" @click="add('3')"></td>
                                            <td><input type="button" value="-" @click="add('-')"></td>
                                        </tr>

                                        <tr>
                                            <td><input type="button" value="0" @click="add('0')"></td>
                                            <td><input type="button" value="." @click="add('.')"></td>
                                            <td><input type="button" value="+" @click="add('+')"></td>
                                            <td><input type="button" value="=" v-on:click="result()"></td>
                                        </tr>
                                    </table>
                                </div>

                                <span slot="reference" @click="visible = !visible">
                                    <span style="cursor:pointer;color:#5F7CFD;margin-left:20px;">计算器</span>
                                </span>
                            </el-popover>
                        </div>
                        <p @click="lookAnalysis" v-if="isShowAnalysis==false && tabIndex !='3' && tabIndex !='4' && tabIndex !='5'">查看解析</p>
                        <!-- && pageExamObj.doneAnswerIds!='' && pageExamObj.doneAnswerIds!=null && pageExamObj.doneAnswerIds!=undefined -->
                        <p @click="lookAnalysis" v-if="isShowAnalysis==true && tabIndex !='3' && tabIndex !='4' && tabIndex !='5'">隐藏解析</p>
                        <p v-if="pageExamObj.isCollect==2" @click="tabCollec()">收藏</p>
                        <p v-if="pageExamObj.isCollect==1" @click="tabCollec()">取消收藏</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { Loading } from 'element-ui';
import {
    subject_list,
    save_subject,
    add_sign,
    add_collect,
    deleted_collect,
    assignment_paper,
    subjectPaperList,
    savePaper_subject,
    assignment_exercise,
    error_exercise_subjectList,
    error_paper_subjectList,
    analysis_list
} from "@/api/exam";
import VueUeditorWrap from 'vue-ueditor-wrap';

export default {
    name: 'examText',
    components: {
        VueUeditorWrap
    },
    data() {
        return {
            radio: '', //选中id
            checkList: [], //多选id
            examType: '0', // 
            examInfo: {}, // 试卷的上级信息
            num: '', // 重做  还是开始
            tabIndex: '', // 章节 专项等
            paperChapterSubjectList: [], // 所有题
            examTextObj: {}, // 试卷的信息

            radioExamList: [], // 单选题集合
            checkboxExamList: [], // 多选题集合
            estimateList: [], // 判断体集合
            budingxiang: [], //不定向选择题
            jisuanfenxi: [], //计算分析题
            synthesisList: [], // 综合体集合
            shortAnswerList: [], // 简答题集合
            jisuanhuida: [], // 计算回答
            anlifenxi: [], //案例分析
            jisuan: [], //计算
            zonghefenxi: [], //综合分析


            pageExamObj: {}, //每一题的信息
            checkedExamId: '', // 左边栏选中的题id
            // titleIndex:0, // 
            isShowAnalysis: false, // 是否显示解析


            hour: 0, //定义时，分，秒，毫秒并初始化为0；
            minute: 0,
            second: 0, //秒
            time: '',
            str: '',
            second_time: 0,

            indexIng: 0, // 点击每个数组的index
            testTextarea: '', // 简答题的textarea
            smallQuestionIndex: '0', // 综合题的小题index
            recordTime: null,
            firstId: '', // 一级分类id
            secondId: '', // 二级分类id 
            score: '', // 自评分分数
            twoIindex: '', //0、专项章节 1、考试
            isUserInfo: false, // 是否有登录
            checkedValue: [], // 不定项
            smallQuestionObj: {}, // 小题的对象
            analysisTestList: [], // 解析题列表
            visible: false, // 是否显示计算器
            rel: "", // 计算
            analysisTestObj: {}, // 解析题对象
            nodeId: '',
            testMounted: 0,
            testSecond: 0,
            testSecond_time: 0,
            testMinute: 0,
            testHour: 0,
            testChangeSecond: 0, // 考试一直变化时间
            isEndSubject: false,

            myConfig: {
                // 如果需要上传功能,找后端小伙伴要服务器接口地址
                serverUrl: '',
                // 你的UEditor资源存放的路径,相对于打包后的index.html
                UEDITOR_HOME_URL: '/UEditor/',
                // 编辑器不自动被内容撑高
                autoHeightEnabled: false,
                // 初始容器高度
                initialFrameHeight: 300,
                // 初始容器宽度
                initialFrameWidth: '100%',
                // 关闭自动保存
                enableAutoSave: false,
                // 字数
                wordCount: false,
                // 元素路径
                elementPathEnabled: false,
                // 图片拉伸
                imageScaleEnabled: false,
                // 右键菜单
                // enableContextMenu: false,
                // 自定义工具栏，需要额外选项可以参考ueditor.config.js
                toolbars: [
                    [
                        'bold', 'italic', 'underline', '|', 'fontsize', 'fontfamily', '|', 'kityformula',
                        'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|',
                    ]
                ],
                contextMenu: [
                    {
                        label: '',
                        cmdName: 'selectall',
                    },
                    {
                        label: '',
                        cmdName: 'cleardoc',
                        exec: function () {
                            if (confirm('确定清空当前文档么？')) {
                                this.execCommand("cleardoc");
                            }
                        }
                    }
                ]
            },

            loading: null,
        }
    },
    created(){
        this.loading = Loading.service({text:'Loading...',background:'rgba(0, 0, 0, 0.1)'})
    },
    mounted() {
        var that = this
        var pageQuery = that.$route.query;

        var examTextInfo = localStorage.getItem("examText")
        if (examTextInfo) {
            examTextInfo = JSON.parse(examTextInfo)
            that.examInfo = examTextInfo.chapterListItem
            if (pageQuery && pageQuery.value) {
                this.num = pageQuery.value;
            } else {
                that.num = examTextInfo.value
            }
            that.secondId = examTextInfo.secondId
            that.firstId = examTextInfo.firstId
        }
        that.tabIndex = pageQuery.tabIndex

        if (that.tabIndex == '1' || that.tabIndex == '2') {
            that.subject_list(() => {
                that.time = setInterval(that.timer, 1000)
            })
        } else if (that.tabIndex == '3' || that.tabIndex == '4' || that.tabIndex == '5') {
            examTextInfo.value == 1 && (that.num = '')
            that.testPaperId = pageQuery.testPaperId
            that.subjectPaperList(() => {
                that.time = setInterval(that.deltimer, 1000)
            })
        } else if (that.tabIndex == '6') {
            that.twoIindex = examTextInfo.twoIindex
            if (examTextInfo.twoIindex == '0') {
                that.subject_list()
            } else {
                examTextInfo.value == 1 && (that.num = '')
                that.testPaperId = pageQuery.testPaperId
                that.subjectPaperList()
            }
        } else if (that.tabIndex == '8') {
            that.twoIindex = examTextInfo.twoIindex;

            if (examTextInfo.twoIindex == '0') {
                that.error_exercise_subjectList()
            } else {
                that.testPaperId = pageQuery.testPaperId
                that.error_paper_subjectList()
            }
        }

        // if(that.tabIndex=='1' || that.tabIndex=='2'){
        //     that.time = setInterval(that.timer,1000)

        // }else if(that.tabIndex=='3' || that.tabIndex=='4' || that.tabIndex=='5'){
        //     that.time = setInterval(that.deltimer,1000)
        // }
        that.getAnalysisList()
        
        
    },
    watch: {},
    methods: {
        update_size(){
            if(document.getElementsByClassName('update_font_size') != undefined && document.getElementsByClassName('update_font_size') != null){
                var sizes = document.getElementsByClassName('update_font_size')
               
                for(var i=0;i<sizes.length;i++){
                    if(sizes[i].getElementsByTagName('font')){
                        
                        var classsize = sizes[i].getElementsByTagName('font');
                       
                        for(var j=0;j<classsize.length;j++){
                            
                            classsize[j].setAttribute('size','4px');
                        }
                      
                    }
                    
                }


            }
            

        },
        // 去视频
        goVideo() {
            var url = 'https://share.plvideo.cn/front/video/preview?vid=' + this.pageExamObj.analysisVideo
            // let routeData = this.$router.resolve({ path: url, query: {} }); 
            window.open(url, '_blank');
        },
        // 计算器
        add(index) { //这里就是按键绑定的方法，把获取到的值拼接到rel字符串上
            this.rel += index;
        },
        result() {
            this.rel = eval(this.rel); //这里是用eval方法进行一个计算
            this.rel = String(this.rel); //这里的目的是为了显示在显示栏的数字还是字符串，只有字符串才能进行退格，归零的操作
        },
        del() { //这个就是退格的操作，用字符串的substring方法截取，每一次截取都是从第0个开始，到长度的前一个，就相当于退格了。
            this.rel = this.rel.substring(0, this.rel.length - 1);
        },
        clean() { //这里就是归零的方法，通过给结果赋一个空字符串的方法来实现归零的操作，当然也可以使用删除的方法，例如unshift方法或者pop方法，直接赋值为空比较简便。
            this.rel = "";
        },
        // 父传子
        getTodos(e) {
            this.isUserInfo = e
        },
        // 判断是否为最后一题
        judgeEndSubject() {
            let pageExamObj = this.pageExamObj;
            let endSubjectId = '';
            let paperChapterSubjectList = this.paperChapterSubjectList;
            let listEndSubjectId = '';
            if (pageExamObj.subjectList.length) {
                endSubjectId = pageExamObj.subjectList[pageExamObj.subjectList.length - 1].subjectId;
            } else {
                endSubjectId = pageExamObj.subjectId;
            }
            if (paperChapterSubjectList.length) {
                let listEndSubjectObj = paperChapterSubjectList[paperChapterSubjectList.length - 1];
                if (listEndSubjectObj.subjectList.length) {
                    listEndSubjectId = listEndSubjectObj.subjectList[listEndSubjectObj.subjectList.length - 1].subjectId;
                } else {
                    listEndSubjectId = listEndSubjectObj.subjectId;
                }
            }
   
            // 是否为最后一题
            if (endSubjectId == listEndSubjectId) {
                this.isEndSubject = true;
            } else if (this.isEndSubject) {
                this.isEndSubject = false;
            }
        },
        // 章节-答题获取题列表(重做)
        error_exercise_subjectList() {
            var obj = {
                testPaperId: this.examInfo.testPaperId
            }
            error_exercise_subjectList(obj).then(res => {
                if (res.data.code == 0) {
                    this.examTextObj = res.data.data
                    this.pageExamObj = res.data.data[0]
                    for (var i in res.data.data) {
                        if (res.data.data[i].classify == "1") {
                            this.radioExamList.push(res.data.data[i])
                        } else if (res.data.data[i].classify == "2") {
                            this.checkboxExamList.push(res.data.data[i])
                        } else if (res.data.data[i].classify == "3") {
                            this.estimateList.push(res.data.data[i])
                        } else if (res.data.data[i].classify == "4") {
                            this.shortAnswerList.push(res.data.data[i])
                        } else if (res.data.data[i].classify == "5") {
                            this.synthesisList.push(res.data.data[i])
                            if (res.data.data[i].subjectList.length > 0) {
                                this.smallQuestionIndex = this.synthesisList[0].subjectList[0].subjectId
                                this.smallQuestionObj = this.synthesisList[0].subjectList[0]
                            }
                        }
                    }
                } else {
                    this.$message.error(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 试卷-答题获取题列表(重做)
        error_paper_subjectList() {
            var obj = {
                testPaperId: this.testPaperId
            }
            error_paper_subjectList(obj).then(res => {
                if (res.data.code == 0) {
                    this.examTextObj = res.data.data
                    this.pageExamObj = res.data.data[0]
                    for (var i in res.data.data) {
                        if (res.data.data[i].classify == "1") {
                            this.radioExamList.push(res.data.data[i])
                        } else if (res.data.data[i].classify == "2") {
                            this.checkboxExamList.push(res.data.data[i])
                        } else if (res.data.data[i].classify == "3") {
                            this.estimateList.push(res.data.data[i])
                        } else if (res.data.data[i].classify == "4") {
                            this.shortAnswerList.push(res.data.data[i])
                        } else if (res.data.data[i].classify == "5") {
                            this.synthesisList.push(res.data.data[i])
                            if (res.data.data[i].subjectList.length > 0) {
                                this.smallQuestionIndex = this.synthesisList[0].subjectList[0].subjectId
                            }
                        }
                    }
                } else {
                    this.$message.error(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 删除收藏
        deleted_collect(obj) {
            deleted_collect(obj).then(res => {
                if (res.data.code == 0) {
                    this.pageExamObj.isCollect == 2
                    if (this.pageExamObj.classify == '1') {
                        for (var i in this.radioExamList) {
                            if (this.radioExamList[i].subjectId == this.pageExamObj.subjectId) {
                                this.radioExamList[i].isCollect = 2
                            }
                        }
                    } else if (this.pageExamObj.classify == '2') {
                        for (var i in this.checkboxExamList) {
                            if (this.checkboxExamList[i].subjectId == this.pageExamObj.subjectId) {
                                this.checkboxExamList[i].isCollect = 2
                            }
                        }
                    } else if (this.pageExamObj.classify == '3') {
                        for (var i in this.estimateList) {
                            if (this.estimateList[i].subjectId == this.pageExamObj.subjectId) {
                                this.estimateList[i].isCollect = 2
                            }
                        }

                    } else if (this.pageExamObj.classify == '4') {
                        for (var i in this.budingxiang) {
                            if (this.budingxiang[i].subjectId == this.pageExamObj.subjectId) {
                                this.budingxiang[i].isCollect = 2
                            }
                        }

                    } else if (this.pageExamObj.classify == '5') {
                        for (var i in this.jisuanfenxi) {
                            if (this.jisuanfenxi[i].subjectId == this.pageExamObj.subjectId) {
                                this.jisuanfenxi[i].isCollect = 2
                            }
                        }

                    } else if (this.pageExamObj.classify == '6') {
                        for (var i in this.synthesisList) {
                            if (this.synthesisList[i].subjectId == this.pageExamObj.subjectId) {
                                this.synthesisList[i].isCollect = 2
                            }
                        }

                    } else if (this.pageExamObj.classify == '7') {
                        for (var i in this.shortAnswerList) {
                            if (this.shortAnswerList[i].subjectId == this.pageExamObj.subjectId) {
                                this.shortAnswerList[i].isCollect = 2
                            }
                        }

                    } else if (this.pageExamObj.classify == '8') {
                        for (var i in this.jisuanhuida) {
                            if (this.jisuanhuida[i].subjectId == this.pageExamObj.subjectId) {
                                this.jisuanhuida[i].isCollect = 2
                            }
                        }

                    } else if (this.pageExamObj.classify == '9') {
                        for (var i in this.anlifenxi) {
                            if (this.anlifenxi[i].subjectId == this.pageExamObj.subjectId) {
                                this.anlifenxi[i].isCollect = 2
                            }
                        }

                    } else if (this.pageExamObj.classify == '10') {
                        for (var i in this.jisuan) {
                            if (this.jisuan[i].subjectId == this.pageExamObj.subjectId) {
                                this.jisuan[i].isCollect = 2
                            }
                        }

                    } else if (this.pageExamObj.classify == '11') {
                        for (var i in this.zonghefenxi) {
                            if (this.zonghefenxi[i].subjectId == this.pageExamObj.subjectId) {
                                this.zonghefenxi[i].isCollect = 2
                            }
                        }

                    }

                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 添加收藏
        add_collect(obj) {
            add_collect(obj).then(res => {
                if (res.data.code == 0) {
                    this.pageExamObj.isCollect == 1
                    if (this.pageExamObj.classify == '1') {
                        this.pageExamObj.isCollect == 1
                        for (var i in this.radioExamList) {
                            if (this.radioExamList[i].subjectId == this.pageExamObj.subjectId) {
                                this.radioExamList[i].isCollect = 1
                            }
                        }
                    } else if (this.pageExamObj.classify == '2') {

                        for (var i in this.checkboxExamList) {
                            if (this.checkboxExamList[i].subjectId == this.pageExamObj.subjectId) {
                                this.checkboxExamList[i].isCollect = 1
                            }
                        }
                    } else if (this.pageExamObj.classify == '3') {

                        for (var i in this.estimateList) {
                            if (this.estimateList[i].subjectId == this.pageExamObj.subjectId) {
                                this.estimateList[i].isCollect = 1
                            }
                        }
                    } else if (this.pageExamObj.classify == '4') {

                        for (var i in this.budingxiang) {
                            if (this.budingxiang[i].subjectId == this.pageExamObj.subjectId) {
                                this.budingxiang[i].isCollect = 1
                            }
                        }
                    } else if (this.pageExamObj.classify == '5') {

                        for (var i in this.jisuanfenxi) {
                            if (this.jisuanfenxi[i].subjectId == this.pageExamObj.subjectId) {
                                this.jisuanfenxi[i].isCollect = 1
                            }
                        }
                    } else if (this.pageExamObj.classify == '6') {

                        for (var i in this.synthesisList) {
                            if (this.synthesisList[i].subjectId == this.pageExamObj.subjectId) {
                                this.synthesisList[i].isCollect = 1
                            }
                        }
                    } else if (this.pageExamObj.classify == '7') {

                        for (var i in this.shortAnswerList) {
                            if (this.shortAnswerList[i].subjectId == this.pageExamObj.subjectId) {
                                this.shortAnswerList[i].isCollect = 1
                            }
                        }
                    } else if (this.pageExamObj.classify == '8') {

                        for (var i in this.jisuanhuida) {
                            if (this.jisuanhuida[i].subjectId == this.pageExamObj.subjectId) {
                                this.jisuanhuida[i].isCollect = 1
                            }
                        }
                    } else if (this.pageExamObj.classify == '9') {

                        for (var i in this.anlifenxi) {
                            if (this.anlifenxi[i].subjectId == this.pageExamObj.subjectId) {
                                this.anlifenxi[i].isCollect = 1
                            }
                        }
                    } else if (this.pageExamObj.classify == '10') {

                        for (var i in this.jisuan) {
                            if (this.jisuan[i].subjectId == this.pageExamObj.subjectId) {
                                this.jisuan[i].isCollect = 1
                            }
                        }
                    } else if (this.pageExamObj.classify == '11') {

                        for (var i in this.zonghefenxi) {
                            if (this.zonghefenxi[i].subjectId == this.pageExamObj.subjectId) {
                                this.zonghefenxi[i].isCollect = 1
                            }
                        }
                    }

                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 点击收藏
        tabCollec() {
            if (this.pageExamObj.isCollect == 1) {
                var obj = {
                    subjectIds: this.pageExamObj.subjectId,
                }
                this.deleted_collect(obj)
            } else {
                var obj = {
                    subjectId: this.pageExamObj.subjectId,
                }
                this.add_collect(obj)
            }
        },
        // 标记此题
        stamp() {
            var type = null
            if (this.pageExamObj.isSign == 1) {
                type = 2
            } else {
                type = 1
            }
            var obj = {
                subjectId: this.pageExamObj.subjectId,
                type: type,
                nodeId: this.examInfo.nodeId,
                testPaperId: ''
            }
            add_sign(obj).then(res => {
                if (res.data.code == 0) {
                    if (this.pageExamObj.classify == '1') {
                        if (this.pageExamObj.isSign == 1) {
                            this.pageExamObj.isSign == 2
                            for (var i in this.radioExamList) {
                                if (this.radioExamList[i].subjectId == this.pageExamObj.subjectId) {
                                    this.radioExamList[i].isSign = 2
                                }
                            }
                        } else {
                            this.pageExamObj.isSign == 1
                            for (var i in this.radioExamList) {
                                if (this.radioExamList[i].subjectId == this.pageExamObj.subjectId) {
                                    this.radioExamList[i].isSign = 1
                                }
                            }
                        }

                    } else if (this.pageExamObj.classify == '2') {
                        if (this.pageExamObj.isSign == 1) {
                            this.pageExamObj.isSign == 2
                            for (var i in this.checkboxExamList) {
                                if (this.checkboxExamList[i].subjectId == this.pageExamObj.subjectId) {
                                    this.checkboxExamList[i].isSign = 2
                                }
                            }
                        } else {
                            this.pageExamObj.isSign == 1
                            for (var i in this.checkboxExamList) {
                                if (this.checkboxExamList[i].subjectId == this.pageExamObj.subjectId) {
                                    this.checkboxExamList[i].isSign = 1
                                }
                            }
                        }
                    } else if (this.pageExamObj.classify == '3') {
                        if (this.pageExamObj.isSign == 1) {
                            this.pageExamObj.isSign == 2
                            for (var i in this.estimateList) {
                                if (this.estimateList[i].subjectId == this.pageExamObj.subjectId) {
                                    this.estimateList[i].isSign = 2
                                }
                            }
                        } else {
                            this.pageExamObj.isSign == 1
                            for (var i in this.estimateList) {
                                if (this.estimateList[i].subjectId == this.pageExamObj.subjectId) {
                                    this.estimateList[i].isSign = 1
                                }
                            }
                        }
                    } else if (this.pageExamObj.classify == '4') {
                        if (this.pageExamObj.isSign == 1) {
                            this.pageExamObj.isSign == 2
                            for (var i in this.budingxiang) {
                                if (this.budingxiang[i].subjectId == this.pageExamObj.subjectId) {
                                    this.budingxiang[i].isSign = 2
                                }
                            }
                        } else {
                            this.pageExamObj.isSign == 1
                            for (var i in this.budingxiang) {
                                if (this.budingxiang[i].subjectId == this.pageExamObj.subjectId) {
                                    this.budingxiang[i].isSign = 1
                                }
                            }
                        }
                    } else if (this.pageExamObj.classify == '5') {
                        if (this.pageExamObj.isSign == 1) {
                            this.pageExamObj.isSign == 2
                            for (var i in this.jisuanfenxi) {
                                if (this.jisuanfenxi[i].subjectId == this.pageExamObj.subjectId) {
                                    this.jisuanfenxi[i].isSign = 2
                                }
                            }
                        } else {
                            this.pageExamObj.isSign == 1
                            for (var i in this.jisuanfenxi) {
                                if (this.jisuanfenxi[i].subjectId == this.pageExamObj.subjectId) {
                                    this.jisuanfenxi[i].isSign = 1
                                }
                            }
                        }
                    } else if (this.pageExamObj.classify == '6') {
                        if (this.pageExamObj.isSign == 1) {
                            this.pageExamObj.isSign == 2
                            for (var i in this.synthesisList) {
                                if (this.synthesisList[i].subjectId == this.pageExamObj.subjectId) {
                                    this.synthesisList[i].isSign = 2
                                }
                            }
                        } else {
                            this.pageExamObj.isSign == 1
                            for (var i in this.synthesisList) {
                                if (this.synthesisList[i].subjectId == this.pageExamObj.subjectId) {
                                    this.synthesisList[i].isSign = 1
                                }
                            }
                        }
                    } else if (this.pageExamObj.classify == '7') {
                        if (this.pageExamObj.isSign == 1) {
                            this.pageExamObj.isSign == 2
                            for (var i in this.shortAnswerList) {
                                if (this.shortAnswerList[i].subjectId == this.pageExamObj.subjectId) {
                                    this.shortAnswerList[i].isSign = 2
                                }
                            }
                        } else {
                            this.pageExamObj.isSign == 1
                            for (var i in this.shortAnswerList) {
                                if (this.shortAnswerList[i].subjectId == this.pageExamObj.subjectId) {
                                    this.shortAnswerList[i].isSign = 1
                                }
                            }
                        }
                    } else if (this.pageExamObj.classify == '8') {
                        if (this.pageExamObj.isSign == 1) {
                            this.pageExamObj.isSign == 2
                            for (var i in this.jisuanhuida) {
                                if (this.jisuanhuida[i].subjectId == this.pageExamObj.subjectId) {
                                    this.jisuanhuida[i].isSign = 2
                                }
                            }
                        } else {
                            this.pageExamObj.isSign == 1
                            for (var i in this.jisuanhuida) {
                                if (this.jisuanhuida[i].subjectId == this.pageExamObj.subjectId) {
                                    this.jisuanhuida[i].isSign = 1
                                }
                            }
                        }
                    } else if (this.pageExamObj.classify == '9') {
                        if (this.pageExamObj.isSign == 1) {
                            this.pageExamObj.isSign == 2
                            for (var i in this.anlifenxi) {
                                if (this.anlifenxi[i].subjectId == this.pageExamObj.subjectId) {
                                    this.anlifenxi[i].isSign = 2
                                }
                            }
                        } else {
                            this.pageExamObj.isSign == 1
                            for (var i in this.anlifenxi) {
                                if (this.anlifenxi[i].subjectId == this.pageExamObj.subjectId) {
                                    this.anlifenxi[i].isSign = 1
                                }
                            }
                        }
                    } else if (this.pageExamObj.classify == '10') {
                        if (this.pageExamObj.isSign == 1) {
                            this.pageExamObj.isSign == 2
                            for (var i in this.jisuan) {
                                if (this.jisuan[i].subjectId == this.pageExamObj.subjectId) {
                                    this.jisuan[i].isSign = 2
                                }
                            }
                        } else {
                            this.pageExamObj.isSign == 1
                            for (var i in this.jisuan) {
                                if (this.jisuan[i].subjectId == this.pageExamObj.subjectId) {
                                    this.jisuan[i].isSign = 1
                                }
                            }
                        }
                    } else if (this.pageExamObj.classify == '11') {
                        if (this.pageExamObj.isSign == 1) {
                            this.pageExamObj.isSign == 2
                            for (var i in this.zonghefenxi) {
                                if (this.zonghefenxi[i].subjectId == this.pageExamObj.subjectId) {
                                    this.zonghefenxi[i].isSign = 2
                                }
                            }
                        } else {
                            this.pageExamObj.isSign == 1
                            for (var i in this.zonghefenxi) {
                                if (this.zonghefenxi[i].subjectId == this.pageExamObj.subjectId) {
                                    this.zonghefenxi[i].isSign = 1
                                }
                            }
                        }
                    }

                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 查看解析
        lookAnalysis() {
            var that = this
            that.isShowAnalysis = !that.isShowAnalysis
            let arr2 = []
            let arr3 = []


            var obj = that.pageExamObj;

            if (obj.classify == '1' || obj.classify == '3') {
                if (this.radio) {
           
                    obj.answerList.forEach((item, index) => {
                        if (this.radio == item.answerId) {
                            arr2.push(item.indexes);
                        }
                        if (item.isRight == 1) {
                            arr3.push(item.indexes);
                        }
                    })
                    obj.myAnswer = arr2.join(',');
                    obj.refertoAnswer = arr3.join(',')

                } else if (obj.doneAnswerIds != '' && obj.doneAnswerIds != null && obj.doneAnswerIds != undefined) {
                    var arr = obj.doneAnswerIds.split(',')
                    for (var i in obj.answerList) {
                        for (var j in arr) {
                            if (arr[j] == obj.answerList[i].answerId) {
                                arr2.push(obj.answerList[i].indexes)
                            }
                        }
                        if (obj.answerList[i].isRight == 1) {
                            arr3.push(obj.answerList[i].indexes)
                        }
                    }
                    obj.myAnswer = arr2.join(',')
                    obj.refertoAnswer = arr3.join(',')
                } else {
                    for (var i in obj.answerList) {
                        if (obj.answerList[i].isRight == 1) {
                            arr3.push(obj.answerList[i].indexes)
                        }
                    }
                    obj.myAnswer = '未作答'
                    obj.refertoAnswer = arr3.join(',')
                }
                that.pageExamObj = obj
            } else if (that.pageExamObj.classify == '2') {
                if (obj.doneAnswerIds != '' && obj.doneAnswerIds != null && obj.doneAnswerIds != undefined) {
      
                    var arr = obj.doneAnswerIds.split(',')
                    for (var i in obj.answerList) {
                        for (var j in arr) {
                            if (arr[j] == obj.answerList[i].answerId) {
                                arr2.push(obj.answerList[i].indexes)
                            }
                        }
                        if (obj.answerList[i].isRight == 1) {
                            arr3.push(obj.answerList[i].indexes)
                        }
                    }
                    obj.myAnswer = arr2.join(',')
                    obj.refertoAnswer = arr3.join(',')
                } else {
                    for (var i in obj.answerList) {
                        if (obj.answerList[i].isRight == 1) {
                            arr3.push(obj.answerList[i].indexes)
                        }
                    }
                    obj.myAnswer = '未作答'
                    obj.refertoAnswer = arr3.join(',')
                }
                that.pageExamObj = obj
            } else if (that.pageExamObj.classify == '4') {

                if (that.pageExamObj.subjectList.length > 0) { // 如果有小题
                    for (var i in that.pageExamObj.subjectList) {
                        if (that.smallQuestionIndex == that.pageExamObj.subjectList[i].subjectId) {
                            
                            if (obj.subjectList[i].doneAnswerIds != '' && obj.subjectList[i].doneAnswerIds != null && obj.subjectList[i].doneAnswerIds != undefined) { // 作答了
                                var arr = obj.subjectList[i].doneAnswerIds.split(',')
                                for (var k in obj.subjectList[i].answerList) {
                                    for (var j in arr) {
                                        if (arr[j] == obj.subjectList[i].answerList[k].answerId) {
                                            arr2.push(obj.subjectList[i].answerList[k].indexes)
                                        }
                                    }
                                    if (obj.subjectList[i].answerList[k].isRight == 1) {
                                        arr3.push(obj.subjectList[i].answerList[k].indexes)
                                    }
                                }
                                obj.myAnswer = arr2.join(',')
                                obj.refertoAnswer = arr3.join(',')
                            
                            } else { // 未作答
                                for (var i in obj.subjectList[0].answerList) {
                                    if (obj.subjectList[0].answerList[i].isRight == 1) {
                                        arr3.push(obj.subjectList[0].answerList[i].indexes)
                                    }
                                }
                                obj.myAnswer = '未作答'
                                obj.refertoAnswer = arr3.join(',')
                            }
                            that.pageExamObj = obj
                        }
                    }
                } else { // 没有小题
           
                    if (obj.doneAnswerIds != '' && obj.doneAnswerIds != null && obj.doneAnswerIds != undefined) { // 作答了
                        var arr = that.pageExamObj.doneAnswerIds.split(',')

                        // for (var i in obj.subjectList[0].answerList) {
                        //   for (var j in arr) {
                        //     if (arr[j] == obj.subjectList[0].answerList[i].answerId) {
                        //       arr2.push(obj.subjectList[0].answerList[i].indexes)
                        //     }
                        //   }
                        //   if (obj.subjectList[0].answerList[i].isRight == 1) {
                        //     arr3.push(obj.subjectList[0].answerList[i].indexes)
                        //   }
                        // }
                        obj.answerList.forEach(item => {
                    
                            arr.forEach(val => {
                                if (val == item.answerId) {
                                    arr2.push(item.indexes)
                                }
                            })
                            if (item.isRight == 1) {
                                arr3.push(item.indexes);
                            }
                        })

                        obj.myAnswer = arr2.join(',')
                        obj.refertoAnswer = arr3.join(',')
                    } else { // 未作答
                        for (var i in obj.answerList) {
                            if (obj.answerList[i].isRight == 1) {
                                arr3.push(obj.answerList[i].indexes)
                            }
                        }
                        obj.myAnswer = '未作答'
                        obj.refertoAnswer = arr3.join(',')
                    }
                }

            } else if (that.pageExamObj.classify == '5') {
                // if(obj.subjectList.length>0){// 有小题
                //     for(var i in obj.subjectList){
                //         if(obj.subjectList[i].doneAnswer!='' && obj.subjectList[i].doneAnswer!=null && obj.subjectList[i].doneAnswer!=undefined){
                //             var arr=that.pageExamObj.doneAnswerIds.split(',')
                //         }else{
                //             for(var i in obj.subjectList[i].answerList){
                //                 if(obj.subjectList[i].answerList[i].isRight==1){
                //                     arr3.push(obj.subjectList[i].answerList[i].indexes)
                //                 }
                //             }
                //             obj.myAnswer='未作答'
                //             obj.refertoAnswer=arr3.join(',')
                //         }
                //     }
                // }else{
                //     if(obj.doneAnswer!='' && obj.doneAnswer!=null && obj.doneAnswer!=undefined){
                //         var arr=that.pageExamObj.doneAnswerIds.split(',')
                //     }else{
                //         for(var i in obj.answerList){
                //             if(obj.answerList[i].isRight==1){
                //                 arr3.push(obj.answerList[i].indexes)
                //             }
                //         }
                //         obj.myAnswer='未作答'
                //         obj.refertoAnswer=arr3.join(',')
                //     }
                // }

            } else if (that.pageExamObj.classify == '6') {

            } else if (that.pageExamObj.classify == '7') {

            } else if (that.pageExamObj.classify == '8') {

            }

            if (this.pageExamObj.classify > 4) {

                let smallQuestionIndex = this.smallQuestionIndex
                if (obj.subjectList.length > 0) {
                    obj.subjectList.forEach((item) => {
                        if (item.subjectId == smallQuestionIndex) {
                            obj.analysisText = item.analysisText
                        }
                    })
                }
            }




            for (var i in that.analysisTestList) {
                if (that.analysisTestList[i].subjectId == that.pageExamObj.subjectId) {
                    if (that.pageExamObj.subjectList.length > 0) {
                        for (var j in that.pageExamObj.subjectList) {
                            if (that.smallQuestionIndex == that.pageExamObj.subjectList[j].subjectId) {
                                that.analysisTestObj = that.analysisTestList[i]
                                return false
                            }
                        }
                    } else {
                        that.analysisTestObj = that.analysisTestList[i]
                        return false
                    }
                }
            }

        },
        // 切换小题
        tabSmallQuestion(item, index) {
            this.smallQuestionIndex = item.subjectId
            this.smallQuestionObj = item
            this.isShowAnalysis = false;
            
            if (item.classify == '4') {
    
                if (item.doneAnswerIds != '' && item.doneAnswerIds != null && item.doneAnswerIds != undefined) {
        
                    this.checkList = item.doneAnswerIds.split(",");

                } else {
                    this.checkList = []
                }
            } else {
                if (item.doneAnswer != '' && item.doneAnswer != null && item.doneAnswer != undefined) {
                    this.testTextarea = item.doneAnswer
                } else {
                    this.testTextarea = ''
                }
            }
            

        },
        // 切换题
        tabExamQuestion(item, num, index) {
            // 切换题时，保存答题数据
            this.tab_save_subject(item, num);
            /* 
              1.radioExamList
              2.checkboxExamList
              3.estimateList
            */

            var that = this;
            that.checkedExamId = item.subjectId;
            that.pageExamObj = item;
            // that.titleIndex=num
            that.indexIng = index;
            that.isShowAnalysis = false;

            // if(num=='5'){
            //     that.smallQuestionIndex=item.subjectList[0].subjectId
            // }
            if (num == 1 || num == 3) {
                //切换题目要回显答案
                that.radio = item.doneAnswerIds || "";
            } else if (num == 2) {
                if (item.doneAnswerIds != null && item.doneAnswerIds != '' && item.doneAnswerIds != undefined) {
                    // var checkListArr = item.doneAnswerIds.split(",");
                    // var temp_checkList = [];
                    // for(var i = 0;i<checkListArr.length;i++){
                    //     temp_checkList.push(checkListArr[i]);
                    //     // that.checkList.push();
                    // }
                    that.checkList = item.doneAnswerIds.split(",");
                    // if(that.pageExamObj.classify=='2'){
                    //     that.checkList=that.pageExamObj.doneAnswerIds.split(',')
                    // }
                }
            } else if (num == 4) {
                that.checkList = []
                if (item.subjectList.length > 0) {
                    if (item.subjectList[0].doneAnswerIds != null && item.subjectList[0].doneAnswerIds != '' && item.subjectList[0].doneAnswerIds != undefined) {
                        var checkListArr = item.subjectList[0].doneAnswerIds.split(",");
                        var temp_checkList = [];
                        for (var i = 0; i < checkListArr.length; i++) {
                            temp_checkList.push(checkListArr[i]);
                            // that.checkList.push();
                        }
                        that.checkList = temp_checkList
                    }
                    that.smallQuestionObj = item.subjectList[0]
                    that.smallQuestionIndex = item.subjectList[0].subjectId
                } else {
                    that.smallQuestionIndex = item.subjectId
                    if (item.doneAnswerIds != null && item.doneAnswerIds != '' && item.doneAnswerIds != undefined) {
                        var checkListArr = item.doneAnswerIds.split(",");
                        var temp_checkList = [];
                        for (var i = 0; i < checkListArr.length; i++) {
                            temp_checkList.push(checkListArr[i]);
                            // that.checkList.push();
                        }
                        that.checkList = temp_checkList
                    }
                    that.smallQuestionIndex = item.subjectId
                    that.smallQuestionObj = item
                }
            } else {
              
                if (item.subjectList.length > 0) {
                    that.smallQuestionObj = item.subjectList[0]
                    that.smallQuestionIndex = item.subjectList[0].subjectId
                    that.testTextarea = item.subjectList[0].doneAnswer;
                } else {
                    that.smallQuestionObj = item
                    that.smallQuestionIndex = item.subjectId
                    that.testTextarea = item.doneAnswer;
                }
            }
            that.$nextTick(()=>{
                that.update_size();
            })

        },
        getAnswerIds(val){
            let newAry = [];
            val.map((item)=>{
                newAry.push(item.answerId);
            });
            return newAry;
        },
        getInfoArr(arr1, arr2){
            let add=arr2.filter(item=>arr1.some(ele=>ele===item))
            return add;
        },
        // 答题卡切换保存
        tab_save_subject(item) {
            console.log("index",this.tabIndex);
            let pageExamObj = this.pageExamObj;
            let obj = {
                answerTimeLength: this.second_time - this.recordTime,
                nodeType: this.tabIndex,
                subjectId: this.pageExamObj.subjectId,
                answerId: '',
                answerText: ''
            }
           
            if (pageExamObj.classify == 1) {
                let radioExamList = this.radioExamList;
                radioExamList.map((value, index) => {
                    if (obj.subjectId == value.subjectId) {
                        return value.doneAnswerIds = this.radio;
                    }
                })
      
                this.radioExamList = radioExamList;
                obj.answerId = this.radio;
            } else if (pageExamObj.classify == 2) {
                
             
                this.checkList = this.getInfoArr(this.getAnswerIds(this.pageExamObj.answerList),this.checkList);
                let checkboxExamList = this.checkboxExamList;
                checkboxExamList.map((value, index) => {
                    if (obj.subjectId == value.subjectId) {
                        return value.doneAnswerIds = this.checkList.join(',');
                    }
                })
                this.checkboxExamList = checkboxExamList;
                obj.answerId = this.checkList.join(',');
            } else if (pageExamObj.classify == 3) {
                let estimateList = this.estimateList;
                estimateList.map((value, index) => {
                    if (obj.subjectId == value.subjectId) {
                        return value.doneAnswerIds = this.radio;
                    }
                })
                this.estimateList = estimateList;
                obj.answerId = this.radio;
            } else if (pageExamObj.classify == 4) {
                let budingxiang = this.budingxiang;
                budingxiang.map((value, index) => {
                    if (obj.subjectId == value.subjectId) {
                 
                        //小题
                        if (value.subjectList.length) {
                            value.subjectList.map((val) => {
                                if (val.subjectId == this.smallQuestionIndex) {
                                    return val.doneAnswerIds = this.checkList.join(',');
                                }
                            })
                        } else {
                            return value.doneAnswerIds = this.checkList.join(',');
                        }
                    }
                })
               

                obj.subjectId = this.smallQuestionIndex;
                obj.answerId = this.checkList.join(',');

            } else if (pageExamObj.classify > 4) {
                // obj.subjectId = item.subjectId;
                // obj.answerId = '';
                let typesObj = {
                    jisuanfenxi: this.jisuanfenxi,
                    synthesisList: this.synthesisList,
                    shortAnswerList: this.shortAnswerList,
                    anlifenxi: this.anlifenxi,
                    shortAnswerList: this.shortAnswerList,
                    jisuan: this.jisuan,
                    zonghefenxi: this.zonghefenxi,
                    jisuanhuida:this.jisuanhuida,
                };
                let listName = '';
                typesObj.jisuanfenxi.forEach(value => {
                    if (value.subjectId == obj.subjectId) {
                        listName = 'jisuanfenxi';
                    }
                })
                typesObj.synthesisList.forEach(value => {
                    if (value.subjectId == obj.subjectId) {
                        listName = 'synthesisList';
                    }
                })
                typesObj.shortAnswerList.forEach(value => {
                    if (value.subjectId == obj.subjectId) {
                        listName = 'shortAnswerList';
                    }
                })
                typesObj.anlifenxi.forEach(value => {
                    if (value.subjectId == obj.subjectId) {
                        listName = 'anlifenxi';
                    }
                })
                typesObj.shortAnswerList.forEach(value => {
                    if (value.subjectId == obj.subjectId) {
                        listName = 'shortAnswerList';
                    }
                })
                typesObj.jisuan.forEach(value => {
                    if (value.subjectId == obj.subjectId) {
                        listName = 'jisuan';
                    }
                })
                typesObj.zonghefenxi.forEach(value => {
                    if (value.subjectId == obj.subjectId) {
                        listName = 'zonghefenxi';
                    }
                })
                typesObj.jisuanhuida.forEach(value => {
                    if (value.subjectId == obj.subjectId) {
                        listName = 'jisuanhuida';
                    }
                })
                typesObj[listName].forEach(value => {
                    if (value.subjectId == obj.subjectId) {
                        if (value.subjectList.length) {
                            value.subjectList.forEach(val => {
                                if (this.smallQuestionIndex == val.subjectId) {
                                    val.doneAnswer = this.testTextarea;
                                }
                            })
                        } else {
                            value.doneAnswer = this.testTextarea;
                        }
                    }
                    obj.subjectId = this.smallQuestionIndex;
                    obj.answerText = this.testTextarea;
                })
            }
            if(this.tabIndex == '1'){
                if (obj.answerId || obj.answerText) {
                    save_subject(obj).then(res => {
                        console.log(res);
                    })
                }

            }else{
                if (obj.answerId || obj.answerText) {
                    var newTime = Number(this.second_time - this.recordTime)
                    var obj_mn = {
                        answerTimeLength: newTime,
                        subjectId: this.pageExamObj.subjectId,
                        testPaperId: this.examInfo.testPaperId,
                        answerId: obj.answerId,
                        answerText: obj.answerText,
                        score: this.score,
                    }
                    console.log("---0000----");
                    savePaper_subject(obj_mn).then(res => {
                        console.log("模拟试卷",res);
                    }).catch(error => {
                        console.log(error)
                    })

                }
                
            }
            
        },


        // 获取章节试卷内容
        subject_list(fn) {
            var that = this
            var obj = {
                nodeId: that.examInfo.nodeId,
                nodeType: that.tabIndex,
                type: that.num
            }
            subject_list(obj).then(res => {
         
                if (res.data.code == 0) {
                    this.loading.close()
                    fn && fn();
                    that.examTextObj = res.data.data
                    that.paperChapterSubjectList = res.data.data.paperChapterSubjectList
                    if (res.data.data.timeLength != '' && res.data.data.timeLength != null && res.data.data.timeLength != undefined) {
                        that.second = res.data.data.timeLength
                        that.second_time = res.data.data.timeLength
                        that.recordTime = res.data.data.timeLength
                        that.minute = Number(res.data.data.timeLength) % 60
                        if (that.minute > 60) {
                            that.minute = Number(that.minute) % 60
                            that.hour = (Number(that.minute) - (Number(that.minute) % 60)) / 60
                        } else {
                            that.minute = (Number(res.data.data.timeLength) - that.second) / 60
                            that.hour = 0
                        }
                    }

                    for (var i in res.data.data.paperChapterSubjectList) {
                        /**
                         *  1单项选择题（一个题干一道题）、
                            2多项选择题（一个题干一道题）、
                            3判断题（一个题干一道题）、
                            4不定项选择题（一个题干多道题---多选题）\n主观题：
                            5计算分析题、
                            6综合题、
                            7简答题、
                            8计算回答题、
                            9案例分析题、
                            10计算题、
                            11综合分析题。（都有可能一个题干多道题）
                         */
                        if (res.data.data.paperChapterSubjectList[i].classify == "1") {
                            that.radioExamList.push(res.data.data.paperChapterSubjectList[i])
                        } else if (res.data.data.paperChapterSubjectList[i].classify == "2") {
                            that.checkboxExamList.push(res.data.data.paperChapterSubjectList[i])
                        } else if (res.data.data.paperChapterSubjectList[i].classify == "3") {
                            that.estimateList.push(res.data.data.paperChapterSubjectList[i])
                        } else if (res.data.data.paperChapterSubjectList[i].classify == "4") {
                            that.budingxiang.push(res.data.data.paperChapterSubjectList[i])
                        } else if (res.data.data.paperChapterSubjectList[i].classify == "5") {
                            that.jisuanfenxi.push(res.data.data.paperChapterSubjectList[i])
                            // if(res.data.data.paperChapterSubjectList[i].subjectList.length>0){
                            //     that.smallQuestionIndex=that.synthesisList[0].subjectList[0].subjectId
                            // }
                        } else if (res.data.data.paperChapterSubjectList[i].classify == "6") {
                            that.synthesisList.push(res.data.data.paperChapterSubjectList[i])
                        } else if (res.data.data.paperChapterSubjectList[i].classify == "7") {
                            that.shortAnswerList.push(res.data.data.paperChapterSubjectList[i])
                        } else if (res.data.data.paperChapterSubjectList[i].classify == "8") {
                            that.jisuanhuida.push(res.data.data.paperChapterSubjectList[i])
                        } else if (res.data.data.paperChapterSubjectList[i].classify == "9") {
                            that.anlifenxi.push(res.data.data.paperChapterSubjectList[i])
                        } else if (res.data.data.paperChapterSubjectList[i].classify == "10") {
                            that.jisuan.push(res.data.data.paperChapterSubjectList[i])
                        } else if (res.data.data.paperChapterSubjectList[i].classify == "11") {
                            that.zonghefenxi.push(res.data.data.paperChapterSubjectList[i])
                        }
                    }
                    if (res.data.data.finallyAnswerId != '' && res.data.data.finallyAnswerId != null && res.data.data.finallyAnswerId != undefined) {
                        that.checkedExamId = res.data.data.finallyAnswerId
                        for (var i in res.data.data.paperChapterSubjectList) {
                            if (res.data.data.finallyAnswerId == res.data.data.paperChapterSubjectList[i].subjectId) {
                                that.pageExamObj = res.data.data.paperChapterSubjectList[i]
                                var obj = that.pageExamObj
                                if (obj.classify == '2') {
                                    that.checkList = obj.doneAnswerIds.split(',')
                                    for (var i in that.checkboxExamList) {
                                        if (res.data.data.finallyAnswerId == that.checkboxExamList[i].subjectId) {
                                            that.indexIng = Number(i)
                                        }
                                    }
                                } else if (obj.classify == '1') {
                                    that.radio = obj.doneAnswerIds
                                    for (var i in that.radioExamList) {
                                        if (res.data.data.finallyAnswerId == that.radioExamList[i].subjectId) {
                                            that.indexIng = Number(i)
                                            if (that.radioExamList[i].doneAnswerIds != '' && that.radioExamList[i].doneAnswerIds != null && that.radioExamList[i].doneAnswerIds != undefined) {
                                                that.radio = that.radioExamList[i].doneAnswerIds
                                            }
                                        }
                                    }
                                } else if (obj.classify == '3') {
                                    that.radio = obj.doneAnswerIds
                                    for (var i in that.estimateList) {
                                        if (res.data.data.finallyAnswerId == that.estimateList[i].subjectId) {
                                            that.indexIng = Number(i)
                                        }
                                    }
                                } else if (obj.classify == '4') {
                                    for (var i in that.budingxiang) {
                                        if (res.data.data.finallyAnswerId == that.budingxiang[i].subjectId) {
                                            that.indexIng = Number(i)
                                            if (that.jisuanfenxi[i].subjectList.length > 0) {
                                                that.smallQuestionIndex = that.budingxiang[i].subjectList[0].subjectId
                                                that.smallQuestionObj = that.budingxiang[i].subjectList[0]
                                                if (that.budingxiang[i].subjectList[0].doneAnswerIds != '' && that.budingxiang[i].subjectList[0].doneAnswerIds != null && that.budingxiang[i].subjectList[0].doneAnswerIds != undefined) {
                                                    var checkListArr = that.budingxiang[i].subjectList[0].doneAnswerIds.split(",");
                                                    var temp_checkList = [];
                                                    for (var i = 0; i < checkListArr.length; i++) {
                                                        temp_checkList.push(checkListArr[i]);
                                                        // that.checkList.push();
                                                    }
                                                    that.checkList = temp_checkList
                                                }
                                            } else {
                                                that.indexIng = Number(i)
                                                that.smallQuestionIndex = that.budingxiang[i].subjectId
                                                that.smallQuestionObj = that.budingxiang[i]
                                                var checkListArr = that.budingxiang[i].doneAnswerIds.split(",");
                                                var temp_checkList = [];
                                                for (var i = 0; i < checkListArr.length; i++) {
                                                    temp_checkList.push(checkListArr[i]);
                                                    // that.checkList.push();
                                                }
                                                that.checkList = temp_checkList
                                            }
                                        }
                                    }
                                } else if (obj.classify == '5') {
                                    for (var i in that.jisuanfenxi) {
                                        if (res.data.data.finallyAnswerId == that.jisuanfenxi[i].subjectId) {
                                            that.indexIng = Number(i)
                                            if (that.jisuanfenxi[i].subjectList.length > 0) {
                                                that.smallQuestionIndex = that.jisuanfenxi[i].subjectList[0].subjectId
                                                that.smallQuestionObj = that.jisuanfenxi[i].subjectList[0]
                                                if (that.jisuanfenxi[i].subjectList[0].doneAnswer != '' && that.jisuanfenxi[i].subjectList[0].doneAnswer != null && that.jisuanfenxi[i].subjectList[0].doneAnswer != undefined) {
                                                    that.testTextarea = that.jisuanfenxi[i].subjectList[0].doneAnswer
                                                }
                                            } else {
                                                that.smallQuestionIndex = that.jisuanfenxi[i].subjectId
                                                that.smallQuestionObj = that.jisuanfenxi[i]
                                                if (that.jisuanfenxi[i].doneAnswer != '' && that.jisuanfenxi[i].doneAnswer != null && that.jisuanfenxi[i].doneAnswer != undefined) {
                                                    that.testTextarea = that.jisuanfenxi[i].doneAnswer
                                                }
                                            }
                                        }
                                    }
                                } else if (obj.classify == '6') {
                                    for (var i in that.synthesisList) {
                                        if (res.data.data.finallyAnswerId == that.synthesisList[i].subjectId) {
                                            that.indexIng = Number(i)
                                            if (that.synthesisList[i].subjectList.length > 0) {
                                                that.smallQuestionIndex = that.synthesisList[i].subjectList[0].subjectId
                                                that.smallQuestionObj = that.synthesisList[i].subjectList[0]
                                                if (that.synthesisList[i].subjectList[0].doneAnswer != '' && that.synthesisList[i].subjectList[0].doneAnswer != null && that.synthesisList[i].subjectList[0].doneAnswer != undefined) {
                                                    that.testTextarea = that.synthesisList[i].subjectList[0].doneAnswer
                                                }
                                            } else {
                                                that.smallQuestionIndex = that.synthesisList[i].subjectId
                                                that.smallQuestionObj = that.synthesisList[i]
                                                if (that.synthesisList[i].doneAnswer != '' && that.synthesisList[i].doneAnswer != null && that.synthesisList[i].doneAnswer != undefined) {
                                                    that.testTextarea = that.synthesisList[i].doneAnswer
                                                }
                                            }
                                        }
                                    }
                                } else if (obj.classify == '7') {
                                    for (var i in that.shortAnswerList) {
                                        if (res.data.data.finallyAnswerId == that.shortAnswerList[i].subjectId) {
                                            that.indexIng = Number(i)
                                            if (that.shortAnswerList[i].subjectList.length > 0) {
                                                that.smallQuestionIndex = that.shortAnswerList[i].subjectList[0].subjectId
                                                that.smallQuestionObj = that.shortAnswerList[i].subjectList[0]
                                                if (that.shortAnswerList[i].subjectList[0].doneAnswer != '' && that.shortAnswerList[i].subjectList[0].doneAnswer != null && that.shortAnswerList[i].subjectList[0].doneAnswer != undefined) {
                                                    that.testTextarea = that.shortAnswerList[i].subjectList[0].doneAnswer
                                                }
                                            } else {
                                                that.smallQuestionIndex = that.shortAnswerList[i].subjectId
                                                that.smallQuestionObj = that.shortAnswerList[i]
                                                if (that.shortAnswerList[i].doneAnswer != '' && that.shortAnswerList[i].doneAnswer != null && that.shortAnswerList[i].doneAnswer != undefined) {
                                                    that.testTextarea = that.shortAnswerList[i].doneAnswer
                                                }
                                            }
                                        }
                                    }
                                } else if (obj.classify == '8') {
                                    for (var i in that.jisuanhuida) {
                                        if (res.data.data.finallyAnswerId == that.jisuanhuida[i].subjectId) {
                                            that.indexIng = Number(i)
                                            if (that.jisuanhuida[i].subjectList.length > 0) {
                                                that.smallQuestionIndex = that.jisuanhuida[i].subjectList[0].subjectId
                                                that.smallQuestionObj = that.jisuanhuida[i].subjectList[0]
                                                if (that.jisuanhuida[i].subjectList[0].doneAnswer != '' && that.jisuanhuida[i].subjectList[0].doneAnswer != null && that.jisuanhuida[i].subjectList[0].doneAnswer != undefined) {
                                                    that.testTextarea = that.jisuanhuida[i].subjectList[0].doneAnswer
                                                }
                                            } else {
                                                that.smallQuestionIndex = that.jisuanhuida[i].subjectId
                                                that.smallQuestionObj = that.jisuanhuida[i]
                                                if (that.jisuanhuida[i].doneAnswer != '' && that.jisuanhuida[i].doneAnswer != null && that.jisuanhuida[i].doneAnswer != undefined) {
                                                    that.testTextarea = that.jisuanhuida[i].doneAnswer
                                                }
                                            }
                                        }
                                    }
                                } else if (obj.classify == '9') {
                                    for (var i in that.anlifenxi) {
                                        if (res.data.data.finallyAnswerId == that.anlifenxi[i].subjectId) {
                                            that.indexIng = Number(i)
                                            if (that.anlifenxi[i].subjectList.length > 0) {
                                                that.smallQuestionIndex = that.anlifenxi[i].subjectList[0].subjectId
                                                that.smallQuestionObj = that.anlifenxi[i].subjectList[0]
                                                if (that.anlifenxi[i].subjectList[0].doneAnswer != '' && that.anlifenxi[i].subjectList[0].doneAnswer != null && that.anlifenxi[i].subjectList[0].doneAnswer != undefined) {
                                                    that.testTextarea = that.anlifenxi[i].subjectList[0].doneAnswer
                                                }
                                            } else {
                                                that.smallQuestionIndex = that.anlifenxi[i].subjectId
                                                that.smallQuestionObj = that.anlifenxi[i]
                                                if (that.anlifenxi[i].doneAnswer != '' && that.anlifenxi[i].doneAnswer != null && that.anlifenxi[i].doneAnswer != undefined) {
                                                    that.testTextarea = that.anlifenxi[i].doneAnswer
                                                }
                                            }
                                        }
                                    }
                                } else if (obj.classify == '10') {
                                    for (var i in that.jisuan) {
                                        if (res.data.data.finallyAnswerId == that.jisuan[i].subjectId) {
                                            that.indexIng = Number(i)
                                            if (that.jisuan[i].subjectList.length > 0) {
                                                that.smallQuestionIndex = that.jisuan[i].subjectList[0].subjectId
                                                that.smallQuestionObj = that.jisuan[i].subjectList[0]
                                                if (that.jisuan[i].subjectList[0].doneAnswer != '' && that.jisuan[i].subjectList[0].doneAnswer != null && that.jisuan[i].subjectList[0].doneAnswer != undefined) {
                                                    that.testTextarea = that.jisuan[i].subjectList[0].doneAnswer
                                                }
                                            } else {
                                                that.smallQuestionIndex = that.jisuan[i].subjectId
                                                that.smallQuestionObj = that.jisuan[i]
                                                if (that.jisuan[i].doneAnswer != '' && that.jisuan[i].doneAnswer != null && that.jisuan[i].doneAnswer != undefined) {
                                                    that.testTextarea = that.jisuan[i].doneAnswer
                                                }
                                            }
                                        }
                                    }
                                } else if (obj.classify == '11') {
                                    for (var i in that.zonghefenxi) {
                                        if (res.data.data.finallyAnswerId == that.zonghefenxi[i].subjectId) {
                                            that.indexIng = Number(i)
                                            if (that.zonghefenxi[i].subjectList.length > 0) {
                                                that.smallQuestionIndex = that.zonghefenxi[i].subjectList[0].subjectId
                                                that.smallQuestionObj = that.zonghefenxi[i].subjectList[0]
                                                if (that.zonghefenxi[i].subjectList[0].doneAnswer != '' && that.zonghefenxi[i].subjectList[0].doneAnswer != null && that.zonghefenxi[i].subjectList[0].doneAnswer != undefined) {
                                                    that.testTextarea = that.zonghefenxi[i].subjectList[0].doneAnswer
                                                }
                                            } else {
                                                that.smallQuestionIndex = that.zonghefenxi[i].subjectId
                                                that.smallQuestionObj = that.zonghefenxi[i]
                                                if (that.zonghefenxi[i].doneAnswer != '' && that.zonghefenxi[i].doneAnswer != null && that.zonghefenxi[i].doneAnswer != undefined) {
                                                    that.testTextarea = that.zonghefenxi[i].doneAnswer
                                                }
                                            }
                                        }
                                    }
                                    // for(var i in that.zonghefenxi){
                                    //     // if(res.data.data.finallyAnswerId==that.zonghefenxi[i].subjectId){
                                    //     //     that.indexIng=i
                                    //     // }
                                    //     if(that.zonghefenxi[i].subjectList.length>0){
                                    //         for(var j in that.zonghefenxi[i].subjectList){
                                    //             if(res.data.data.finallyAnswerId==that.zonghefenxi[i].subjectList[j].subjectId){
                                    //                 that.indexIng=i
                                    //                 that.smallQuestionIndex=that.zonghefenxi[i].subjectList[j].subjectId
                                    //                 that.smallQuestionObj=that.zonghefenxi[i].subjectList[j]
                                    //                 if(that.zonghefenxi[i].subjectList[j].doneAnswer!='' && that.zonghefenxi[i].subjectList[j].doneAnswer!=null && that.zonghefenxi[i].subjectList[j].doneAnswer!=undefined){
                                    //                     that.testTextarea = that.zonghefenxi[i].subjectList[j].doneAnswer
                                    //                 }
                                    //             }
                                    //         }
                                    //     }else{
                                    //         if(res.data.data.finallyAnswerId==that.zonghefenxi[i].subjectId){
                                    //             that.indexIng=i
                                    //             that.smallQuestionIndex=that.zonghefenxi[i].subjectId
                                    //             that.smallQuestionObj=that.zonghefenxi[i]
                                    //             if(that.zonghefenxi[i].subjectList[j].doneAnswer!='' && that.zonghefenxi[i].subjectList[j].doneAnswer!=null && that.zonghefenxi[i].subjectList[j].doneAnswer!=undefined){
                                    //                 that.testTextarea = that.zonghefenxi[i].subjectList[j].doneAnswer
                                    //             }
                                    //         }
                                    //     }

                                    // }
                                }
                            }
                        }
                        // 啦啦啦啦
                    } else {
                        if (res.data.data.paperChapterSubjectList[0].classify == '1') {
                            that.checkedExamId = res.data.data.paperChapterSubjectList[0].subjectId
                        } else if (res.data.data.paperChapterSubjectList[0].classify == '2') {
                            that.checkedExamId = res.data.data.paperChapterSubjectList[0].subjectId
                        } else if (res.data.data.paperChapterSubjectList[0].classify == '3') {
                            that.checkedExamId = res.data.data.paperChapterSubjectList[0].subjectId
                        } else if (res.data.data.paperChapterSubjectList[0].classify == '4') {
                            if (res.data.data.paperChapterSubjectList[0].subjectList.length > 0) {
                                that.checkedExamId = res.data.data.paperChapterSubjectList[0].subjectId
                                that.smallQuestionObj = res.data.data.paperChapterSubjectList[0].subjectList[0]
                                that.smallQuestionIndex = res.data.data.paperChapterSubjectList[0].subjectList[0].subjectId
                            } else {
                                that.checkedExamId = res.data.data.paperChapterSubjectList[0].subjectId
                            }
                            that.indexIng = 0
                        }
                        that.pageExamObj = res.data.data.paperChapterSubjectList[0]
                    }
                    that.$nextTick(()=>{
                        that.update_size();

                    })
                } else {
                    this.loading.close()
                    that.$nextTick(()=>{
                        that.update_size();

                    })
                    that.$message.error(res.data.msg)
                }
            }).catch(error => {
                this.loading.close()
                console.log(error)
            })
        },
        // 获取考试试卷内容
        subjectPaperList(fn) {
            var that = this
            var obj = {
                testPaperId: that.examInfo.testPaperId,
                type: that.num
            }
            subjectPaperList(obj).then(res => {
            
                if (res.data.code == 0) {
                    this.loading.close()
                    fn && fn();
                    this.examTextObj = res.data.data;
                    let paperSubjectList = this.paperSubjectList = res.data.data.paperSubjectList
                    this.pageExamObj = paperSubjectList[0]
                    this.checkedExamId = paperSubjectList[0].subjectId
                    if (res.data.data.timeLength != '' && res.data.data.timeLength != null && res.data.data.timeLength != undefined) {
                        // 显示
                        this.testMounted = Number(res.data.data.paperAnswerTime * 60) - res.data.data.timeLength //总共剩余秒数

                        this.testSecond = this.testMounted % 60
                        this.testSecond_time = res.data.data.timeLength
                        this.recordTime = res.data.data.timeLength
                        this.testMinute = Math.floor(this.testMounted / 60)
                        if (this.testMinute > 60) {
                            this.testHour = Math.floor(Number(this.testMinute) / 60)
                            this.testMinute = this.testMinute % 60
                         
                            // this.testHour=((Number(Number(res.data.data.paperAnswerTime*60) - res.data.data.timeLength)-(Number(res.data.data.timeLength)%60))/60)/60
                            // this.testSecond= (Number(this.testSecond) / 60).toFixed(0)
                        } else {
                            // this.testMinute=(Number(this.testSecond_time) - res.data.data.timeLength)/60
                      
                            if (this.testMinute >= 60) {
                       
                                this.testMinute = Number(this.testMinute) % 60
                            }
                            this.testHour = 0
                        }
                    }
                    for (var i in res.data.data.paperSubjectList) {
                        /**
                         *  1单项选择题（一个题干一道题）、
                            2多项选择题（一个题干一道题）、
                            3判断题（一个题干一道题）、
                            4不定项选择题（一个题干多道题---多选题）\n主观题：
                            5计算分析题、
                            6综合题、
                            7简答题、
                            8计算回答题、
                            9案例分析题、
                            10计算题、
                            11综合分析题。（都有可能一个题干多道题）
                         */
                        if (res.data.data.paperSubjectList[i].classify == "1") {
                            that.radioExamList.push(res.data.data.paperSubjectList[i])
                        } else if (res.data.data.paperSubjectList[i].classify == "2") {
                            that.checkboxExamList.push(res.data.data.paperSubjectList[i])
                        } else if (res.data.data.paperSubjectList[i].classify == "3") {
                            that.estimateList.push(res.data.data.paperSubjectList[i])
                        } else if (res.data.data.paperSubjectList[i].classify == "4") {
                            that.budingxiang.push(res.data.data.paperSubjectList[i])
                        } else if (res.data.data.paperSubjectList[i].classify == "5") {
                            that.jisuanfenxi.push(res.data.data.paperSubjectList[i])
                            // if(res.data.data.paperSubjectList[i].subjectList.length>0){
                            //     that.smallQuestionIndex=that.synthesisList[0].subjectList[0].subjectId
                            // }
                        } else if (res.data.data.paperSubjectList[i].classify == "6") {
                            that.synthesisList.push(res.data.data.paperSubjectList[i])
                        } else if (res.data.data.paperSubjectList[i].classify == "7") {
                            that.shortAnswerList.push(res.data.data.paperSubjectList[i])
                        } else if (res.data.data.paperSubjectList[i].classify == "8") {
                            that.jisuanhuida.push(res.data.data.paperSubjectList[i])
                        } else if (res.data.data.paperSubjectList[i].classify == "9") {
                            that.anlifenxi.push(res.data.data.paperSubjectList[i])
                        } else if (res.data.data.paperSubjectList[i].classify == "10") {
                            that.jisuan.push(res.data.data.paperSubjectList[i])
                        } else if (res.data.data.paperSubjectList[i].classify == "11") {
                            that.zonghefenxi.push(res.data.data.paperSubjectList[i])
                        }
                    }

                    if (res.data.data.finallyAnswerId != '' && res.data.data.finallyAnswerId != null && res.data.data.finallyAnswerId != undefined) {
                        // that.checkedExamId || (that.checkedExamId = res.data.data.finallyAnswerId)
                        var obj = that.pageExamObj
                        for (var i in res.data.data.paperSubjectList) {
                            // if (res.data.data.finallyAnswerId == res.data.data.paperSubjectList[i].subjectId) {
                            // that.pageExamObj = res.data.data.paperSubjectList[i]
                            if (obj.classify == '2') {
                                that.checkList = obj.doneAnswerIds.split(',')
                                for (var i in that.checkboxExamList) {
                                    if (res.data.data.finallyAnswerId == that.checkboxExamList[i].subjectId) {
                                        that.indexIng = i
                                    }
                                }
                            } else if (obj.classify == '1') {
                                that.radio = obj.doneAnswerIds
                                for (var i in that.radioExamList) {
                                    if (res.data.data.finallyAnswerId == that.radioExamList[i].subjectId) {
                                        that.indexIng = Number(i)
                                        if (that.radioExamList[i].doneAnswerIds != '' && that.radioExamList[i].doneAnswerIds != null && that.radioExamList[i].doneAnswerIds != undefined) {
                                            that.radio = that.radioExamList[i].doneAnswerIds
                                        }
                                    }
                                }
                            } else if (obj.classify == '3') {
                                that.radio = obj.doneAnswerIds
                                for (var i in that.estimateList) {
                                    if (res.data.data.finallyAnswerId == that.estimateList[i].subjectId) {
                                        that.indexIng = i
                                    }
                                }
                            } else if (obj.classify == '4') {
                                for (var i in that.budingxiang) {
                                    if (res.data.data.finallyAnswerId == that.budingxiang[i].subjectId) {
                                        that.indexIng = i
                                        if (that.jisuanfenxi[i].subjectList.length > 0) {
                                            that.smallQuestionIndex = that.budingxiang[i].subjectList[0].subjectId
                                            that.smallQuestionObj = that.budingxiang[i].subjectList[0]
                                            if (that.budingxiang[i].subjectList[0].doneAnswerIds != '' && that.budingxiang[i].subjectList[0].doneAnswerIds != null && that.budingxiang[i].subjectList[0].doneAnswerIds != undefined) {
                                                var checkListArr = that.budingxiang[i].subjectList[0].doneAnswerIds.split(",");
                                                var temp_checkList = [];
                                                for (var i = 0; i < checkListArr.length; i++) {
                                                    temp_checkList.push(checkListArr[i]);
                                                    // that.checkList.push();
                                                }
                                                that.checkList = temp_checkList
                                            }
                                        } else {
                                            that.indexIng = i
                                            that.smallQuestionIndex = that.budingxiang[i].subjectId
                                            that.smallQuestionObj = that.budingxiang[i]
                                            var checkListArr = that.budingxiang[i].doneAnswerIds.split(",");
                                            var temp_checkList = [];
                                            for (var i = 0; i < checkListArr.length; i++) {
                                                temp_checkList.push(checkListArr[i]);
                                                // that.checkList.push();
                                            }
                                            that.checkList = temp_checkList
                                        }
                                    }
                                }
                            }
                            // else if (obj.classify > 4) {
                            //   /* 
                            //     this.checkedExamId 答题卡选中id
                            //     this.indexIng 答题
                            //   */
                            //   let finallyAnswerId = res.data.data.finallyAnswerId;
                            //   let typesObj = {
                            //     5: this.jisuanfenxi,
                            //     6: this.synthesisList,
                            //     7: this.shortAnswerList,
                            //     8: this.anlifenxi,
                            //     9: this.shortAnswerList,
                            //     10: this.jisuan,
                            //     11: this.zonghefenxi,
                            //   }
                            //   console.log(typesObj[obj.classify]);
                            //   typesObj[obj.classify].forEach((item, index) => {
                            //     if (item.subjectList.length) {  // 有小题
                            //       item.subjectList.forEach((val, idx) => {
                            //         if (val.subjectId == finallyAnswerId) {
                            //           this.indexIng = index;
                            //           this.smallQuestionIndex = val.subjectId;
                            //           this.smallQuestionObj = val;
                            //           val.doneAnswer && (this.testTextarea = val.doneAnswer)
                            //         }
                            //       })
                            //     } else if (item.subjectId == finallyAnswerId) {
                            //       this.smallQuestionIndex = item.subjectId;
                            //       this.smallQuestionObj = item;
                            //       item.doneAnswer && (this.testTextarea = item.doneAnswer)
                            //     }

                            //   })
                            // }

                            /* else if (obj.classify == '5') {
                              for (var i in that.jisuanfenxi) {
                                if (res.data.data.finallyAnswerId == that.jisuanfenxi[i].subjectId) {
                                  that.indexIng = i
                                  if (that.jisuanfenxi[i].subjectList.length > 0) {
                                    that.smallQuestionIndex = that.jisuanfenxi[i].subjectList[0].subjectId
                                    that.smallQuestionObj = that.jisuanfenxi[i].subjectList[0]
                                    if (that.jisuanfenxi[i].subjectList[0].doneAnswer != '' && that.jisuanfenxi[i].subjectList[0].doneAnswer != null && that.jisuanfenxi[i].subjectList[0].doneAnswer != undefined) {
                                      that.testTextarea = that.jisuanfenxi[i].subjectList[0].doneAnswer
                                    }
                                  } else {
                                    that.smallQuestionIndex = that.jisuanfenxi[i].subjectId
                                    that.smallQuestionObj = that.jisuanfenxi[i]
                                    if (that.jisuanfenxi[i].doneAnswer != '' && that.jisuanfenxi[i].doneAnswer != null && that.jisuanfenxi[i].doneAnswer != undefined) {
                                      that.testTextarea = that.jisuanfenxi[i].doneAnswer
                                    }
                                  }
                                }
                              }
                            } else if (obj.classify == '6') {
                              for (var i in that.synthesisList) {
                                if (res.data.data.finallyAnswerId == that.synthesisList[i].subjectId) {
                                  that.indexIng = i
                                  if (that.synthesisList[i].subjectList.length > 0) {
                                    that.smallQuestionIndex = that.synthesisList[i].subjectList[0].subjectId
                                    that.smallQuestionObj = that.synthesisList[i].subjectList[0]
                                    if (that.synthesisList[i].subjectList[0].doneAnswer != '' && that.synthesisList[i].subjectList[0].doneAnswer != null && that.synthesisList[i].subjectList[0].doneAnswer != undefined) {
                                      that.testTextarea = that.synthesisList[i].subjectList[0].doneAnswer
                                    }
                                  } else {
                                    that.smallQuestionIndex = that.synthesisList[i].subjectId
                                    that.smallQuestionObj = that.synthesisList[i]
                                    if (that.synthesisList[i].doneAnswer != '' && that.synthesisList[i].doneAnswer != null && that.synthesisList[i].doneAnswer != undefined) {
                                      that.testTextarea = that.synthesisList[i].doneAnswer
                                    }
                                  }
                                }
                              }
                            } else if (obj.classify == '7') {
                              for (var i in that.shortAnswerList) {
                                if (res.data.data.finallyAnswerId == that.shortAnswerList[i].subjectId) {
                                  that.indexIng = i
                                  if (that.shortAnswerList[i].subjectList.length > 0) {
                                    that.smallQuestionIndex = that.shortAnswerList[i].subjectList[0].subjectId
                                    that.smallQuestionObj = that.shortAnswerList[i].subjectList[0]
                                    if (that.shortAnswerList[i].subjectList[0].doneAnswer != '' && that.shortAnswerList[i].subjectList[0].doneAnswer != null && that.shortAnswerList[i].subjectList[0].doneAnswer != undefined) {
                                      that.testTextarea = that.shortAnswerList[i].subjectList[0].doneAnswer
                                    }
                                  } else {
                                    that.smallQuestionIndex = that.shortAnswerList[i].subjectId
                                    that.smallQuestionObj = that.shortAnswerList[i]
                                    if (that.shortAnswerList[i].doneAnswer != '' && that.shortAnswerList[i].doneAnswer != null && that.shortAnswerList[i].doneAnswer != undefined) {
                                      that.testTextarea = that.shortAnswerList[i].doneAnswer
                                    }
                                  }
                                }
                              }
                            } else if (obj.classify == '8') {
                              for (var i in that.jisuanhuida) {
                                if (res.data.data.finallyAnswerId == that.jisuanhuida[i].subjectId) {
                                  that.indexIng = i
                                  if (that.jisuanhuida[i].subjectList.length > 0) {
                                    that.smallQuestionIndex = that.jisuanhuida[i].subjectList[0].subjectId
                                    that.smallQuestionObj = that.jisuanhuida[i].subjectList[0]
                                    if (that.jisuanhuida[i].subjectList[0].doneAnswer != '' && that.jisuanhuida[i].subjectList[0].doneAnswer != null && that.jisuanhuida[i].subjectList[0].doneAnswer != undefined) {
                                      that.testTextarea = that.jisuanhuida[i].subjectList[0].doneAnswer
                                    }
                                  } else {
                                    that.smallQuestionIndex = that.jisuanhuida[i].subjectId
                                    that.smallQuestionObj = that.jisuanhuida[i]
                                    if (that.jisuanhuida[i].doneAnswer != '' && that.jisuanhuida[i].doneAnswer != null && that.jisuanhuida[i].doneAnswer != undefined) {
                                      that.testTextarea = that.jisuanhuida[i].doneAnswer
                                    }
                                  }
                                }
                              }
                            } else if (obj.classify == '9') {
                              for (var i in that.anlifenxi) {
                                if (res.data.data.finallyAnswerId == that.anlifenxi[i].subjectId) {
                                  that.indexIng = i
                                  if (that.anlifenxi[i].subjectList.length > 0) {
                                    that.smallQuestionIndex = that.anlifenxi[i].subjectList[0].subjectId
                                    that.smallQuestionObj = that.anlifenxi[i].subjectList[0]
                                    if (that.anlifenxi[i].subjectList[0].doneAnswer != '' && that.anlifenxi[i].subjectList[0].doneAnswer != null && that.anlifenxi[i].subjectList[0].doneAnswer != undefined) {
                                      that.testTextarea = that.anlifenxi[i].subjectList[0].doneAnswer
                                    }
                                  } else {
                                    that.smallQuestionIndex = that.anlifenxi[i].subjectId
                                    that.smallQuestionObj = that.anlifenxi[i]
                                    if (that.anlifenxi[i].doneAnswer != '' && that.anlifenxi[i].doneAnswer != null && that.anlifenxi[i].doneAnswer != undefined) {
                                      that.testTextarea = that.anlifenxi[i].doneAnswer
                                    }
                                  }
                                }
                              }
                            } else if (obj.classify == '10') {
                              for (var i in that.jisuan) {
                                if (res.data.data.finallyAnswerId == that.jisuan[i].subjectId) {
                                  that.indexIng = i
                                  if (that.jisuan[i].subjectList.length > 0) {
                                    that.smallQuestionIndex = that.jisuan[i].subjectList[0].subjectId
                                    that.smallQuestionObj = that.jisuan[i].subjectList[0]
                                    if (that.jisuan[i].subjectList[0].doneAnswer != '' && that.jisuan[i].subjectList[0].doneAnswer != null && that.jisuan[i].subjectList[0].doneAnswer != undefined) {
                                      that.testTextarea = that.jisuan[i].subjectList[0].doneAnswer
                                    }
                                  } else {
                                    that.smallQuestionIndex = that.jisuan[i].subjectId
                                    that.smallQuestionObj = that.jisuan[i]
                                    if (that.jisuan[i].doneAnswer != '' && that.jisuan[i].doneAnswer != null && that.jisuan[i].doneAnswer != undefined) {
                                      that.testTextarea = that.jisuan[i].doneAnswer
                                    }
                                  }
                                }
                              }
                            } else if (obj.classify == '11') {
                              for (var i in that.zonghefenxi) {
                                if (res.data.data.finallyAnswerId == that.zonghefenxi[i].subjectId) {
                                  that.indexIng = i
                                  if (that.zonghefenxi[i].subjectList.length > 0) {
                                    that.smallQuestionIndex = that.zonghefenxi[i].subjectList[0].subjectId
                                    that.smallQuestionObj = that.zonghefenxi[i].subjectList[0]
                                    if (that.zonghefenxi[i].subjectList[0].doneAnswer != '' && that.zonghefenxi[i].subjectList[0].doneAnswer != null && that.zonghefenxi[i].subjectList[0].doneAnswer != undefined) {
                                      that.testTextarea = that.zonghefenxi[i].subjectList[0].doneAnswer
                                    }
                                  } else {
                                    that.smallQuestionIndex = that.zonghefenxi[i].subjectId
                                    that.smallQuestionObj = that.zonghefenxi[i]
                                    if (that.zonghefenxi[i].doneAnswer != '' && that.zonghefenxi[i].doneAnswer != null && that.zonghefenxi[i].doneAnswer != undefined) {
                                      that.testTextarea = that.zonghefenxi[i].doneAnswer
                                    }
                                  }
                                }
                              } */


                            // for(var i in that.zonghefenxi){
                            //     // if(res.data.data.finallyAnswerId==that.zonghefenxi[i].subjectId){
                            //     //     that.indexIng=i
                            //     // }
                            //     if(that.zonghefenxi[i].subjectList.length>0){
                            //         for(var j in that.zonghefenxi[i].subjectList){
                            //             if(res.data.data.finallyAnswerId==that.zonghefenxi[i].subjectList[j].subjectId){
                            //                 that.indexIng=i
                            //                 that.smallQuestionIndex=that.zonghefenxi[i].subjectList[j].subjectId
                            //                 that.smallQuestionObj=that.zonghefenxi[i].subjectList[j]
                            //                 if(that.zonghefenxi[i].subjectList[j].doneAnswer!='' && that.zonghefenxi[i].subjectList[j].doneAnswer!=null && that.zonghefenxi[i].subjectList[j].doneAnswer!=undefined){
                            //                     that.testTextarea = that.zonghefenxi[i].subjectList[j].doneAnswer
                            //                 }
                            //             }
                            //         }
                            //     }else{
                            //         if(res.data.data.finallyAnswerId==that.zonghefenxi[i].subjectId){
                            //             that.indexIng=i
                            //             that.smallQuestionIndex=that.zonghefenxi[i].subjectId
                            //             that.smallQuestionObj=that.zonghefenxi[i]
                            //             if(that.zonghefenxi[i].subjectList[j].doneAnswer!='' && that.zonghefenxi[i].subjectList[j].doneAnswer!=null && that.zonghefenxi[i].subjectList[j].doneAnswer!=undefined){
                            //                 that.testTextarea = that.zonghefenxi[i].subjectList[j].doneAnswer
                            //             }
                            //         }
                            //     }

                            // }
                            // }
                            // }
                        }

                        if (obj.classify > 4) {
                            /* 
                              this.checkedExamId 答题卡选中id
                              this.indexIng 答题题号
                            */
                            let finallyAnswerId = res.data.data.finallyAnswerId;
                            let typesObj = {
                                5: this.jisuanfenxi,
                                6: this.synthesisList,
                                7: this.shortAnswerList,
                                8: this.anlifenxi,
                                9: this.shortAnswerList,
                                10: this.jisuan,
                                11: this.zonghefenxi,
                            }
        
                            typesObj[obj.classify].forEach((item, index) => {
                                if (item.subjectList.length) {  // 有小题
                                    item.subjectList.forEach((val, idx) => {
                                        if (val.subjectId == finallyAnswerId) {
                           
                                            this.indexIng = index;
                                            this.smallQuestionIndex = val.subjectId;
                                            this.smallQuestionObj = val;
                                            this.checkedExamId = item.subjectId;
                                            that.pageExamObj = item;
                                            val.doneAnswer && (this.testTextarea = val.doneAnswer)
                                        }
                                    })
                                } else if (item.subjectId == finallyAnswerId) {
                                    this.smallQuestionIndex = item.subjectId;
                                    this.smallQuestionObj = item;
                                    item.doneAnswer && (this.testTextarea = item.doneAnswer)
                                }

                            })
                        }
                    } else {
                        if (res.data.data.paperSubjectList[0].classify == '1') {
                            that.checkedExamId = res.data.data.paperSubjectList[0].subjectId
                        } else if (res.data.data.paperSubjectList[0].classify == '2') {
                            that.checkedExamId = res.data.data.paperSubjectList[0].subjectId
                        } else if (res.data.data.paperSubjectList[0].classify == '3') {
                            that.checkedExamId = res.data.data.paperSubjectList[0].subjectId
                        } else if (res.data.data.paperSubjectList[0].classify == '4') {
                            if (res.data.data.paperSubjectList[0].subjectList.length > 0) {
                                that.checkedExamId = res.data.data.paperSubjectList[0].subjectId
                                that.smallQuestionObj = res.data.data.paperSubjectList[0].subjectList[0]
                                that.smallQuestionIndex = res.data.data.paperSubjectList[0].subjectList[0].subjectId
                            } else {
                                that.checkedExamId = res.data.data.paperSubjectList[0].subjectId
                            }
                            that.indexIng = 0
                        }
                        that.pageExamObj = res.data.data.paperSubjectList[0]
                    }
                } else {
                    if (res.data.msg == "暂无权限") {
                        this.loading.close()
                        this.$alert('抱歉，您还未购买该课程，暂时不能做题！', '提示', {
                            confirmButtonText: '回到题库',
                            callback: action => {
                                that.$router.push({
                                    path: '/examcenter',
                                    query: {
                                        firstId: that.firstId,
                                        secondId: that.secondId,
                                    }
                                });
                            }
                        });
                    } else {
                        this.loading.close()
                        this.$message.error(res.data.msg)
                    }
                }
            }).catch(error => {
                this.loading.close()
                console.log(error)
            })
        },
        // 上一题
        uptopic() {
            var that = this
            that.isShowAnalysis = false
            if (that.pageExamObj.classify == "1") {
                if (that.indexIng - 1 < 0) {
                    // that.pageExamObj=that.checkboxExamList[0]
                    // that.checkedExamId=that.checkboxExamList[0].subjectId
                    that.indexIng = 0
                    that.$message.error('没有上一题了')
                } else {
                    that.pageExamObj = that.radioExamList[that.indexIng - 1]
                    that.checkedExamId = that.radioExamList[that.indexIng - 1].subjectId
                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                        that.radio = that.pageExamObj.doneAnswerIds
                    } else {
                        that.radio = ''
                    }
                    that.indexIng = that.indexIng - 1
                }
            } else if (that.pageExamObj.classify == "2") {
                if (that.indexIng - 1 < 0) {
                    if (that.radioExamList.length > 0) {
                        that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                        that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                        if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                            that.radio = that.pageExamObj.doneAnswerIds
                        } else {
                            that.radio = ''
                        }
                        that.indexIng = that.radioExamList.length - 1
                    } else {
                        that.indexIng = 0
                        that.$message.error('没有上一题了')
                    }
                } else {
                    that.pageExamObj = that.checkboxExamList[that.indexIng - 1]
                    that.checkedExamId = that.checkboxExamList[that.indexIng - 1].subjectId
                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                        var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                        var temp_checkList = [];
                        for (var i = 0; i < checkListArr.length; i++) {
                            temp_checkList.push(checkListArr[i]);
                            // that.checkList.push();
                        }
                        that.checkList = temp_checkList
                    }
                    that.indexIng = that.indexIng - 1
                }

            } else if (that.pageExamObj.classify == "3") {
                if (that.indexIng - 1 < 0) {
                    if (that.checkboxExamList.length > 0) {
                        that.pageExamObj = that.checkboxExamList[that.checkboxExamList.length - 1]
                        that.checkedExamId = that.checkboxExamList[that.checkboxExamList.length - 1].subjectId
                        if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                            var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                            var temp_checkList = [];
                            for (var i = 0; i < checkListArr.length; i++) {
                                temp_checkList.push(checkListArr[i]);
                                // that.checkList.push();
                            }
                            that.checkList = temp_checkList
                        } else {
                            that.checkList = []
                        }
                        that.indexIng = that.checkboxExamList.length - 1
                    } else {
                        if (that.radioExamList.length > 0) {
                            that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                            that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                            if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                that.radio = that.pageExamObj.doneAnswerIds
                            } else {
                                that.radio = ''
                            }
                            that.indexIng = that.radioExamList.length - 1
                        } else {
                            that.$message.error('没有上一题了')
                        }
                    }

                } else {
                    that.pageExamObj = that.estimateList[that.indexIng - 1]
                    that.checkedExamId = that.estimateList[that.indexIng - 1].subjectId
                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                        that.radio = that.pageExamObj.doneAnswerIds
                    } else {
                        that.radio = ''
                    }
                    that.indexIng = that.indexIng - 1
                }

            } else if (that.pageExamObj.classify == "4") {
                if (that.pageExamObj.subjectList.length > 0) { // 判断该答题有没有小题  有
                    if (that.smallQuestionIndex == that.pageExamObj.subjectList[0].subjectId) { // 如果是第一小题 切换到上一大题
                        if (that.estimateList.length > 0) { // 如果上一类型有题
                            that.pageExamObj = that.estimateList[that.estimateList.length - 1]
                            that.checkedExamId = that.estimateList[that.estimateList.length - 1].subjectId
                            if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                that.radio = that.pageExamObj.doneAnswerIds
                            } else {
                                that.radio = ''
                            }
                            that.indexIng = that.estimateList.length - 1
                        } else { // 上一类型没有题
                            if (that.checkboxExamList.length > 0) { // 再如果上一类型有题
                                that.pageExamObj = that.checkboxExamList[that.checkboxExamList.length - 1]
                                that.checkedExamId = that.checkboxExamList[that.checkboxExamList.length - 1].subjectId
                                if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                    var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                    var temp_checkList = [];
                                    for (var i = 0; i < checkListArr.length; i++) {
                                        temp_checkList.push(checkListArr[i]);
                                        // that.checkList.push();
                                    }
                                    that.checkList = temp_checkList
                                } else {
                                    that.checkList = []
                                }
                                that.indexIng = that.checkboxExamList.length - 1
                            } else { // 再如果上一类型没有题
                                if (that.radioExamList.length > 0) {
                                    that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                                    that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                        that.radio = that.pageExamObj.doneAnswerIds
                                    } else {
                                        that.radio = ''
                                    }
                                    that.indexIng = that.radioExamList.length - 1
                                } else {
                                    that.$message.error('没有上一题了')
                                }
                            }
                        }
                    } else { // 不是第一小题 查看在哪一个小标
                        for (var j in that.pageExamObj.subjectList) {
                            if (that.smallQuestionIndex == that.pageExamObj.subjectList[j].subjectId) {
                                that.smallQuestionObj = that.pageExamObj.subjectList[j - 1]
                                that.smallQuestionIndex = that.pageExamObj.subjectList[j - 1].subjectId
                                if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                    var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                    var temp_checkList = [];
                                    for (var i = 0; i < checkListArr.length; i++) {
                                        temp_checkList.push(checkListArr[i]);
                                        // that.checkList.push();
                                    }
                                    that.checkList = temp_checkList
                                } else {
                                    that.checkList = []
                                }
                            }
                        }
                    }
                } else {
                    if (that.indexIng - 1 < 0) { // 如果是第一大题
                        if (that.estimateList.length > 0) { // 如果上一类型有题
                            that.pageExamObj = that.estimateList[that.estimateList.length - 1]
                            that.checkedExamId = that.estimateList[that.estimateList.length - 1].subjectId
                            if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                that.radio = that.pageExamObj.doneAnswerIds
                            } else {
                                that.radio = ''
                            }
                            that.indexIng = that.estimateList.length - 1
                        } else { // 上一类型没有题
                            if (that.checkboxExamList.length > 0) { // 再如果上一类型有题
                                that.pageExamObj = that.checkboxExamList[that.checkboxExamList.length - 1]
                                that.checkedExamId = that.checkboxExamList[that.checkboxExamList.length - 1].subjectId
                                if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                    var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                    var temp_checkList = [];
                                    for (var i = 0; i < checkListArr.length; i++) {
                                        temp_checkList.push(checkListArr[i]);
                                        // that.checkList.push();
                                    }
                                    that.checkList = temp_checkList
                                } else {
                                    that.checkList = []
                                }
                                that.indexIng = that.checkboxExamList.length - 1
                            } else { // 再如果上一类型没有题
                                if (that.radioExamList.length > 0) {
                                    that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                                    that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                        that.radio = that.pageExamObj.doneAnswerIds
                                    } else {
                                        that.radio = ''
                                    }
                                    that.indexIng = that.radioExamList.length - 1
                                } else {
                                    that.$message.error('没有上一题了')
                                }
                            }
                        }
                    } else {

                        that.pageExamObj = that.budingxiang[that.indexIng - 1]
                        that.checkedExamId = that.budingxiang[that.indexIng - 1].subjectId
                        that.indexIng = that.indexIng - 1
                        if (that.pageExamObj.subjectList.length > 0) {
                            that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                            that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                            if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                var temp_checkList = [];
                                for (var i = 0; i < checkListArr.length; i++) {
                                    temp_checkList.push(checkListArr[i]);
                                    // that.checkList.push();
                                }
                                that.checkList = temp_checkList
                            } else {
                                that.checkList = []
                            }
                        } else {
                            that.smallQuestionObj = that.pageExamObj
                            that.smallQuestionIndex = that.pageExamObjx.subjectId
                            if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                var temp_checkList = [];
                                for (var i = 0; i < checkListArr.length; i++) {
                                    temp_checkList.push(checkListArr[i]);
                                    // that.checkList.push();
                                }
                                that.checkList = temp_checkList
                            } else {
                                that.checkList = []
                            }
                        }
                    }
                }

            } else if (that.pageExamObj.classify == "5") {
                if (that.pageExamObj.subjectList.length > 0) { // 判断该答题有没有小题  有
                    if (that.smallQuestionIndex == that.pageExamObj.subjectList[0].subjectId) { // 如果是第一小题 切换到上一大题
                        if (that.checkedExamId == that.jisuanfenxi[0].subjectId) {
                            if (that.budingxiang.length > 0) {
                                that.pageExamObj = that.budingxiang[that.budingxiang.length - 1]
                                that.checkedExamId = that.budingxiang[that.budingxiang.length - 1].subjectId
                                that.indexIng = that.budingxiang.length - 1
                                if (that.pageExamObj.subjectList.length > 0) {
                                    that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                    that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                    if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                        var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                        var temp_checkList = [];
                                        for (var i = 0; i < checkListArr.length; i++) {
                                            temp_checkList.push(checkListArr[i]);
                                            // that.checkList.push();
                                        }
                                        that.checkList = temp_checkList
                                    } else {
                                        that.checkList = []
                                    }
                                } else {
                                    that.smallQuestionObj = that.pageExamObj
                                    that.smallQuestionIndex = that.pageExamObj.subjectId
                                    if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                        var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                        var temp_checkList = [];
                                        for (var i = 0; i < checkListArr.length; i++) {
                                            temp_checkList.push(checkListArr[i]);
                                            // that.checkList.push();
                                        }
                                        that.checkList = temp_checkList
                                    } else {
                                        that.checkList = []
                                    }
                                }
                            } else {
                                if (that.estimateList.length > 0) { // 如果上一类型有题
                                    that.pageExamObj = that.estimateList[that.estimateList.length - 1]
                                    that.checkedExamId = that.estimateList[that.estimateList.length - 1].subjectId
                                    that.indexIng = that.estimateList.length - 1
                                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                        that.radio = that.pageExamObj.doneAnswerIds
                                    } else {
                                        that.radio = ''
                                    }
                                } else { // 上一类型没有题
                                    if (that.checkboxExamList.length > 0) { // 再如果上一类型有题
                                        that.pageExamObj = that.checkboxExamList[that.checkboxExamList.length - 1]
                                        that.checkedExamId = that.checkboxExamList[that.checkboxExamList.length - 1].subjectId
                                        that.indexIng = that.checkboxExamList.length - 1
                                        if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                            var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                            var temp_checkList = [];
                                            for (var i = 0; i < checkListArr.length; i++) {
                                                temp_checkList.push(checkListArr[i]);
                                                // that.checkList.push();
                                            }
                                            that.checkList = temp_checkList
                                        } else {
                                            that.checkList = []
                                        }
                                    } else { // 再如果上一类型没有题
                                        if (that.radioExamList.length > 0) {
                                            that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                                            that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                                            that.indexIng = that.radioExamList.length - 1
                                            if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                that.radio = that.pageExamObj.doneAnswerIds
                                            } else {
                                                that.radio = ''
                                            }
                                        } else {
                                            that.$message.error('没有上一题了')
                                        }
                                    }
                                }
                            }
                        } else {
                            for (var k in that.jisuanfenxi) {
                                if (that.checkedExamId == that.jisuanfenxi[k].subjectId) {
                                    that.pageExamObj = that.jisuanfenxi[k - 1]
                                    that.checkedExamId = that.jisuanfenxi[k - 1].subjectId
                                    that.indexIng = k - 1
                                    if (that.pageExamObj.subjectList.length > 0) {
                                        that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                        that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    } else {
                                        that.smallQuestionObj = that.pageExamObj
                                        that.smallQuestionIndex = that.pageExamObj.subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    }
                                }
                            }
                        }


                    } else { // 不是第一小题 查看在哪一个小标
                        for (var j in that.pageExamObj.subjectList) {
                            if (that.smallQuestionIndex == that.pageExamObj.subjectList[j].subjectId) {
                                that.smallQuestionObj = that.pageExamObj.subjectList[j - 1]
                                that.smallQuestionIndex = that.pageExamObj.subjectList[j - 1].subjectId
                                if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                    var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                    var temp_checkList = [];
                                    for (var i = 0; i < checkListArr.length; i++) {
                                        temp_checkList.push(checkListArr[i]);
                                        // that.checkList.push();
                                    }
                                    that.checkList = temp_checkList
                                } else {
                                    that.checkList = []
                                }
                            }
                        }
                    }
                } else {
                    if (that.indexIng - 1 < 0) { // 如果是第一大题
                        if (that.budingxiang.length > 0) {
                            that.pageExamObj = that.budingxiang[that.budingxiang.length - 1]
                            that.checkedExamId = that.budingxiang[that.budingxiang.length - 1].subjectId
                            that.indexIng = that.budingxiang.length - 1
                            if (that.pageExamObj.subjectList.length > 0) {
                                that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                    var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                    var temp_checkList = [];
                                    for (var i = 0; i < checkListArr.length; i++) {
                                        temp_checkList.push(checkListArr[i]);
                                        // that.checkList.push();
                                    }
                                    that.checkList = temp_checkList
                                } else {
                                    that.checkList = []
                                }
                            } else {
                                that.smallQuestionObj = that.pageExamObj
                                that.smallQuestionIndex = that.pageExamObj.subjectId
                                if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                    var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                    var temp_checkList = [];
                                    for (var i = 0; i < checkListArr.length; i++) {
                                        temp_checkList.push(checkListArr[i]);
                                        // that.checkList.push();
                                    }
                                    that.checkList = temp_checkList
                                } else {
                                    that.checkList = []
                                }
                            }

                        } else {
                            if (that.estimateList.length > 0) { // 如果上一类型有题
                                that.pageExamObj = that.estimateList[that.estimateList.length - 1]
                                that.checkedExamId = that.estimateList[that.estimateList.length - 1].subjectId
                                that.indexIng = that.estimateList.length - 1
                                if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                    that.radio = that.pageExamObj.doneAnswerIds
                                } else {
                                    that.radio = ''
                                }
                            } else { // 上一类型没有题
                                if (that.checkboxExamList.length > 0) { // 再如果上一类型有题
                                    that.pageExamObj = that.checkboxExamList[that.checkboxExamList.length - 1]
                                    that.checkedExamId = that.checkboxExamList[that.checkboxExamList.length - 1].subjectId
                                    that.indexIng = that.checkboxExamList.length - 1
                                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                        var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                        var temp_checkList = [];
                                        for (var i = 0; i < checkListArr.length; i++) {
                                            temp_checkList.push(checkListArr[i]);
                                            // that.checkList.push();
                                        }
                                        that.checkList = temp_checkList
                                    } else {
                                        that.checkList = []
                                    }
                                } else { // 再如果上一类型没有题
                                    if (that.radioExamList.length > 0) {
                                        that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                                        that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                                        that.indexIng = that.radioExamList.length - 1
                                        if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                            that.radio = that.pageExamObj.doneAnswerIds
                                        } else {
                                            that.radio = ''
                                        }
                                    } else {
                                        that.$message.error('没有上一题了')
                                    }
                                }
                            }
                        }

                    } else {
                        that.pageExamObj = that.jisuanfenxi[that.indexIng - 1]
                        that.checkedExamId = that.jisuanfenxi[that.indexIng - 1].subjectId
                        that.indexIng = that.indexIng - 1
                        if (that.pageExamObj.subjectList.length > 0) {
                            that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                            that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                            if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                var temp_checkList = [];
                                for (var i = 0; i < checkListArr.length; i++) {
                                    temp_checkList.push(checkListArr[i]);
                                    // that.checkList.push();
                                }
                                that.checkList = temp_checkList
                            } else {
                                that.checkList = []
                            }
                        } else {
                            that.smallQuestionObj = that.pageExamObj
                            that.smallQuestionIndex = that.pageExamObjx.subjectId
                            if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                var temp_checkList = [];
                                for (var i = 0; i < checkListArr.length; i++) {
                                    temp_checkList.push(checkListArr[i]);
                                    // that.checkList.push();
                                }
                                that.checkList = temp_checkList
                            } else {
                                that.checkList = []
                            }
                        }
                    }
                }

            } else if (that.pageExamObj.classify == "6") {
                if (that.pageExamObj.subjectList.length > 0) { // 判断该答题有没有小题  有
                    if (that.smallQuestionIndex == that.pageExamObj.subjectList[0].subjectId) { // 如果是第一小题 切换到上一大题
                        if (that.checkedExamId == that.synthesisList[0].subjectId) {
                            if (that.jisuanfenxi.length > 0) {
                                that.pageExamObj = that.jisuanfenxi[that.jisuanfenxi.length - 1]
                                that.checkedExamId = that.jisuanfenxi[that.jisuanfenxi.length - 1].subjectId
                                that.indexIng = that.jisuanfenxi.length - 1
                                if (that.pageExamObj.subjectList.length > 0) {
                                    that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                    that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                } else {
                                    that.smallQuestionObj = that.pageExamObj
                                    that.smallQuestionObj = that.pageExamObj.subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                }
                            } else {
                                if (that.budingxiang.length > 0) {
                                    that.pageExamObj = that.budingxiang[that.budingxiang.length - 1]
                                    that.checkedExamId = that.budingxiang[that.budingxiang.length - 1].subjectId
                                    that.indexIng = that.checkboxExamList.length - 1
                                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                        var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                        var temp_checkList = [];
                                        for (var i = 0; i < checkListArr.length; i++) {
                                            temp_checkList.push(checkListArr[i]);
                                            // that.checkList.push();
                                        }
                                        that.checkList = temp_checkList
                                    } else {
                                        that.checkList = []
                                    }
                                } else {
                                    if (that.estimateList.length > 0) { // 如果上一类型有题
                                        that.pageExamObj = that.estimateList[that.estimateList.length - 1]
                                        that.checkedExamId = that.estimateList[that.estimateList.length - 1].subjectId
                                        that.indexIng = that.estimateList.length - 1
                                        if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                            that.radio = that.pageExamObj.doneAnswerIds
                                        } else {
                                            that.radio = ''
                                        }
                                    } else { // 上一类型没有题
                                        if (that.checkboxExamList.length > 0) { // 再如果上一类型有题
                                            that.pageExamObj = that.checkboxExamList[that.checkboxExamList.length - 1]
                                            that.checkedExamId = that.checkboxExamList[that.checkboxExamList.length - 1].subjectId
                                            that.indexIng = that.checkboxExamList.length - 1
                                            if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                var temp_checkList = [];
                                                for (var i = 0; i < checkListArr.length; i++) {
                                                    temp_checkList.push(checkListArr[i]);
                                                    // that.checkList.push();
                                                }
                                                that.checkList = temp_checkList
                                            } else {
                                                that.checkList = []
                                            }
                                        } else { // 再如果上一类型没有题
                                            if (that.radioExamList.length > 0) {
                                                that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                                                that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                                                that.indexIng = that.radioExamList.length - 1
                                                if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                    that.radio = that.pageExamObj.doneAnswerIds
                                                } else {
                                                    that.radio = ''
                                                }
                                            } else {
                                                that.$message.error('没有上一题了')
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            for (var k in that.synthesisList) {
                                if (that.checkedExamId == that.synthesisList[k].subjectId) {
                                    that.pageExamObj = that.synthesisList[k - 1]
                                    that.checkedExamId = that.synthesisList[k - 1].subjectId
                                    that.indexIng = k - 1
                                    if (that.pageExamObj.subjectList.length > 0) {
                                        that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                        that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    } else {
                                        that.smallQuestionObj = that.pageExamObj
                                        that.smallQuestionIndex = that.pageExamObj.subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    }
                                }
                            }
                        }
                    } else { // 不是第一小题 查看在哪一个小标
                        for (var j in that.pageExamObj.subjectList) {
                            if (that.smallQuestionIndex == that.pageExamObj.subjectList[j].subjectId) {
                                that.smallQuestionObj = that.pageExamObj.subjectList[j - 1]
                                that.smallQuestionIndex = that.pageExamObj.subjectList[j - 1].subjectId
                                if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                    var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                    var temp_checkList = [];
                                    for (var i = 0; i < checkListArr.length; i++) {
                                        temp_checkList.push(checkListArr[i]);
                                        // that.checkList.push();
                                    }
                                    that.checkList = temp_checkList
                                } else {
                                    that.checkList = []
                                }
                            }
                        }
                    }
                } else {
                    if (that.indexIng - 1 < 0) { // 如果是第一大题
                        if (that.jisuanfenxi.length > 0) {
                            that.pageExamObj = that.jisuanfenxi[that.jisuanfenxi.length - 1]
                            that.checkedExamId = that.jisuanfenxi[that.jisuanfenxi.length - 1].subjectId
                            that.indexIng = that.jisuanfenxi.length - 1
                            if (that.pageExamObj.subjectList.length > 0) {
                                that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                } else {
                                    that.testTextarea = ''
                                }
                            } else {
                                that.smallQuestionObj = that.pageExamObj
                                that.smallQuestionObj = that.pageExamObj.subjectId
                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                } else {
                                    that.testTextarea = ''
                                }
                            }
                        } else {
                            if (that.budingxiang.length > 0) {
                                that.pageExamObj = that.budingxiang[that.budingxiang.length - 1]
                                that.checkedExamId = that.budingxiang[that.budingxiang.length - 1].subjectId
                                that.indexIng = that.budingxiang.length - 1
                                if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                    var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                    var temp_checkList = [];
                                    for (var i = 0; i < checkListArr.length; i++) {
                                        temp_checkList.push(checkListArr[i]);
                                        // that.checkList.push();
                                    }
                                    that.checkList = temp_checkList
                                } else {
                                    that.checkList = []
                                }
                            } else {
                                if (that.estimateList.length > 0) { // 如果上一类型有题
                                    that.pageExamObj = that.estimateList[that.estimateList.length - 1]
                                    that.checkedExamId = that.estimateList[that.estimateList.length - 1].subjectId
                                    that.indexIng = that.estimateList.length - 1
                                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                        that.radio = that.pageExamObj.doneAnswerIds
                                    } else {
                                        that.radio = ''
                                    }
                                } else { // 上一类型没有题
                                    if (that.checkboxExamList.length > 0) { // 再如果上一类型有题
                                        that.pageExamObj = that.checkboxExamList[that.checkboxExamList.length - 1]
                                        that.checkedExamId = that.checkboxExamList[that.checkboxExamList.length - 1].subjectId
                                        that.indexIng = that.checkboxExamList.length - 1
                                        if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                            var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                            var temp_checkList = [];
                                            for (var i = 0; i < checkListArr.length; i++) {
                                                temp_checkList.push(checkListArr[i]);
                                                // that.checkList.push();
                                            }
                                            that.checkList = temp_checkList
                                        } else {
                                            that.checkList = []
                                        }
                                    } else { // 再如果上一类型没有题
                                        if (that.radioExamList.length > 0) {
                                            that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                                            that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                                            that.indexIng = that.radioExamList.length - 1
                                            if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                that.radio = that.pageExamObj.doneAnswerIds
                                            } else {
                                                that.radio = ''
                                            }
                                        } else {
                                            that.$message.error('没有上一题了')
                                        }
                                    }
                                }
                            }
                        }


                    } else {
                        that.pageExamObj = that.synthesisList[that.indexIng - 1]
                        that.checkedExamId = that.synthesisList[that.indexIng - 1].subjectId
                        that.indexIng = that.indexIng - 1
                        if (that.pageExamObj.subjectList.length > 0) {
                            that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                            that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                            if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                var temp_checkList = [];
                                for (var i = 0; i < checkListArr.length; i++) {
                                    temp_checkList.push(checkListArr[i]);
                                    // that.checkList.push();
                                }
                                that.checkList = temp_checkList
                            } else {
                                that.checkList = []
                            }
                        } else {
                            that.smallQuestionObj = that.pageExamObj
                            that.smallQuestionIndex = that.pageExamObjx.subjectId
                            if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                var temp_checkList = [];
                                for (var i = 0; i < checkListArr.length; i++) {
                                    temp_checkList.push(checkListArr[i]);
                                    // that.checkList.push();
                                }
                                that.checkList = temp_checkList
                            } else {
                                that.checkList = []
                            }
                        }
                    }
                }
            } else if (that.pageExamObj.classify == "7") {
                if (that.pageExamObj.subjectList.length > 0) { // 判断该答题有没有小题  有
                    if (that.smallQuestionIndex == that.pageExamObj.subjectList[0].subjectId) { // 如果是第一小题 切换到上一大题
                        if (that.checkedExamId == that.shortAnswerList[0].subjectId) {
                            if (that.synthesisList.length > 0) {
                                that.pageExamObj = that.synthesisList[that.synthesisList.length - 1]
                                that.checkedExamId = that.synthesisList[that.synthesisList.length - 1].subjectId
                                that.indexIng = that.synthesisList.length - 1
                                if (that.pageExamObj.subjectList.length > 0) {
                                    that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                    that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                } else {
                                    that.smallQuestionObj = that.pageExamObj
                                    that.smallQuestionObj = that.pageExamObj.subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                }
                            } else {
                                if (that.jisuanfenxi.length > 0) {
                                    that.pageExamObj = that.jisuanfenxi[that.jisuanfenxi.length - 1]
                                    that.checkedExamId = that.jisuanfenxi[that.jisuanfenxi.length - 1].subjectId
                                    that.indexIng = that.jisuanfenxi.length - 1
                                    if (that.pageExamObj.subjectList.length > 0) {
                                        that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                        that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    } else {
                                        that.smallQuestionObj = that.pageExamObj
                                        that.smallQuestionObj = that.pageExamObj.subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    }
                                } else {
                                    if (that.budingxiang.length > 0) {
                                        that.pageExamObj = that.budingxiang[that.budingxiang.length - 1]
                                        that.checkedExamId = that.budingxiang[that.budingxiang.length - 1].subjectId
                                        that.indexIng = that.checkboxExamList.length - 1
                                        if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                            var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                            var temp_checkList = [];
                                            for (var i = 0; i < checkListArr.length; i++) {
                                                temp_checkList.push(checkListArr[i]);
                                                // that.checkList.push();
                                            }
                                            that.checkList = temp_checkList
                                        } else {
                                            that.checkList = []
                                        }
                                    } else {
                                        if (that.estimateList.length > 0) { // 如果上一类型有题
                                            that.pageExamObj = that.estimateList[that.estimateList.length - 1]
                                            that.checkedExamId = that.estimateList[that.estimateList.length - 1].subjectId
                                            that.indexIng = that.estimateList.length - 1
                                            if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                that.radio = that.pageExamObj.doneAnswerIds
                                            } else {
                                                that.radio = ''
                                            }
                                        } else { // 上一类型没有题
                                            if (that.checkboxExamList.length > 0) { // 再如果上一类型有题
                                                that.pageExamObj = that.checkboxExamList[that.checkboxExamList.length - 1]
                                                that.checkedExamId = that.checkboxExamList[that.checkboxExamList.length - 1].subjectId
                                                that.indexIng = that.checkboxExamList.length - 1
                                                if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                    var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                    var temp_checkList = [];
                                                    for (var i = 0; i < checkListArr.length; i++) {
                                                        temp_checkList.push(checkListArr[i]);
                                                        // that.checkList.push();
                                                    }
                                                    that.checkList = temp_checkList
                                                } else {
                                                    that.checkList = []
                                                }
                                            } else { // 再如果上一类型没有题
                                                if (that.radioExamList.length > 0) {
                                                    that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                                                    that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                                                    that.indexIng = that.radioExamList.length - 1
                                                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                        that.radio = that.pageExamObj.doneAnswerIds
                                                    } else {
                                                        that.radio = ''
                                                    }
                                                } else {
                                                    that.$message.error('没有上一题了')
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            for (var k in that.shortAnswerList) {
                                if (that.checkedExamId == that.shortAnswerList[k].subjectId) {
                                    that.pageExamObj = that.shortAnswerList[k - 1]
                                    that.checkedExamId = that.shortAnswerList[k - 1].subjectId
                                    that.indexIng = k - 1
                                    if (that.pageExamObj.subjectList.length > 0) {
                                        that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                        that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    } else {
                                        that.smallQuestionObj = that.pageExamObj
                                        that.smallQuestionIndex = that.pageExamObj.subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    }
                                }
                            }
                        }


                    } else { // 不是第一小题 查看在哪一个小标
                        for (var j in that.pageExamObj.subjectList) {
                            if (that.smallQuestionIndex == that.pageExamObj.subjectList[j].subjectId) {
                                that.smallQuestionObj = that.pageExamObj.subjectList[j - 1]
                                that.smallQuestionIndex = that.pageExamObj.subjectList[j - 1].subjectId
                                if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                    var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                    var temp_checkList = [];
                                    for (var i = 0; i < checkListArr.length; i++) {
                                        temp_checkList.push(checkListArr[i]);
                                        // that.checkList.push();
                                    }
                                    that.checkList = temp_checkList
                                } else {
                                    that.checkList = []
                                }
                            }
                        }
                    }
                } else {
                    if (that.indexIng - 1 < 0) { // 如果是第一大题
                        if (that.synthesisList.length > 0) {
                            that.pageExamObj = that.synthesisList[that.synthesisList.length - 1]
                            that.checkedExamId = that.synthesisList[that.synthesisList.length - 1].subjectId
                            that.indexIng = that.synthesisList.length - 1
                            if (that.pageExamObj.subjectList.length > 0) {
                                that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                } else {
                                    that.testTextarea = ''
                                }
                            } else {
                                that.smallQuestionObj = that.pageExamObj
                                that.smallQuestionObj = that.pageExamObj.subjectId
                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                } else {
                                    that.testTextarea = ''
                                }
                            }
                        } else {
                            if (that.jisuanfenxi.length > 0) {
                                that.pageExamObj = that.jisuanfenxi[that.jisuanfenxi.length - 1]
                                that.checkedExamId = that.jisuanfenxi[that.jisuanfenxi.length - 1].subjectId
                                that.indexIng = that.jisuanfenxi.length - 1
                                if (that.pageExamObj.subjectList.length > 0) {
                                    that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                    that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                } else {
                                    that.smallQuestionObj = that.pageExamObj
                                    that.smallQuestionObj = that.pageExamObj.subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                }
                            } else {
                                if (that.budingxiang.length > 0) {
                                    that.pageExamObj = that.budingxiang[that.budingxiang.length - 1]
                                    that.checkedExamId = that.budingxiang[that.budingxiang.length - 1].subjectId
                                    that.indexIng = that.checkboxExamList.length - 1
                                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                        var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                        var temp_checkList = [];
                                        for (var i = 0; i < checkListArr.length; i++) {
                                            temp_checkList.push(checkListArr[i]);
                                            // that.checkList.push();
                                        }
                                        that.checkList = temp_checkList
                                    } else {
                                        that.checkList = []
                                    }
                                } else {
                                    if (that.estimateList.length > 0) { // 如果上一类型有题
                                        that.pageExamObj = that.estimateList[that.estimateList.length - 1]
                                        that.checkedExamId = that.estimateList[that.estimateList.length - 1].subjectId
                                        that.indexIng = that.estimateList.length - 1
                                        if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                            that.radio = that.pageExamObj.doneAnswerIds
                                        } else {
                                            that.radio = ''
                                        }
                                    } else { // 上一类型没有题
                                        if (that.checkboxExamList.length > 0) { // 再如果上一类型有题
                                            that.pageExamObj = that.checkboxExamList[that.checkboxExamList.length - 1]
                                            that.checkedExamId = that.checkboxExamList[that.checkboxExamList.length - 1].subjectId
                                            that.indexIng = that.checkboxExamList.length - 1
                                            if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                var temp_checkList = [];
                                                for (var i = 0; i < checkListArr.length; i++) {
                                                    temp_checkList.push(checkListArr[i]);
                                                    // that.checkList.push();
                                                }
                                                that.checkList = temp_checkList
                                            } else {
                                                that.checkList = []
                                            }
                                        } else { // 再如果上一类型没有题
                                            if (that.radioExamList.length > 0) {
                                                that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                                                that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                                                that.indexIng = that.radioExamList.length - 1
                                                if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                    that.radio = that.pageExamObj.doneAnswerIds
                                                } else {
                                                    that.radio = ''
                                                }
                                            } else {
                                                that.$message.error('没有上一题了')
                                            }
                                        }
                                    }
                                }
                            }
                        }


                    } else {
                        that.pageExamObj = that.shortAnswerList[that.indexIng - 1]
                        that.checkedExamId = that.shortAnswerList[that.indexIng - 1].subjectId
                        that.indexIng = that.indexIng - 1
                        if (that.pageExamObj.subjectList.length > 0) {
                            that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                            that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                            if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                var temp_checkList = [];
                                for (var i = 0; i < checkListArr.length; i++) {
                                    temp_checkList.push(checkListArr[i]);
                                    // that.checkList.push();
                                }
                                that.checkList = temp_checkList
                            } else {
                                that.checkList = []
                            }
                        } else {
                            that.smallQuestionObj = that.pageExamObj
                            that.smallQuestionIndex = that.pageExamObjx.subjectId
                            if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                var temp_checkList = [];
                                for (var i = 0; i < checkListArr.length; i++) {
                                    temp_checkList.push(checkListArr[i]);
                                    // that.checkList.push();
                                }
                                that.checkList = temp_checkList
                            } else {
                                that.checkList = []
                            }
                        }
                    }
                }
            } else if (that.pageExamObj.classify == "8") {
                if (that.pageExamObj.subjectList.length > 0) { // 判断该答题有没有小题  有
                    if (that.smallQuestionIndex == that.pageExamObj.subjectList[0].subjectId) { // 如果是第一小题 切换到上一大题
                        if (that.checkedExamId == that.jisuanhuida[0].subjectId) {
                            if (that.shortAnswerList.length > 0) {
                                that.pageExamObj = that.shortAnswerList[that.shortAnswerList.length - 1]
                                that.checkedExamId = that.shortAnswerList[that.shortAnswerList.length - 1].subjectId
                                that.indexIng = that.shortAnswerList.length - 1
                                if (that.pageExamObj.subjectList.length > 0) {
                                    that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                    that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                } else {
                                    that.smallQuestionObj = that.pageExamObj
                                    that.smallQuestionObj = that.pageExamObj.subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                }
                            } else {
                                if (that.synthesisList.length > 0) {
                                    that.pageExamObj = that.synthesisList[that.synthesisList.length - 1]
                                    that.checkedExamId = that.synthesisList[that.synthesisList.length - 1].subjectId
                                    that.indexIng = that.synthesisList.length - 1
                                    if (that.pageExamObj.subjectList.length > 0) {
                                        that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                        that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    } else {
                                        that.smallQuestionObj = that.pageExamObj
                                        that.smallQuestionObj = that.pageExamObj.subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    }
                                } else {
                                    if (that.jisuanfenxi.length > 0) {
                                        that.pageExamObj = that.jisuanfenxi[that.jisuanfenxi.length - 1]
                                        that.checkedExamId = that.jisuanfenxi[that.jisuanfenxi.length - 1].subjectId
                                        that.indexIng = that.jisuanfenxi.length - 1
                                        if (that.pageExamObj.subjectList.length > 0) {
                                            that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                            that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                            if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                that.testTextarea = that.smallQuestionObj.doneAnswer
                                            } else {
                                                that.testTextarea = ''
                                            }
                                        } else {
                                            that.smallQuestionObj = that.pageExamObj
                                            that.smallQuestionObj = that.pageExamObj.subjectId
                                            if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                that.testTextarea = that.smallQuestionObj.doneAnswer
                                            } else {
                                                that.testTextarea = ''
                                            }
                                        }
                                    } else {
                                        if (that.budingxiang.length > 0) {
                                            that.pageExamObj = that.budingxiang[that.budingxiang.length - 1]
                                            that.checkedExamId = that.budingxiang[that.budingxiang.length - 1].subjectId
                                            that.indexIng = that.checkboxExamList.length - 1
                                            if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                var temp_checkList = [];
                                                for (var i = 0; i < checkListArr.length; i++) {
                                                    temp_checkList.push(checkListArr[i]);
                                                    // that.checkList.push();
                                                }
                                                that.checkList = temp_checkList
                                            } else {
                                                that.checkList = []
                                            }
                                        } else {
                                            if (that.estimateList.length > 0) { // 如果上一类型有题
                                                that.pageExamObj = that.estimateList[that.estimateList.length - 1]
                                                that.checkedExamId = that.estimateList[that.estimateList.length - 1].subjectId
                                                that.indexIng = that.estimateList.length - 1
                                                if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                    that.radio = that.pageExamObj.doneAnswerIds
                                                } else {
                                                    that.radio = ''
                                                }
                                            } else { // 上一类型没有题
                                                if (that.checkboxExamList.length > 0) { // 再如果上一类型有题
                                                    that.pageExamObj = that.checkboxExamList[that.checkboxExamList.length - 1]
                                                    that.checkedExamId = that.checkboxExamList[that.checkboxExamList.length - 1].subjectId
                                                    that.indexIng = that.checkboxExamList.length - 1
                                                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                        var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                        var temp_checkList = [];
                                                        for (var i = 0; i < checkListArr.length; i++) {
                                                            temp_checkList.push(checkListArr[i]);
                                                            // that.checkList.push();
                                                        }
                                                        that.checkList = temp_checkList
                                                    } else {
                                                        that.checkList = []
                                                    }
                                                } else { // 再如果上一类型没有题
                                                    if (that.radioExamList.length > 0) {
                                                        that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                                                        that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                                                        that.indexIng = that.radioExamList.length - 1
                                                        if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                            that.radio = that.pageExamObj.doneAnswerIds
                                                        } else {
                                                            that.radio = ''
                                                        }
                                                    } else {
                                                        that.$message.error('没有上一题了')
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            for (var k in that.jisuanhuida) {
                                if (that.checkedExamId == that.jisuanhuida[k].subjectId) {
                                    that.pageExamObj = that.jisuanhuida[k - 1]
                                    that.checkedExamId = that.jisuanhuida[k - 1].subjectId
                                    that.indexIng = k - 1
                                    if (that.pageExamObj.subjectList.length > 0) {
                                        that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                        that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    } else {
                                        that.smallQuestionObj = that.pageExamObj
                                        that.smallQuestionIndex = that.pageExamObj.subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    }
                                }
                            }
                        }



                    } else { // 不是第一小题 查看在哪一个小标
                        for (var j in that.pageExamObj.subjectList) {
                            if (that.smallQuestionIndex == that.pageExamObj.subjectList[j].subjectId) {
                                that.smallQuestionObj = that.pageExamObj.subjectList[j - 1]
                                that.smallQuestionIndex = that.pageExamObj.subjectList[j - 1].subjectId
                                if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                    var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                    var temp_checkList = [];
                                    for (var i = 0; i < checkListArr.length; i++) {
                                        temp_checkList.push(checkListArr[i]);
                                        // that.checkList.push();
                                    }
                                    that.checkList = temp_checkList
                                } else {
                                    that.checkList = []
                                }
                            }
                        }
                    }
                } else {
                    if (that.indexIng - 1 < 0) { // 如果是第一大题
                        if (that.shortAnswerList.length > 0) {
                            that.pageExamObj = that.shortAnswerList[that.shortAnswerList.length - 1]
                            that.checkedExamId = that.shortAnswerList[that.shortAnswerList.length - 1].subjectId
                            that.indexIng = that.shortAnswerList.length - 1
                            if (that.pageExamObj.subjectList.length > 0) {
                                that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                } else {
                                    that.testTextarea = ''
                                }
                            } else {
                                that.smallQuestionObj = that.pageExamObj
                                that.smallQuestionObj = that.pageExamObj.subjectId
                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                } else {
                                    that.testTextarea = ''
                                }
                            }
                        } else {
                            if (that.synthesisList.length > 0) {
                                that.pageExamObj = that.synthesisList[that.synthesisList.length - 1]
                                that.checkedExamId = that.synthesisList[that.synthesisList.length - 1].subjectId
                                that.indexIng = that.synthesisList.length - 1
                                if (that.pageExamObj.subjectList.length > 0) {
                                    that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                    that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                } else {
                                    that.smallQuestionObj = that.pageExamObj
                                    that.smallQuestionObj = that.pageExamObj.subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                }
                            } else {
                                if (that.jisuanfenxi.length > 0) {
                                    that.pageExamObj = that.jisuanfenxi[that.jisuanfenxi.length - 1]
                                    that.checkedExamId = that.jisuanfenxi[that.jisuanfenxi.length - 1].subjectId
                                    that.indexIng = that.jisuanfenxi.length - 1
                                    if (that.pageExamObj.subjectList.length > 0) {
                                        that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                        that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    } else {
                                        that.smallQuestionObj = that.pageExamObj
                                        that.smallQuestionObj = that.pageExamObj.subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    }
                                } else {
                                    if (that.budingxiang.length > 0) {
                                        that.pageExamObj = that.budingxiang[that.budingxiang.length - 1]
                                        that.checkedExamId = that.budingxiang[that.budingxiang.length - 1].subjectId
                                        that.indexIng = that.checkboxExamList.length - 1
                                        if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                            var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                            var temp_checkList = [];
                                            for (var i = 0; i < checkListArr.length; i++) {
                                                temp_checkList.push(checkListArr[i]);
                                                // that.checkList.push();
                                            }
                                            that.checkList = temp_checkList
                                        } else {
                                            that.checkList = []
                                        }
                                    } else {
                                        if (that.estimateList.length > 0) { // 如果上一类型有题
                                            that.pageExamObj = that.estimateList[that.estimateList.length - 1]
                                            that.checkedExamId = that.estimateList[that.estimateList.length - 1].subjectId
                                            that.indexIng = that.estimateList.length - 1
                                            if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                that.radio = that.pageExamObj.doneAnswerIds
                                            } else {
                                                that.radio = ''
                                            }
                                        } else { // 上一类型没有题
                                            if (that.checkboxExamList.length > 0) { // 再如果上一类型有题
                                                that.pageExamObj = that.checkboxExamList[that.checkboxExamList.length - 1]
                                                that.checkedExamId = that.checkboxExamList[that.checkboxExamList.length - 1].subjectId
                                                that.indexIng = that.checkboxExamList.length - 1
                                                if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                    var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                    var temp_checkList = [];
                                                    for (var i = 0; i < checkListArr.length; i++) {
                                                        temp_checkList.push(checkListArr[i]);
                                                        // that.checkList.push();
                                                    }
                                                    that.checkList = temp_checkList
                                                } else {
                                                    that.checkList = []
                                                }
                                            } else { // 再如果上一类型没有题
                                                if (that.radioExamList.length > 0) {
                                                    that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                                                    that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                                                    that.indexIng = that.radioExamList.length - 1
                                                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                        that.radio = that.pageExamObj.doneAnswerIds
                                                    } else {
                                                        that.radio = ''
                                                    }
                                                } else {
                                                    that.$message.error('没有上一题了')
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }



                    } else {
                        that.pageExamObj = that.jisuanhuida[that.indexIng - 1]
                        that.checkedExamId = that.jisuanhuida[that.indexIng - 1].subjectId
                        that.indexIng = that.indexIng - 1
                        if (that.pageExamObj.subjectList.length > 0) {
                            that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                            that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                            if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                var temp_checkList = [];
                                for (var i = 0; i < checkListArr.length; i++) {
                                    temp_checkList.push(checkListArr[i]);
                                    // that.checkList.push();
                                }
                                that.checkList = temp_checkList
                            } else {
                                that.checkList = []
                            }
                        } else {
                            that.smallQuestionObj = that.pageExamObj
                            that.smallQuestionIndex = that.pageExamObjx.subjectId
                            if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                var temp_checkList = [];
                                for (var i = 0; i < checkListArr.length; i++) {
                                    temp_checkList.push(checkListArr[i]);
                                    // that.checkList.push();
                                }
                                that.checkList = temp_checkList
                            } else {
                                that.checkList = []
                            }
                        }
                    }
                }
            } else if (that.pageExamObj.classify == "9") {
                if (that.pageExamObj.subjectList.length > 0) { // 判断该答题有没有小题  有
                    if (that.smallQuestionIndex == that.pageExamObj.subjectList[0].subjectId) { // 如果是第一小题 切换到上一大题
                        if (that.checkedExamId == that.anlifenxi[0].subjectId) {
                            if (that.jisuanhuida.length > 0) {
                                that.pageExamObj = that.jisuanhuida[that.jisuanhuida.length - 1]
                                that.checkedExamId = that.jisuanhuida[that.jisuanhuida.length - 1].subjectId
                                that.indexIng = that.jisuanhuida.length - 1
                                if (that.pageExamObj.subjectList.length > 0) {
                                    that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                    that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                } else {
                                    that.smallQuestionObj = that.pageExamObj
                                    that.smallQuestionObj = that.pageExamObj.subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                }
                            } else {
                                if (that.shortAnswerList.length > 0) {
                                    that.pageExamObj = that.shortAnswerList[that.shortAnswerList.length - 1]
                                    that.checkedExamId = that.shortAnswerList[that.shortAnswerList.length - 1].subjectId
                                    that.indexIng = that.shortAnswerList.length - 1
                                    if (that.pageExamObj.subjectList.length > 0) {
                                        that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                        that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    } else {
                                        that.smallQuestionObj = that.pageExamObj
                                        that.smallQuestionObj = that.pageExamObj.subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    }
                                } else {
                                    if (that.synthesisList.length > 0) {
                                        that.pageExamObj = that.synthesisList[that.synthesisList.length - 1]
                                        that.checkedExamId = that.synthesisList[that.synthesisList.length - 1].subjectId
                                        that.indexIng = that.synthesisList.length - 1
                                        if (that.pageExamObj.subjectList.length > 0) {
                                            that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                            that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                            if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                that.testTextarea = that.smallQuestionObj.doneAnswer
                                            } else {
                                                that.testTextarea = ''
                                            }
                                        } else {
                                            that.smallQuestionObj = that.pageExamObj
                                            that.smallQuestionObj = that.pageExamObj.subjectId
                                            if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                that.testTextarea = that.smallQuestionObj.doneAnswer
                                            } else {
                                                that.testTextarea = ''
                                            }
                                        }
                                    } else {
                                        if (that.jisuanfenxi.length > 0) {
                                            that.pageExamObj = that.jisuanfenxi[that.jisuanfenxi.length - 1]
                                            that.checkedExamId = that.jisuanfenxi[that.jisuanfenxi.length - 1].subjectId
                                            that.indexIng = that.jisuanfenxi.length - 1
                                            if (that.pageExamObj.subjectList.length > 0) {
                                                that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                                that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                                } else {
                                                    that.testTextarea = ''
                                                }
                                            } else {
                                                that.smallQuestionObj = that.pageExamObj
                                                that.smallQuestionObj = that.pageExamObj.subjectId
                                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                                } else {
                                                    that.testTextarea = ''
                                                }
                                            }
                                        } else {
                                            if (that.budingxiang.length > 0) {
                                                that.pageExamObj = that.budingxiang[that.budingxiang.length - 1]
                                                that.checkedExamId = that.budingxiang[that.budingxiang.length - 1].subjectId
                                                that.indexIng = that.checkboxExamList.length - 1
                                                if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                    var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                    var temp_checkList = [];
                                                    for (var i = 0; i < checkListArr.length; i++) {
                                                        temp_checkList.push(checkListArr[i]);
                                                        // that.checkList.push();
                                                    }
                                                    that.checkList = temp_checkList
                                                } else {
                                                    that.checkList = []
                                                }
                                            } else {
                                                if (that.estimateList.length > 0) { // 如果上一类型有题
                                                    that.pageExamObj = that.estimateList[that.estimateList.length - 1]
                                                    that.checkedExamId = that.estimateList[that.estimateList.length - 1].subjectId
                                                    that.indexIng = that.estimateList.length - 1
                                                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                        that.radio = that.pageExamObj.doneAnswerIds
                                                    } else {
                                                        that.radio = ''
                                                    }
                                                } else { // 上一类型没有题
                                                    if (that.checkboxExamList.length > 0) { // 再如果上一类型有题
                                                        that.pageExamObj = that.checkboxExamList[that.checkboxExamList.length - 1]
                                                        that.checkedExamId = that.checkboxExamList[that.checkboxExamList.length - 1].subjectId
                                                        that.indexIng = that.checkboxExamList.length - 1
                                                        if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                            var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                            var temp_checkList = [];
                                                            for (var i = 0; i < checkListArr.length; i++) {
                                                                temp_checkList.push(checkListArr[i]);
                                                                // that.checkList.push();
                                                            }
                                                            that.checkList = temp_checkList
                                                        } else {
                                                            that.checkList = []
                                                        }
                                                    } else { // 再如果上一类型没有题
                                                        if (that.radioExamList.length > 0) {
                                                            that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                                                            that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                                                            that.indexIng = that.radioExamList.length - 1
                                                            if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                                that.radio = that.pageExamObj.doneAnswerIds
                                                            } else {
                                                                that.radio = ''
                                                            }
                                                        } else {
                                                            that.$message.error('没有上一题了')
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            for (var k in that.anlifenxi) {
                                if (that.checkedExamId == that.anlifenxi[k].subjectId) {
                                    that.pageExamObj = that.anlifenxi[k - 1]
                                    that.checkedExamId = that.anlifenxi[k - 1].subjectId
                                    that.indexIng = k - 1
                                    if (that.pageExamObj.subjectList.length > 0) {
                                        that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                        that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    } else {
                                        that.smallQuestionObj = that.pageExamObj
                                        that.smallQuestionIndex = that.pageExamObj.subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    }
                                }
                            }
                        }

                    } else { // 不是第一小题 查看在哪一个小标
                        for (var j in that.pageExamObj.subjectList) {
                            if (that.smallQuestionIndex == that.pageExamObj.subjectList[j].subjectId) {
                                that.smallQuestionObj = that.pageExamObj.subjectList[j - 1]
                                that.smallQuestionIndex = that.pageExamObj.subjectList[j - 1].subjectId
                                if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                    var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                    var temp_checkList = [];
                                    for (var i = 0; i < checkListArr.length; i++) {
                                        temp_checkList.push(checkListArr[i]);
                                        // that.checkList.push();
                                    }
                                    that.checkList = temp_checkList
                                } else {
                                    that.checkList = []
                                }
                            }
                        }
                    }
                } else {
                    if (that.indexIng - 1 < 0) { // 如果是第一大题
                        if (that.jisuanhuida.length > 0) {
                            that.pageExamObj = that.jisuanhuida[that.jisuanhuida.length - 1]
                            that.checkedExamId = that.jisuanhuida[that.jisuanhuida.length - 1].subjectId
                            that.indexIng = that.jisuanhuida.length - 1
                            if (that.pageExamObj.subjectList.length > 0) {
                                that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                } else {
                                    that.testTextarea = ''
                                }
                            } else {
                                that.smallQuestionObj = that.pageExamObj
                                that.smallQuestionObj = that.pageExamObj.subjectId
                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                } else {
                                    that.testTextarea = ''
                                }
                            }
                        } else {
                            if (that.shortAnswerList.length > 0) {
                                that.pageExamObj = that.shortAnswerList[that.shortAnswerList.length - 1]
                                that.checkedExamId = that.shortAnswerList[that.shortAnswerList.length - 1].subjectId
                                that.indexIng = that.shortAnswerList.length - 1
                                if (that.pageExamObj.subjectList.length > 0) {
                                    that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                    that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                } else {
                                    that.smallQuestionObj = that.pageExamObj
                                    that.smallQuestionObj = that.pageExamObj.subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                }
                            } else {
                                if (that.synthesisList.length > 0) {
                                    that.pageExamObj = that.synthesisList[that.synthesisList.length - 1]
                                    that.checkedExamId = that.synthesisList[that.synthesisList.length - 1].subjectId
                                    that.indexIng = that.synthesisList.length - 1
                                    if (that.pageExamObj.subjectList.length > 0) {
                                        that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                        that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    } else {
                                        that.smallQuestionObj = that.pageExamObj
                                        that.smallQuestionObj = that.pageExamObj.subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    }
                                } else {
                                    if (that.jisuanfenxi.length > 0) {
                                        that.pageExamObj = that.jisuanfenxi[that.jisuanfenxi.length - 1]
                                        that.checkedExamId = that.jisuanfenxi[that.jisuanfenxi.length - 1].subjectId
                                        that.indexIng = that.jisuanfenxi.length - 1
                                        if (that.pageExamObj.subjectList.length > 0) {
                                            that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                            that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                            if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                that.testTextarea = that.smallQuestionObj.doneAnswer
                                            } else {
                                                that.testTextarea = ''
                                            }
                                        } else {
                                            that.smallQuestionObj = that.pageExamObj
                                            that.smallQuestionObj = that.pageExamObj.subjectId
                                            if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                that.testTextarea = that.smallQuestionObj.doneAnswer
                                            } else {
                                                that.testTextarea = ''
                                            }
                                        }
                                    } else {
                                        if (that.budingxiang.length > 0) {
                                            that.pageExamObj = that.budingxiang[that.budingxiang.length - 1]
                                            that.checkedExamId = that.budingxiang[that.budingxiang.length - 1].subjectId
                                            that.indexIng = that.checkboxExamList.length - 1
                                            if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                var temp_checkList = [];
                                                for (var i = 0; i < checkListArr.length; i++) {
                                                    temp_checkList.push(checkListArr[i]);
                                                    // that.checkList.push();
                                                }
                                                that.checkList = temp_checkList
                                            } else {
                                                that.checkList = []
                                            }
                                        } else {
                                            if (that.estimateList.length > 0) { // 如果上一类型有题
                                                that.pageExamObj = that.estimateList[that.estimateList.length - 1]
                                                that.checkedExamId = that.estimateList[that.estimateList.length - 1].subjectId
                                                that.indexIng = that.estimateList.length - 1
                                                if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                    that.radio = that.pageExamObj.doneAnswerIds
                                                } else {
                                                    that.radio = ''
                                                }
                                            } else { // 上一类型没有题
                                                if (that.checkboxExamList.length > 0) { // 再如果上一类型有题
                                                    that.pageExamObj = that.checkboxExamList[that.checkboxExamList.length - 1]
                                                    that.checkedExamId = that.checkboxExamList[that.checkboxExamList.length - 1].subjectId
                                                    that.indexIng = that.checkboxExamList.length - 1
                                                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                        var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                        var temp_checkList = [];
                                                        for (var i = 0; i < checkListArr.length; i++) {
                                                            temp_checkList.push(checkListArr[i]);
                                                            // that.checkList.push();
                                                        }
                                                        that.checkList = temp_checkList
                                                    } else {
                                                        that.checkList = []
                                                    }
                                                } else { // 再如果上一类型没有题
                                                    if (that.radioExamList.length > 0) {
                                                        that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                                                        that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                                                        that.indexIng = that.radioExamList.length - 1
                                                        if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                            that.radio = that.pageExamObj.doneAnswerIds
                                                        } else {
                                                            that.radio = ''
                                                        }
                                                    } else {
                                                        that.$message.error('没有上一题了')
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }




                    } else {
                        that.pageExamObj = that.anlifenxi[that.indexIng - 1]
                        that.checkedExamId = that.anlifenxi[that.indexIng - 1].subjectId
                        that.indexIng = that.indexIng - 1
                        if (that.pageExamObj.subjectList.length > 0) {
                            that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                            that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                            if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                var temp_checkList = [];
                                for (var i = 0; i < checkListArr.length; i++) {
                                    temp_checkList.push(checkListArr[i]);
                                    // that.checkList.push();
                                }
                                that.checkList = temp_checkList
                            } else {
                                that.checkList = []
                            }
                        } else {
                            that.smallQuestionObj = that.pageExamObj
                            that.smallQuestionIndex = that.pageExamObjx.subjectId
                            if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                var temp_checkList = [];
                                for (var i = 0; i < checkListArr.length; i++) {
                                    temp_checkList.push(checkListArr[i]);
                                    // that.checkList.push();
                                }
                                that.checkList = temp_checkList
                            } else {
                                that.checkList = []
                            }
                        }
                    }
                }
            } else if (that.pageExamObj.classify == "10") {
                if (that.pageExamObj.subjectList.length > 0) { // 判断该答题有没有小题  有
                    if (that.smallQuestionIndex == that.pageExamObj.subjectList[0].subjectId) { // 如果是第一小题 切换到上一大题
                        if (that.checkedExamId == that.jisuan[0].subjectId) {
                            if (that.anlifenxi.length > 0) {
                                that.pageExamObj = that.anlifenxi[that.anlifenxi.length - 1]
                                that.checkedExamId = that.anlifenxi[that.anlifenxi.length - 1].subjectId
                                that.indexIng = that.anlifenxi.length - 1
                                if (that.pageExamObj.subjectList.length > 0) {
                                    that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                    that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                } else {
                                    that.smallQuestionObj = that.pageExamObj
                                    that.smallQuestionObj = that.pageExamObj.subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                }
                            } else {
                                if (that.jisuanhuida.length > 0) {
                                    that.pageExamObj = that.jisuanhuida[that.jisuanhuida.length - 1]
                                    that.checkedExamId = that.jisuanhuida[that.jisuanhuida.length - 1].subjectId
                                    that.indexIng = that.jisuanhuida.length - 1
                                    if (that.pageExamObj.subjectList.length > 0) {
                                        that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                        that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    } else {
                                        that.smallQuestionObj = that.pageExamObj
                                        that.smallQuestionObj = that.pageExamObj.subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    }
                                } else {
                                    if (that.shortAnswerList.length > 0) {
                                        that.pageExamObj = that.shortAnswerList[that.shortAnswerList.length - 1]
                                        that.checkedExamId = that.shortAnswerList[that.shortAnswerList.length - 1].subjectId
                                        that.indexIng = that.shortAnswerList.length - 1
                                        if (that.pageExamObj.subjectList.length > 0) {
                                            that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                            that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                            if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                that.testTextarea = that.smallQuestionObj.doneAnswer
                                            } else {
                                                that.testTextarea = ''
                                            }
                                        } else {
                                            that.smallQuestionObj = that.pageExamObj
                                            that.smallQuestionObj = that.pageExamObj.subjectId
                                            if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                that.testTextarea = that.smallQuestionObj.doneAnswer
                                            } else {
                                                that.testTextarea = ''
                                            }
                                        }
                                    } else {
                                        if (that.synthesisList.length > 0) {
                                            that.pageExamObj = that.synthesisList[that.synthesisList.length - 1]
                                            that.checkedExamId = that.synthesisList[that.synthesisList.length - 1].subjectId
                                            that.indexIng = that.synthesisList.length - 1
                                            if (that.pageExamObj.subjectList.length > 0) {
                                                that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                                that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                                } else {
                                                    that.testTextarea = ''
                                                }
                                            } else {
                                                that.smallQuestionObj = that.pageExamObj
                                                that.smallQuestionObj = that.pageExamObj.subjectId
                                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                                } else {
                                                    that.testTextarea = ''
                                                }
                                            }
                                        } else {
                                            if (that.jisuanfenxi.length > 0) {
                                                that.pageExamObj = that.jisuanfenxi[that.jisuanfenxi.length - 1]
                                                that.checkedExamId = that.jisuanfenxi[that.jisuanfenxi.length - 1].subjectId
                                                that.indexIng = that.jisuanfenxi.length - 1
                                                if (that.pageExamObj.subjectList.length > 0) {
                                                    that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                                    that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                                    } else {
                                                        that.testTextarea = ''
                                                    }
                                                } else {
                                                    that.smallQuestionObj = that.pageExamObj
                                                    that.smallQuestionObj = that.pageExamObj.subjectId
                                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                                    } else {
                                                        that.testTextarea = ''
                                                    }
                                                }
                                            } else {
                                                if (that.budingxiang.length > 0) {
                                                    that.pageExamObj = that.budingxiang[that.budingxiang.length - 1]
                                                    that.checkedExamId = that.budingxiang[that.budingxiang.length - 1].subjectId
                                                    that.indexIng = that.checkboxExamList.length - 1
                                                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                        var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                        var temp_checkList = [];
                                                        for (var i = 0; i < checkListArr.length; i++) {
                                                            temp_checkList.push(checkListArr[i]);
                                                            // that.checkList.push();
                                                        }
                                                        that.checkList = temp_checkList
                                                    } else {
                                                        that.checkList = []
                                                    }
                                                } else {
                                                    if (that.estimateList.length > 0) { // 如果上一类型有题
                                                        that.pageExamObj = that.estimateList[that.estimateList.length - 1]
                                                        that.checkedExamId = that.estimateList[that.estimateList.length - 1].subjectId
                                                        that.indexIng = that.estimateList.length - 1
                                                        if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                            that.radio = that.pageExamObj.doneAnswerIds
                                                        } else {
                                                            that.radio = ''
                                                        }
                                                    } else { // 上一类型没有题
                                                        if (that.checkboxExamList.length > 0) { // 再如果上一类型有题
                                                            that.pageExamObj = that.checkboxExamList[that.checkboxExamList.length - 1]
                                                            that.checkedExamId = that.checkboxExamList[that.checkboxExamList.length - 1].subjectId
                                                            that.indexIng = that.checkboxExamList.length - 1
                                                            if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                                var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                                var temp_checkList = [];
                                                                for (var i = 0; i < checkListArr.length; i++) {
                                                                    temp_checkList.push(checkListArr[i]);
                                                                    // that.checkList.push();
                                                                }
                                                                that.checkList = temp_checkList
                                                            } else {
                                                                that.checkList = []
                                                            }
                                                        } else { // 再如果上一类型没有题
                                                            if (that.radioExamList.length > 0) {
                                                                that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                                                                that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                                                                that.indexIng = that.radioExamList.length - 1
                                                                if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                                    that.radio = that.pageExamObj.doneAnswerIds
                                                                } else {
                                                                    that.radio = ''
                                                                }
                                                            } else {
                                                                that.$message.error('没有上一题了')
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            for (var k in that.jisuan) {
                                if (that.checkedExamId == that.jisuan[k].subjectId) {
                                    that.pageExamObj = that.jisuan[k - 1]
                                    that.checkedExamId = that.jisuan[k - 1].subjectId
                                    that.indexIng = k - 1
                                    if (that.pageExamObj.subjectList.length > 0) {
                                        that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                        that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    } else {
                                        that.smallQuestionObj = that.pageExamObj
                                        that.smallQuestionIndex = that.pageExamObj.subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    }
                                }
                            }
                        }


                    } else { // 不是第一小题 查看在哪一个小标
                        for (var j in that.pageExamObj.subjectList) {
                            if (that.smallQuestionIndex == that.pageExamObj.subjectList[j].subjectId) {
                                that.smallQuestionObj = that.pageExamObj.subjectList[j - 1]
                                that.smallQuestionIndex = that.pageExamObj.subjectList[j - 1].subjectId
                                if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                    var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                    var temp_checkList = [];
                                    for (var i = 0; i < checkListArr.length; i++) {
                                        temp_checkList.push(checkListArr[i]);
                                        // that.checkList.push();
                                    }
                                    that.checkList = temp_checkList
                                } else {
                                    that.checkList = []
                                }
                            }
                        }
                    }
                } else {
                    if (that.indexIng - 1 < 0) { // 如果是第一大题
                        if (that.anlifenxi.length > 0) {
                            that.pageExamObj = that.anlifenxi[that.anlifenxi.length - 1]
                            that.checkedExamId = that.anlifenxi[that.anlifenxi.length - 1].subjectId
                            that.indexIng = that.anlifenxi.length - 1
                            if (that.pageExamObj.subjectList.length > 0) {
                                that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                } else {
                                    that.testTextarea = ''
                                }
                            } else {
                                that.smallQuestionObj = that.pageExamObj
                                that.smallQuestionObj = that.pageExamObj.subjectId
                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                } else {
                                    that.testTextarea = ''
                                }
                            }
                        } else {
                    
                            if (that.jisuanhuida.length > 0) {
                           
                                that.pageExamObj = that.jisuanhuida[that.jisuanhuida.length - 1]
                                that.checkedExamId = that.jisuanhuida[that.jisuanhuida.length - 1].subjectId
                                that.indexIng = that.jisuanhuida.length - 1
                                if (that.pageExamObj.subjectList.length > 0) {
                                    that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                    that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                } else {
                                    that.smallQuestionObj = that.pageExamObj
                                    that.smallQuestionIndex = that.pageExamObj.subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                }
                            } else {
                                if (that.shortAnswerList.length > 0) {
                                    that.pageExamObj = that.shortAnswerList[that.shortAnswerList.length - 1]
                                    that.checkedExamId = that.shortAnswerList[that.shortAnswerList.length - 1].subjectId
                                    that.indexIng = that.shortAnswerList.length - 1
                                    if (that.pageExamObj.subjectList.length > 0) {
                                        that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                        that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    } else {
                                        that.smallQuestionObj = that.pageExamObj
                                        that.smallQuestionObj = that.pageExamObj.subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    }
                                } else {
                                    if (that.synthesisList.length > 0) {
                                        that.pageExamObj = that.synthesisList[that.synthesisList.length - 1]
                                        that.checkedExamId = that.synthesisList[that.synthesisList.length - 1].subjectId
                                        that.indexIng = that.synthesisList.length - 1
                                        if (that.pageExamObj.subjectList.length > 0) {
                                            that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                            that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                            if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                that.testTextarea = that.smallQuestionObj.doneAnswer
                                            } else {
                                                that.testTextarea = ''
                                            }
                                        } else {
                                            that.smallQuestionObj = that.pageExamObj
                                            that.smallQuestionObj = that.pageExamObj.subjectId
                                            if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                that.testTextarea = that.smallQuestionObj.doneAnswer
                                            } else {
                                                that.testTextarea = ''
                                            }
                                        }
                                    } else {
                                        if (that.jisuanfenxi.length > 0) {
                                            that.pageExamObj = that.jisuanfenxi[that.jisuanfenxi.length - 1]
                                            that.checkedExamId = that.jisuanfenxi[that.jisuanfenxi.length - 1].subjectId
                                            that.indexIng = that.jisuanfenxi.length - 1
                                            if (that.pageExamObj.subjectList.length > 0) {
                                                that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                                that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                                } else {
                                                    that.testTextarea = ''
                                                }
                                            } else {
                                                that.smallQuestionObj = that.pageExamObj
                                                that.smallQuestionObj = that.pageExamObj.subjectId
                                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                                } else {
                                                    that.testTextarea = ''
                                                }
                                            }
                                        } else {
                                            if (that.budingxiang.length > 0) {
                                                that.pageExamObj = that.budingxiang[that.budingxiang.length - 1]
                                                that.checkedExamId = that.budingxiang[that.budingxiang.length - 1].subjectId
                                                that.indexIng = that.checkboxExamList.length - 1
                                                if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                    var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                    var temp_checkList = [];
                                                    for (var i = 0; i < checkListArr.length; i++) {
                                                        temp_checkList.push(checkListArr[i]);
                                                        // that.checkList.push();
                                                    }
                                                    that.checkList = temp_checkList
                                                } else {
                                                    that.checkList = []
                                                }
                                            } else {
                                                if (that.estimateList.length > 0) { // 如果上一类型有题
                                                    that.pageExamObj = that.estimateList[that.estimateList.length - 1]
                                                    that.checkedExamId = that.estimateList[that.estimateList.length - 1].subjectId
                                                    that.indexIng = that.estimateList.length - 1
                                                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                        that.radio = that.pageExamObj.doneAnswerIds
                                                    } else {
                                                        that.radio = ''
                                                    }
                                                } else { // 上一类型没有题
                                                    if (that.checkboxExamList.length > 0) { // 再如果上一类型有题
                                                        that.pageExamObj = that.checkboxExamList[that.checkboxExamList.length - 1]
                                                        that.checkedExamId = that.checkboxExamList[that.checkboxExamList.length - 1].subjectId
                                                        that.indexIng = that.checkboxExamList.length - 1
                                                        if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                            var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                            var temp_checkList = [];
                                                            for (var i = 0; i < checkListArr.length; i++) {
                                                                temp_checkList.push(checkListArr[i]);
                                                                // that.checkList.push();
                                                            }
                                                            that.checkList = temp_checkList
                                                        } else {
                                                            that.checkList = []
                                                        }
                                                    } else { // 再如果上一类型没有题
                                                        if (that.radioExamList.length > 0) {
                                                            that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                                                            that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                                                            that.indexIng = that.radioExamList.length - 1
                                                            if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                                that.radio = that.pageExamObj.doneAnswerIds
                                                            } else {
                                                                that.radio = ''
                                                            }
                                                        } else {
                                                            that.$message.error('没有上一题了')
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }





                    } else {
                        that.pageExamObj = that.jisuan[that.indexIng - 1]
                        that.checkedExamId = that.jisuan[that.indexIng - 1].subjectId
                        that.indexIng = that.indexIng - 1
                        if (that.pageExamObj.subjectList.length > 0) {
                            that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                            that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                            if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                var temp_checkList = [];
                                for (var i = 0; i < checkListArr.length; i++) {
                                    temp_checkList.push(checkListArr[i]);
                                    // that.checkList.push();
                                }
                                that.checkList = temp_checkList
                            } else {
                                that.checkList = []
                            }
                        } else {
                            that.smallQuestionObj = that.pageExamObj
                            that.smallQuestionIndex = that.pageExamObjx.subjectId
                            if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                var temp_checkList = [];
                                for (var i = 0; i < checkListArr.length; i++) {
                                    temp_checkList.push(checkListArr[i]);
                                    // that.checkList.push();
                                }
                                that.checkList = temp_checkList
                            } else {
                                that.checkList = []
                            }
                        }
                    }
                }
            } else if (that.pageExamObj.classify == "11") {
                if (that.pageExamObj.subjectList.length > 0) { // 判断该答题有没有小题  有
                    if (that.smallQuestionIndex == that.pageExamObj.subjectList[0].subjectId) { // 如果是第一小题 切换到上一大题
                        if (that.checkedExamId == that.zonghefenxi[0].subjectId) {
                            if (that.jisuan.length > 0) {
                                that.pageExamObj = that.jisuan[that.jisuan.length - 1]
                                that.checkedExamId = that.jisuan[that.jisuan.length - 1].subjectId
                                that.indexIng = that.jisuan.length - 1
                                if (that.pageExamObj.subjectList.length > 0) {
                                    that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                    that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                } else {
                                    that.smallQuestionObj = that.pageExamObj
                                    that.smallQuestionObj = that.pageExamObj.subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                }
                            } else {
                                if (that.anlifenxi.length > 0) {
                                    that.pageExamObj = that.anlifenxi[that.anlifenxi.length - 1]
                                    that.checkedExamId = that.anlifenxi[that.anlifenxi.length - 1].subjectId
                                    that.indexIng = that.anlifenxi.length - 1
                                    if (that.pageExamObj.subjectList.length > 0) {
                                        that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                        that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    } else {
                                        that.smallQuestionObj = that.pageExamObj
                                        that.smallQuestionObj = that.pageExamObj.subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    }
                                } else {
                                    if (that.jisuanhuida.length > 0) {
                                        that.pageExamObj = that.jisuanhuida[that.jisuanhuida.length - 1]
                                        that.checkedExamId = that.jisuanhuida[that.jisuanhuida.length - 1].subjectId
                                        that.indexIng = that.jisuanhuida.length - 1
                                        if (that.pageExamObj.subjectList.length > 0) {
                                            that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                            that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                            if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                that.testTextarea = that.smallQuestionObj.doneAnswer
                                            } else {
                                                that.testTextarea = ''
                                            }
                                        } else {
                                            that.smallQuestionObj = that.pageExamObj
                                            that.smallQuestionObj = that.pageExamObj.subjectId
                                            if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                that.testTextarea = that.smallQuestionObj.doneAnswer
                                            } else {
                                                that.testTextarea = ''
                                            }
                                        }
                                    } else {
                                        if (that.shortAnswerList.length > 0) {
                                            that.pageExamObj = that.shortAnswerList[that.shortAnswerList.length - 1]
                                            that.checkedExamId = that.shortAnswerList[that.shortAnswerList.length - 1].subjectId
                                            that.indexIng = that.shortAnswerList.length - 1
                                            if (that.pageExamObj.subjectList.length > 0) {
                                                that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                                that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                                } else {
                                                    that.testTextarea = ''
                                                }
                                            } else {
                                                that.smallQuestionObj = that.pageExamObj
                                                that.smallQuestionObj = that.pageExamObj.subjectId
                                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                                } else {
                                                    that.testTextarea = ''
                                                }
                                            }
                                        } else {
                                            if (that.synthesisList.length > 0) {
                                                that.pageExamObj = that.synthesisList[that.synthesisList.length - 1]
                                                that.checkedExamId = that.synthesisList[that.synthesisList.length - 1].subjectId
                                                that.indexIng = that.synthesisList.length - 1
                                                if (that.pageExamObj.subjectList.length > 0) {
                                                    that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                                    that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                                    } else {
                                                        that.testTextarea = ''
                                                    }
                                                } else {
                                                    that.smallQuestionObj = that.pageExamObj
                                                    that.smallQuestionObj = that.pageExamObj.subjectId
                                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                                    } else {
                                                        that.testTextarea = ''
                                                    }
                                                }
                                            } else {
                                                if (that.jisuanfenxi.length > 0) {
                                                    that.pageExamObj = that.jisuanfenxi[that.jisuanfenxi.length - 1]
                                                    that.checkedExamId = that.jisuanfenxi[that.jisuanfenxi.length - 1].subjectId
                                                    that.indexIng = that.jisuanfenxi.length - 1
                                                    if (that.pageExamObj.subjectList.length > 0) {
                                                        that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                                        that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                                        } else {
                                                            that.testTextarea = ''
                                                        }
                                                    } else {
                                                        that.smallQuestionObj = that.pageExamObj
                                                        that.smallQuestionObj = that.pageExamObj.subjectId
                                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                                        } else {
                                                            that.testTextarea = ''
                                                        }
                                                    }
                                                } else {
                                                    if (that.budingxiang.length > 0) {
                                                        that.pageExamObj = that.budingxiang[that.budingxiang.length - 1]
                                                        that.checkedExamId = that.budingxiang[that.budingxiang.length - 1].subjectId
                                                        that.indexIng = that.checkboxExamList.length - 1
                                                        if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                            var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                            var temp_checkList = [];
                                                            for (var i = 0; i < checkListArr.length; i++) {
                                                                temp_checkList.push(checkListArr[i]);
                                                                // that.checkList.push();
                                                            }
                                                            that.checkList = temp_checkList
                                                        } else {
                                                            that.checkList = []
                                                        }
                                                    } else {
                                                        if (that.estimateList.length > 0) { // 如果上一类型有题
                                                            that.pageExamObj = that.estimateList[that.estimateList.length - 1]
                                                            that.checkedExamId = that.estimateList[that.estimateList.length - 1].subjectId
                                                            that.indexIng = that.estimateList.length - 1
                                                            if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                                that.radio = that.pageExamObj.doneAnswerIds
                                                            } else {
                                                                that.radio = ''
                                                            }
                                                        } else { // 上一类型没有题
                                                            if (that.checkboxExamList.length > 0) { // 再如果上一类型有题
                                                                that.pageExamObj = that.checkboxExamList[that.checkboxExamList.length - 1]
                                                                that.checkedExamId = that.checkboxExamList[that.checkboxExamList.length - 1].subjectId
                                                                that.indexIng = that.checkboxExamList.length - 1
                                                                if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                                    var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                                    var temp_checkList = [];
                                                                    for (var i = 0; i < checkListArr.length; i++) {
                                                                        temp_checkList.push(checkListArr[i]);
                                                                        // that.checkList.push();
                                                                    }
                                                                    that.checkList = temp_checkList
                                                                } else {
                                                                    that.checkList = []
                                                                }
                                                            } else { // 再如果上一类型没有题
                                                                if (that.radioExamList.length > 0) {
                                                                    that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                                                                    that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                                                                    that.indexIng = that.radioExamList.length - 1
                                                                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                                        that.radio = that.pageExamObj.doneAnswerIds
                                                                    } else {
                                                                        that.radio = ''
                                                                    }
                                                                } else {
                                                                    that.$message.error('没有上一题了')
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            for (var k in that.zonghefenxi) {
                                if (that.checkedExamId == that.zonghefenxi[k].subjectId) {
                                    that.pageExamObj = that.zonghefenxi[k - 1]
                                    that.checkedExamId = that.zonghefenxi[k - 1].subjectId
                                    that.indexIng = k - 1
                                    if (that.pageExamObj.subjectList.length > 0) {
                                        that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                        that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    } else {
                                        that.smallQuestionObj = that.pageExamObj
                                        that.smallQuestionIndex = that.pageExamObj.subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    }
                                }
                            }
                        }



                    } else { // 不是第一小题 查看在哪一个小标
                        for (var j in that.pageExamObj.subjectList) {
                            if (that.smallQuestionIndex == that.pageExamObj.subjectList[j].subjectId) {
                                that.smallQuestionObj = that.pageExamObj.subjectList[j - 1]
                                that.smallQuestionIndex = that.pageExamObj.subjectList[j - 1].subjectId
                                if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                    var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                    var temp_checkList = [];
                                    for (var i = 0; i < checkListArr.length; i++) {
                                        temp_checkList.push(checkListArr[i]);
                                        // that.checkList.push();
                                    }
                                    that.checkList = temp_checkList
                                } else {
                                    that.checkList = []
                                }
                            }
                        }
                    }
                } else {
                    if (that.indexIng - 1 < 0) { // 如果是第一大题
                        if (that.jisuan.length > 0) {
                            that.pageExamObj = that.jisuan[that.jisuan.length - 1]
                            that.checkedExamId = that.jisuan[that.jisuan.length - 1].subjectId
                            that.indexIng = that.jisuan.length - 1
                            if (that.pageExamObj.subjectList.length > 0) {
                                that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                } else {
                                    that.testTextarea = ''
                                }
                            } else {
                                that.smallQuestionObj = that.pageExamObj
                                that.smallQuestionObj = that.pageExamObj.subjectId
                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                } else {
                                    that.testTextarea = ''
                                }
                            }
                        } else {
                            if (that.anlifenxi.length > 0) {
                                that.pageExamObj = that.anlifenxi[that.anlifenxi.length - 1]
                                that.checkedExamId = that.anlifenxi[that.anlifenxi.length - 1].subjectId
                                that.indexIng = that.anlifenxi.length - 1
                                if (that.pageExamObj.subjectList.length > 0) {
                                    that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                    that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                } else {
                                    that.smallQuestionObj = that.pageExamObj
                                    that.smallQuestionObj = that.pageExamObj.subjectId
                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                    } else {
                                        that.testTextarea = ''
                                    }
                                }
                            } else {
                                if (that.jisuanhuida.length > 0) {
                                    that.pageExamObj = that.jisuanhuida[that.jisuanhuida.length - 1]
                                    that.checkedExamId = that.jisuanhuida[that.jisuanhuida.length - 1].subjectId
                                    that.indexIng = that.jisuanhuida.length - 1
                                    if (that.pageExamObj.subjectList.length > 0) {
                                        that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                        that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    } else {
                                        that.smallQuestionObj = that.pageExamObj
                                        that.smallQuestionObj = that.pageExamObj.subjectId
                                        if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                            that.testTextarea = that.smallQuestionObj.doneAnswer
                                        } else {
                                            that.testTextarea = ''
                                        }
                                    }
                                } else {
                                    if (that.shortAnswerList.length > 0) {
                                        that.pageExamObj = that.shortAnswerList[that.shortAnswerList.length - 1]
                                        that.checkedExamId = that.shortAnswerList[that.shortAnswerList.length - 1].subjectId
                                        that.indexIng = that.shortAnswerList.length - 1
                                        if (that.pageExamObj.subjectList.length > 0) {
                                            that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                            that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                            if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                that.testTextarea = that.smallQuestionObj.doneAnswer
                                            } else {
                                                that.testTextarea = ''
                                            }
                                        } else {
                                            that.smallQuestionObj = that.pageExamObj
                                            that.smallQuestionObj = that.pageExamObj.subjectId
                                            if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                that.testTextarea = that.smallQuestionObj.doneAnswer
                                            } else {
                                                that.testTextarea = ''
                                            }
                                        }
                                    } else {
                                        if (that.synthesisList.length > 0) {
                                            that.pageExamObj = that.synthesisList[that.synthesisList.length - 1]
                                            that.checkedExamId = that.synthesisList[that.synthesisList.length - 1].subjectId
                                            that.indexIng = that.synthesisList.length - 1
                                            if (that.pageExamObj.subjectList.length > 0) {
                                                that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                                that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                                } else {
                                                    that.testTextarea = ''
                                                }
                                            } else {
                                                that.smallQuestionObj = that.pageExamObj
                                                that.smallQuestionObj = that.pageExamObj.subjectId
                                                if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                    that.testTextarea = that.smallQuestionObj.doneAnswer
                                                } else {
                                                    that.testTextarea = ''
                                                }
                                            }
                                        } else {
                                            if (that.jisuanfenxi.length > 0) {
                                                that.pageExamObj = that.jisuanfenxi[that.jisuanfenxi.length - 1]
                                                that.checkedExamId = that.jisuanfenxi[that.jisuanfenxi.length - 1].subjectId
                                                that.indexIng = that.jisuanfenxi.length - 1
                                                if (that.pageExamObj.subjectList.length > 0) {
                                                    that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                                                    that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                                    } else {
                                                        that.testTextarea = ''
                                                    }
                                                } else {
                                                    that.smallQuestionObj = that.pageExamObj
                                                    that.smallQuestionObj = that.pageExamObj.subjectId
                                                    if (that.smallQuestionObj.doneAnswer != '' && that.smallQuestionObj.doneAnswer != null && that.smallQuestionObj.doneAnswer != undefined) {
                                                        that.testTextarea = that.smallQuestionObj.doneAnswer
                                                    } else {
                                                        that.testTextarea = ''
                                                    }
                                                }
                                            } else {
                                                if (that.budingxiang.length > 0) {
                                                    that.pageExamObj = that.budingxiang[that.budingxiang.length - 1]
                                                    that.checkedExamId = that.budingxiang[that.budingxiang.length - 1].subjectId
                                                    that.indexIng = that.checkboxExamList.length - 1
                                                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                        var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                        var temp_checkList = [];
                                                        for (var i = 0; i < checkListArr.length; i++) {
                                                            temp_checkList.push(checkListArr[i]);
                                                            // that.checkList.push();
                                                        }
                                                        that.checkList = temp_checkList
                                                    } else {
                                                        that.checkList = []
                                                    }
                                                } else {
                                                    if (that.estimateList.length > 0) { // 如果上一类型有题
                                                        that.pageExamObj = that.estimateList[that.estimateList.length - 1]
                                                        that.checkedExamId = that.estimateList[that.estimateList.length - 1].subjectId
                                                        that.indexIng = that.estimateList.length - 1
                                                        if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                            that.radio = that.pageExamObj.doneAnswerIds
                                                        } else {
                                                            that.radio = ''
                                                        }
                                                    } else { // 上一类型没有题
                                                        if (that.checkboxExamList.length > 0) { // 再如果上一类型有题
                                                            that.pageExamObj = that.checkboxExamList[that.checkboxExamList.length - 1]
                                                            that.checkedExamId = that.checkboxExamList[that.checkboxExamList.length - 1].subjectId
                                                            that.indexIng = that.checkboxExamList.length - 1
                                                            if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                                var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                                                                var temp_checkList = [];
                                                                for (var i = 0; i < checkListArr.length; i++) {
                                                                    temp_checkList.push(checkListArr[i]);
                                                                    // that.checkList.push();
                                                                }
                                                                that.checkList = temp_checkList
                                                            } else {
                                                                that.checkList = []
                                                            }
                                                        } else { // 再如果上一类型没有题
                                                            if (that.radioExamList.length > 0) {
                                                                that.pageExamObj = that.radioExamList[that.radioExamList.length - 1]
                                                                that.checkedExamId = that.radioExamList[that.radioExamList.length - 1].subjectId
                                                                that.indexIng = that.radioExamList.length - 1
                                                                if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                                                                    that.radio = that.pageExamObj.doneAnswerIds
                                                                } else {
                                                                    that.radio = ''
                                                                }
                                                            } else {
                                                                that.$message.error('没有上一题了')
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }






                    } else {
                        that.pageExamObj = that.zonghefenxi[that.indexIng - 1]
                        that.checkedExamId = that.zonghefenxi[that.indexIng - 1].subjectId
                        that.indexIng = that.indexIng - 1
                        if (that.pageExamObj.subjectList.length > 0) {
                            that.smallQuestionObj = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1]
                            that.smallQuestionIndex = that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId
                            if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                var temp_checkList = [];
                                for (var i = 0; i < checkListArr.length; i++) {
                                    temp_checkList.push(checkListArr[i]);
                                    // that.checkList.push();
                                }
                                that.checkList = temp_checkList
                            } else {
                                that.checkList = []
                            }
                        } else {
                            that.smallQuestionObj = that.pageExamObj
                            that.smallQuestionIndex = that.pageExamObjx.subjectId
                            if (that.smallQuestionObj.doneAnswerIds != '' && that.smallQuestionObj.doneAnswerIds != null && that.smallQuestionObj.doneAnswerIds != undefined) {
                                var checkListArr = that.smallQuestionObj.doneAnswerIds.split(",");
                                var temp_checkList = [];
                                for (var i = 0; i < checkListArr.length; i++) {
                                    temp_checkList.push(checkListArr[i]);
                                    // that.checkList.push();
                                }
                                that.checkList = temp_checkList
                            } else {
                                that.checkList = []
                            }
                        }
                    }
                }
            }
            that.$nextTick(()=>{
                that.update_size();
            })
        },
        save_subject_Tab(val) {
            
            var that = this;
            
            if (that.tabIndex == '1' || that.tabIndex == '2') {
                that.isShowAnalysis = false
                var answerText = '';
                var subjectId = 0;
                // var checkedList=that.checkList
                // that.checkList=[]
                if (that.pageExamObj.classify == '2') {
                    var answerId = that.checkList.join(',')
                    subjectId = that.pageExamObj.subjectId
                    if (answerId == '') {
                        // that.$message.error('请选择您的答案');
                        // return;
                    }
                } else if (that.pageExamObj.classify == '1' || that.pageExamObj.classify == '3') {
                    subjectId = that.pageExamObj.subjectId
                    if (that.radio == '' || that.radio == null || that.radio == undefined) {
                        // that.$message.error('您未作答，请先作答')
                        // return false
                    } else {
                        var answerId = that.radio
                        // that.radio = ''
                    }
                } else if (that.pageExamObj.classify == '4') {
                    that.checkList = this.getInfoArr(this.getAnswerIds(this.smallQuestionObj.answerList),that.checkList);
                    var answerId = that.checkList.join(',')
                    subjectId = that.smallQuestionIndex
                    // subjectId=that.pageExamObj.subjectId
                    that.checkList = []
                    if (answerId == '') {
                        // that.$message.error('请选择您的答案');
                        // return;
                    }
                } else if (that.pageExamObj.classify == '5' || that.pageExamObj.classify == '6' || that.pageExamObj.classify == '7' || that.pageExamObj.classify == '8' || that.pageExamObj.classify == '9' || that.pageExamObj.classify == '10') {
                    if (that.testTextarea == '' || that.testTextarea == null || that.testTextarea == undefined) {
                        // that.$message.error('请先填写您的答案')
                        // return
                    } else {
                        if ((that.score == '' || that.score == null || that.score == undefined) && [3, 4, 5].includes(this.tabIndex)) {
                            // that.$message.error('请先填写您的自评分')
                            // return;
                        }
                        var answerId = ''
                        subjectId = that.smallQuestionIndex
                        answerText = that.testTextarea
                        that.testTextarea = '';
                    }
                }
                var newTime = Number(that.second_time - that.recordTime)
                var obj = {
                    answerTimeLength: newTime,
                    nodeType: that.tabIndex,
                    subjectId: subjectId,
                    answerId: answerId,
                    answerText: answerText,
                }
                
                if (that.pageExamObj.classify >= 5 && [3, 4, 5].includes(this.tabIndex)) {
                    obj.score = that.score;
                }

                let pageExamObj = this.pageExamObj;
                if ((pageExamObj.classify <= 4 && !obj.answerId) || (pageExamObj.classify > 4 && !obj.answerText)) {
                    //this.nextAnswer(answerId, answerText);
                    return false;
                }


                save_subject(obj).then(res => {
                    
                    if (res.data.code == 0) {
                        let paperChapterSubjectList = this.paperChapterSubjectList;
                        if (paperChapterSubjectList[paperChapterSubjectList.length - 1].subjectId == this.pageExamObj.subjectId) {
                            return false;
                        } else if ((this.pageExamObj.classify == '1' || this.pageExamObj.classify == '3') && this.radio) {
                            this.radio = '';
                        }
                        that.isShowAnalysis = false;
                        
                        that.checkList = answerId.split(",");
                        this.nextAnswer_new(answerId, answerText);
                        this.judgeEndSubject();

                    } else {
                        this.$message.error(res.doneAnswerIds.msg)
                    }
                }).catch(error => {
                    console.log(error)
                })
            } else if (that.tabIndex == '3' || that.tabIndex == '4' || that.tabIndex == '5' || (that.tabIndex == '6' && that.twoIindex == '1') || (that.tabIndex == '8' && that.twoIindex == '1')) {
                that.isShowAnalysis = false
                var score = ''
                var answerText = ''
                var subjectId = 0

                if (that.pageExamObj.classify == '2') {
                    var answerId = that.checkList.join(',')
                    subjectId = that.pageExamObj.subjectId
                    if (answerId == '') {
                        that.$message.error('请选择您的答案');
                        return;
                    }
                } else if (that.pageExamObj.classify == '1' || that.pageExamObj.classify == '3') {
        
                    subjectId = that.pageExamObj.subjectId
                    if (that.radio == '' || that.radio == null || that.radio == undefined) {
                        that.$message.error('您未作答，请先作答')
                        return false
                    } else {
                        var answerId = that.radio
                        // that.radio = ''
                    }
                } else if (that.pageExamObj.classify == '4') {
                    console.log("1111");
                    that.checkList = this.getInfoArr(this.getAnswerIds(this.smallQuestionObj.answerList),that.checkList);
                    var answerId = that.checkList.join(',')
                    subjectId = that.smallQuestionIndex
                    // subjectId=that.pageExamObj.subjectId
                    that.checkList = []
                    if (answerId == '') {
                        that.$message.error('请选择您的答案');
                        return;
                    }
                } else if (that.pageExamObj.classify == '5' || that.pageExamObj.classify == '6' || that.pageExamObj.classify == '7' || that.pageExamObj.classify == '8' || that.pageExamObj.classify == '9' || that.pageExamObj.classify == '10') {
                    if (that.testTextarea == '' || that.testTextarea == null || that.testTextarea == undefined) {
                        that.$message.error('请先填写您的答案')
                        return
                    } else {
                        if (that.score == 0 || that.score == '' || that.score == null || that.score == undefined) {
                            that.$message.error('请填写自评分')
                            return
                        } else {
                            var answerId = ''
                            subjectId = that.smallQuestionIndex
                            answerText = that.testTextarea
                            score = that.score
                            that.testTextarea = ''
                            that.score = 0
                        }

                    }
                }
                var newTime = Number(that.second_time - that.recordTime)
                var obj = {
                    answerTimeLength: newTime,
                    subjectId: subjectId,
                    testPaperId: that.examInfo.testPaperId,
                    answerId: answerId,
                    answerText: answerText,
                    score: score,
                }
                console.log("22222");
                savePaper_subject(obj).then(res => {
                    if (res.data.code == 0) {
                        // let paperChapterSubjectList = this.paperChapterSubjectList;
                        let paperChapterSubjectList = this.paperSubjectList;
                        if (paperChapterSubjectList[paperChapterSubjectList.length - 1].subjectId == this.pageExamObj.subjectId) {
                            console.log("33333");
                            //return false;
                        } else if ((this.pageExamObj.classify == '1' || this.pageExamObj.classify == '3') && this.radio) {
                            this.radio = '';
                        }
                        that.score = null;
                        var arr = {};
                        
                        that.checkList = answerId.split(",");
                        console.log('that.checkList',that.checkList);
                        this.nextAnswer_new(answerId, answerText);
                    }
                }).catch(error => {
                    console.log(error)
                })
            }

        },
        // 保存 下一题
        save_subject() {

            var that = this;
            if (that.tabIndex == '1' || that.tabIndex == '2') {
                that.isShowAnalysis = false
                var answerText = '';
                var subjectId = 0;
                // var checkedList=that.checkList
                // that.checkList=[]
                if (that.pageExamObj.classify == '2') {
                    that.checkList = this.getInfoArr(this.getAnswerIds(this.pageExamObj.answerList),that.checkList);
                    var answerId = that.checkList.join(',')
                    subjectId = that.pageExamObj.subjectId
                    if (answerId == '') {
                        // that.$message.error('请选择您的答案');
                        // return;
                    }
                } else if (that.pageExamObj.classify == '1' || that.pageExamObj.classify == '3') {
                    subjectId = that.pageExamObj.subjectId
                    if (that.radio == '' || that.radio == null || that.radio == undefined) {
                        // that.$message.error('您未作答，请先作答')
                        // return false
                    } else {
                        var answerId = that.radio
                        // that.radio = ''
                    }
                } else if (that.pageExamObj.classify == '4') {
                    var answerId = that.checkList.join(',')
                    subjectId = that.smallQuestionIndex
                    // subjectId=that.pageExamObj.subjectId
                    //that.checkList = []
                    if (answerId == '') {
                        // that.$message.error('请选择您的答案');
                        // return;
                    }
                } else if (that.pageExamObj.classify == '5' || that.pageExamObj.classify == '6' || that.pageExamObj.classify == '7' || that.pageExamObj.classify == '8' || that.pageExamObj.classify == '9' || that.pageExamObj.classify == '10') {
                    if (that.testTextarea == '' || that.testTextarea == null || that.testTextarea == undefined) {
                        // that.$message.error('请先填写您的答案')
                        // return
                    } else {
                        if ((that.score == '' || that.score == null || that.score == undefined) && [3, 4, 5].includes(this.tabIndex)) {
                            // that.$message.error('请先填写您的自评分')
                            // return;
                        }
                        var answerId = ''
                        subjectId = that.smallQuestionIndex
                        answerText = that.testTextarea
                        that.testTextarea = '';
                    }
                }
                var newTime = Number(that.second_time - that.recordTime)
                var obj = {
                    answerTimeLength: newTime,
                    nodeType: that.tabIndex,
                    subjectId: subjectId,
                    answerId: answerId,
                    answerText: answerText,
                }
                if (that.pageExamObj.classify >= 5 && [3, 4, 5].includes(this.tabIndex)) {
                    obj.score = that.score;
                }

                let pageExamObj = this.pageExamObj;
                if ((pageExamObj.classify <= 4 && !obj.answerId) || (pageExamObj.classify > 4 && !obj.answerText)) {
                    this.nextAnswer(answerId, answerText);
                    return false;
                }


                save_subject(obj).then(res => {
                    if (res.data.code == 0) {
                        let paperChapterSubjectList = this.paperChapterSubjectList;
                        if (paperChapterSubjectList[paperChapterSubjectList.length - 1].subjectId == this.pageExamObj.subjectId) {
                            return false;
                        } else if ((this.pageExamObj.classify == '1' || this.pageExamObj.classify == '3') && this.radio) {
                            this.radio = '';
                        }
                        that.isShowAnalysis = false;
                        this.nextAnswer(answerId, answerText);
                        this.judgeEndSubject();

                    } else {
                        this.$message.error(res.doneAnswerIds.msg)
                    }
                }).catch(error => {
                    console.log(error)
                })
            } else if (that.tabIndex == '3' || that.tabIndex == '4' || that.tabIndex == '5' || (that.tabIndex == '6' && that.twoIindex == '1') || (that.tabIndex == '8' && that.twoIindex == '1')) {
                that.isShowAnalysis = false
                var score = ''
                var answerText = ''
                var subjectId = 0

                if (that.pageExamObj.classify == '2') {
                    that.checkList = this.getInfoArr(this.getAnswerIds(this.pageExamObj.answerList),that.checkList);
                    var answerId = that.checkList.join(',')
                    subjectId = that.pageExamObj.subjectId
                    if (answerId == '') {
                        that.$message.error('请选择您的答案');
                        return;
                    }
                } else if (that.pageExamObj.classify == '1' || that.pageExamObj.classify == '3') {
                    subjectId = that.pageExamObj.subjectId
                    if (that.radio == '' || that.radio == null || that.radio == undefined) {
                        that.$message.error('您未作答，请先作答')
                        return false
                    } else {
                        var answerId = that.radio
                        // that.radio = ''
                    }
                } else if (that.pageExamObj.classify == '4') {
                   
                    var answerId = that.checkList.join(',')
                    subjectId = that.smallQuestionIndex
                    // subjectId=that.pageExamObj.subjectId
                    //that.checkList = []
                    if (answerId == '') {
                        that.$message.error('请选择您的答案');
                        return;
                    }
                } else if (that.pageExamObj.classify == '5' || that.pageExamObj.classify == '6' || that.pageExamObj.classify == '7' || that.pageExamObj.classify == '8' || that.pageExamObj.classify == '9' || that.pageExamObj.classify == '10') {
                    if (that.testTextarea == '' || that.testTextarea == null || that.testTextarea == undefined) {
                        that.$message.error('请先填写您的答案')
                        return
                    } else {
                        if (that.score == 0 || that.score == '' || that.score == null || that.score == undefined) {
                            that.$message.error('请填写自评分')
                            return
                        } else {
                            var answerId = ''
                            subjectId = that.smallQuestionIndex
                            answerText = that.testTextarea
                            score = that.score
                            that.testTextarea = ''
                            that.score = 0
                        }

                    }
                }
                var newTime = Number(that.second_time - that.recordTime)
                var obj = {
                    answerTimeLength: newTime,
                    subjectId: subjectId,
                    testPaperId: that.examInfo.testPaperId,
                    answerId: answerId,
                    answerText: answerText,
                    score: score,
                }
                if(that.pageExamObj.classify != '4'){
                    savePaper_subject(obj).then(res => {
                        if (res.data.code == 0) {
                            // let paperChapterSubjectList = this.paperChapterSubjectList;
                            let paperChapterSubjectList = this.paperSubjectList;
                            if (paperChapterSubjectList[paperChapterSubjectList.length - 1].subjectId == this.pageExamObj.subjectId) {
                                return false;
                            } else if ((this.pageExamObj.classify == '1' || this.pageExamObj.classify == '3') && this.radio) {
                                this.radio = '';
                            }
                            that.score = null;
                            var arr = {};
                            
                            this.nextAnswer(answerId, answerText);
                        }
                    }).catch(error => {
                        console.log(error)
                    })

                }else{
                    this.nextAnswer(answerId, answerText);
                }
                
            }
            

        },
        nextAnswer_new(answerId, answerText) {
            let that = this;
            // that.budingxiang[that.indexIng].doneAnswerIds=answerId
                // debugger
                if (that.pageExamObj.subjectList.length > 0) { // 如果有小题
                    that.budingxiang[that.indexIng].doneAnswerIds = answerId
                  
                    // 判断当前是否为最后一小题
                    if (that.smallQuestionIndex == that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId) { // 是 赋值
                        that.budingxiang[that.indexIng].subjectList[that.pageExamObj.subjectList.length - 1].doneAnswerIds = answerId
                        // 判断是否为这个类型的最后一题
                        // if (that.indexIng + 1 >= that.budingxiang.length) { // 是
                        //     that.pageExamObj = that.budingxiang[that.indexIng + 1] // 获取小一题内容
                        //     that.checkedExamId = that.budingxiang[that.indexIng].subjectId // 左侧的当选状态
                        // } else { // 不是
                        //     // 下一题
                        //     that.pageExamObj = that.budingxiang[that.indexIng + 1] // 获取小一题内容
                        //     that.checkedExamId = that.budingxiang[that.indexIng].subjectId // 左侧的当选状态
                           
                        // }
                    } else { // 不是最后一小题  循环小题列表
                        for (var i = 0; i < that.pageExamObj.subjectList.length; i++) {
                          
                            // 判断当前小题是哪个小题
                            
                            if (that.smallQuestionIndex == that.pageExamObj.subjectList[i].subjectId) {
                                // 是的话赋值
                              
                                that.budingxiang[that.indexIng].subjectList[i].doneAnswerIds = answerId
                               // that.smallQuestionIndex = that.budingxiang[that.indexIng].subjectList[Number(i) + 1].subjectId
                               // that.smallQuestionObj = that.budingxiang[that.indexIng].subjectList[Number(i) + 1]
                                return
                            }
                        }
                    }
                } else {
                    that.budingxiang[that.indexIng].doneAnswerIds = answerId
                    if (that.indexIng + 1 >= that.budingxiang.length) { // 是
                        
                        that.indexIng = 0
                        if (that.jisuanfenxi.length > 0) { //5
                            
                            that.pageExamObj = that.jisuanfenxi[0]
                            that.checkedExamId = that.jisuanfenxi[0].subjectId
                            
                        } else {
                            if (that.synthesisList.length > 0) { //6
                              
                                // debugger;
                                that.pageExamObj = that.synthesisList[0]
                                that.checkedExamId = that.synthesisList[0].subjectId
                                if (that.synthesisList[0].subjectList.length > 0) {
                                  //  that.smallQuestionObj = that.synthesisList[0].subjectList[0]
                                  //  that.smallQuestionIndex = that.synthesisList[0].subjectList[0].subjectId
                                } else {
                                 //   that.smallQuestionObj = that.synthesisList[0]
                                //    that.smallQuestionIndex = that.synthesisList[0].subjectId
                                }

                            } else {
                                if (that.shortAnswerList.length > 0) { //7
                                    that.pageExamObj = that.shortAnswerList[0]
                                    that.checkedExamId = that.shortAnswerList[0].subjectId
                                   
                                } else {
                                    if (that.jisuanhuida.length > 0) { //8
                                        that.pageExamObj = that.jisuanhuida[0]
                                        that.checkedExamId = that.jisuanhuida[0].subjectId
                                       
                                    } else {
                                        if (that.anlifenxi.length > 0) { //9
                                            that.pageExamObj = that.anlifenxi[0]
                                            that.checkedExamId = that.anlifenxi[0].subjectId
                                            
                                        } else {
                                            if (that.jisuan.length > 0) { //10
                                                that.pageExamObj = that.jisuan[0]
                                                that.checkedExamId = that.jisuan[0].subjectId
                                                
                                            } else {
                                                if (that.zonghefenxi.length > 0) { //11
                                                    that.pageExamObj = that.zonghefenxi[0]
                                                    that.checkedExamId = that.zonghefenxi[0].subjectId
                                                   
                                                } else {
                                                    that.$confirm('您已经做到最后一题了是否提交呢', '提示', {
                                                        confirmButtonText: '确定',
                                                        cancelButtonText: '取消',
                                                        type: 'warning'
                                                    }).then(() => {
                                                        that.submit()
                                                    }).catch(() => {

                                                    });
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else { // 不是
                        // 下一题
                        that.pageExamObj = that.budingxiang[that.indexIng + 1] // 获取小一题内容
                        that.checkedExamId = that.budingxiang[that.indexIng + 1].subjectId // 左侧的当选状态
                        if (that.budingxiang[that.indexIng + 1].subjectList.length > 0) { // 判断下一题是否有小题
                           // that.smallQuestionIndex = that.budingxiang[that.indexIng + 1].subjectList[0].subjectId // 有的话赋值列表的第一个
                           // that.smallQuestionObj = that.budingxiang[that.indexIng + 1].subjectList[0]
                        } else {
                            // 没有的话，赋值父的
                           // that.smallQuestionIndex = that.budingxiang[that.indexIng + 1].subjectId
                           // that.smallQuestionObj = that.budingxiang[that.indexIng + 1]
                        }
                        //that.indexIng = that.indexIng + 1
                    }
                }
        },
        nextAnswer(answerId, answerText) {
            let that = this;
            if (that.pageExamObj.classify == "1") {
                that.radioExamList[that.indexIng].doneAnswerIds = answerId;
                if (Number(that.indexIng) + 1 >= that.radioExamList.length) {
                    that.indexIng = 0;
                    if (that.checkboxExamList.length > 0) { //2
                        that.pageExamObj = that.checkboxExamList[0]
                        that.checkedExamId = that.checkboxExamList[0].subjectId;
                    } else {
                        if (that.estimateList.length > 0) { //3
                            that.pageExamObj = that.estimateList[0]
                            that.checkedExamId = that.estimateList[0].subjectId;
                        } else {
                            if (that.budingxiang.length > 0) { //4
                                that.pageExamObj = that.budingxiang[0]
                                that.checkedExamId = that.budingxiang[0].subjectId
                            } else {
                                if (that.jisuanfenxi.length > 0) { //5
                                    that.pageExamObj = that.jisuanfenxi[0]
                                    that.checkedExamId = that.jisuanfenxi[0].subjectId
                                } else {
                                    if (that.synthesisList.length > 0) { //6
                                        that.pageExamObj = that.synthesisList[0]
                                        that.checkedExamId = that.synthesisList[0].subjectId
                                    } else {
                                        if (that.shortAnswerList.length > 0) { //7
                                            that.pageExamObj = that.shortAnswerList[0]
                                            that.checkedExamId = that.shortAnswerList[0].subjectId
                                        } else {
                                            if (that.jisuanhuida.length > 0) { //8
                                                that.pageExamObj = that.jisuanhuida[0]
                                                that.checkedExamId = that.jisuanhuida[0].subjectId
                                            } else {
                                                if (that.anlifenxi.length > 0) { //9
                                                    that.pageExamObj = that.anlifenxi[0]
                                                    that.checkedExamId = that.anlifenxi[0].subjectId
                                                } else {
                                                    if (that.jisuan.length > 0) { //10
                                                        that.pageExamObj = that.jisuan[0]
                                                        that.checkedExamId = that.jisuan[0].subjectId
                                                    } else {
                                                        if (that.zonghefenxi.length > 0) { //11
                                                            that.pageExamObj = that.zonghefenxi[0]
                                                            that.checkedExamId = that.zonghefenxi[0].subjectId
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            // 未完待续
                        }
                    }
                } else {
                    that.pageExamObj = that.radioExamList[that.indexIng + 1]
                    that.checkedExamId = that.radioExamList[that.indexIng + 1].subjectId
                    that.indexIng = that.indexIng + 1
                }
            } else if (that.pageExamObj.classify == "2") {
                that.checkboxExamList[that.indexIng].doneAnswerIds = answerId
                console.log('indexIng', that.indexIng)
                if (Number(that.indexIng) + 1 >= Number(that.checkboxExamList.length)) {
                    that.indexIng = 0;
                    if (that.estimateList.length > 0) { //3
                        that.pageExamObj = that.estimateList[0]
                        that.checkedExamId = that.estimateList[0].subjectId
                    } else {
                        if (that.budingxiang.length > 0) { //4
                            that.pageExamObj = that.budingxiang[0]
                            that.checkedExamId = that.budingxiang[0].subjectId
                        } else {
                            if (that.jisuanfenxi.length > 0) { //5
                                that.pageExamObj = that.jisuanfenxi[0]
                                that.checkedExamId = that.jisuanfenxi[0].subjectId
                            } else {
                                if (that.synthesisList.length > 0) { //6
                                    that.pageExamObj = that.synthesisList[0]
                                    that.checkedExamId = that.synthesisList[0].subjectId
                                } else {
                                    if (that.shortAnswerList.length > 0) { //7
                                        that.pageExamObj = that.shortAnswerList[0]
                                        that.checkedExamId = that.shortAnswerList[0].subjectId
                                    } else {
                                        if (that.jisuanhuida.length > 0) { //8
                                            that.pageExamObj = that.jisuanhuida[0]
                                            that.checkedExamId = that.jisuanhuida[0].subjectId
                                        } else {
                                            if (that.anlifenxi.length > 0) { //9
                                                that.pageExamObj = that.anlifenxi[0]
                                                that.checkedExamId = that.anlifenxi[0].subjectId
                                            } else {
                                                if (that.jisuan.length > 0) { //10
                                                    that.pageExamObj = that.jisuan[0]
                                                    that.checkedExamId = that.jisuan[0].subjectId
                                                } else {
                                                    if (that.zonghefenxi.length > 0) { //11
                                                        that.pageExamObj = that.zonghefenxi[0]
                                                        that.checkedExamId = that.zonghefenxi[0].subjectId
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        // 未完待续
                    }
                } else {
                    that.pageExamObj = that.checkboxExamList[that.indexIng + 1]
                    that.checkedExamId = that.checkboxExamList[that.indexIng + 1].subjectId
                    that.indexIng = that.indexIng + 1
                    if (that.pageExamObj.doneAnswerIds != '' && that.pageExamObj.doneAnswerIds != null && that.pageExamObj.doneAnswerIds != undefined) {
                        var checkListArr = that.pageExamObj.doneAnswerIds.split(",");
                        var temp_checkList = [];
                        for (var i = 0; i < checkListArr.length; i++) {
                            temp_checkList.push(checkListArr[i]);
                        }
                        that.checkList = temp_checkList;
                    } else {
                        that.checkList = [];
                    }
                }

            } else if (that.pageExamObj.classify == "3") {
                that.estimateList[that.indexIng].doneAnswerIds = answerId
                if (that.indexIng + 1 >= that.estimateList.length) {
                    that.indexIng = 0
                    if (that.budingxiang.length > 0) { //4
                        if (that.budingxiang[0].subjectList.length > 0) {
                            that.smallQuestionIndex = that.budingxiang[0].subjectList[0].subjectId
                            that.smallQuestionObj = that.budingxiang[0].subjectList[0]
                        } else {
                            that.smallQuestionObj = that.budingxiang[0]
                            that.smallQuestionIndex = that.budingxiang[0].subjectId
                        }
                        that.pageExamObj = that.budingxiang[0]
                        that.checkedExamId = that.budingxiang[0].subjectId
                    } else {
                        if (that.jisuanfenxi.length > 0) { //5
                            that.pageExamObj = that.jisuanfenxi[0]
                            that.checkedExamId = that.jisuanfenxi[0].subjectId
                        } else {
                            if (that.synthesisList.length > 0) { //6
                                that.pageExamObj = that.synthesisList[0]
                                that.checkedExamId = that.synthesisList[0].subjectId
                            } else {
                                if (that.shortAnswerList.length > 0) { //7
                                    that.pageExamObj = that.shortAnswerList[0]
                                    that.checkedExamId = that.shortAnswerList[0].subjectId
                                } else {
                                    if (that.jisuanhuida.length > 0) { //8
                                        that.pageExamObj = that.jisuanhuida[0]
                                        that.checkedExamId = that.jisuanhuida[0].subjectId
                                    } else {
                                        if (that.anlifenxi.length > 0) { //9
                                            that.pageExamObj = that.anlifenxi[0]
                                            that.checkedExamId = that.anlifenxi[0].subjectId
                                        } else {
                                            if (that.jisuan.length > 0) { //10
                                                that.pageExamObj = that.jisuan[0]
                                                that.checkedExamId = that.jisuan[0].subjectId
                                            } else {
                                                if (that.zonghefenxi.length > 0) { //11
                                                    that.pageExamObj = that.zonghefenxi[0]
                                                    that.checkedExamId = that.zonghefenxi[0].subjectId
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    // 未完待续

                } else {
                    that.pageExamObj = that.estimateList[that.indexIng + 1]
                    that.checkedExamId = that.estimateList[that.indexIng + 1].subjectId
                    that.indexIng = that.indexIng + 1
                }

            } else if (that.pageExamObj.classify == "4") {
                // that.budingxiang[that.indexIng].doneAnswerIds=answerId
                // debugger
                if (that.pageExamObj.subjectList.length > 0) { // 如果有小题
                    that.budingxiang[that.indexIng].doneAnswerIds = answerId
                    console.log('锤石', that.pageExamObj.subjectList.length, that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1])
                    // 判断当前是否为最后一小题
                    if (that.smallQuestionIndex == that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId) { // 是 赋值
                        that.budingxiang[that.indexIng].subjectList[that.pageExamObj.subjectList.length - 1].doneAnswerIds = answerId
                        // 判断是否为这个类型的最后一题
                        if (that.indexIng + 1 >= that.budingxiang.length) { // 是
                            that.indexIng = 0
                            if (that.jisuanfenxi.length > 0) { //5
                                that.pageExamObj = that.jisuanfenxi[0]
                                that.checkedExamId = that.jisuanfenxi[0].subjectId
                                if (that.jisuanfenxi[0].subjectList.length > 0) {
                                    that.smallQuestionObj = that.jisuanfenxi[0].subjectList[0]
                                    that.smallQuestionIndex = that.jisuanfenxi[0].subjectList[0].subjectId
                                } else {
                                    that.smallQuestionObj = that.jisuanfenxi[0]
                                    that.smallQuestionIndex = that.jisuanfenxi[0].subjectId
                                }
                            } else {
                                if (that.synthesisList.length > 0) { //6
                                    that.pageExamObj = that.synthesisList[0]
                                    that.checkedExamId = that.synthesisList[0].subjectId
                                    if (that.synthesisList[0].subjectList.length > 0) {
                                        that.smallQuestionObj = that.synthesisList[0].subjectList[0]
                                        that.smallQuestionIndex = that.synthesisList[0].subjectList[0].subjectId
                                    } else {
                                        that.smallQuestionObj = that.synthesisList[0]
                                        that.smallQuestionIndex = that.synthesisList[0].subjectId
                                    }
                                } else {
                                    if (that.shortAnswerList.length > 0) { //7
                                        that.pageExamObj = that.shortAnswerList[0]
                                        that.checkedExamId = that.shortAnswerList[0].subjectId
                                        if (that.shortAnswerList[0].subjectList.length > 0) {
                                            that.smallQuestionObj = that.shortAnswerList[0].subjectList[0]
                                            that.smallQuestionIndex = that.shortAnswerList[0].subjectList[0].subjectId
                                        } else {
                                            that.smallQuestionObj = that.shortAnswerList[0]
                                            that.smallQuestionIndex = that.shortAnswerList[0].subjectId
                                        }
                                    } else {
                                        if (that.jisuanhuida.length > 0) { //8
                                            that.pageExamObj = that.jisuanhuida[0]
                                            that.checkedExamId = that.jisuanhuida[0].subjectId
                                            if (that.jisuanhuida[0].subjectList.length > 0) {
                                                that.smallQuestionObj = that.jisuanhuida[0].subjectList[0]
                                                that.smallQuestionIndex = that.jisuanhuida[0].subjectList[0].subjectId
                                            } else {
                                                that.smallQuestionObj = that.jisuanhuida[0]
                                                that.smallQuestionIndex = that.jisuanhuida[0].subjectId
                                            }
                                        } else {
                                            if (that.anlifenxi.length > 0) { //9
                                                that.pageExamObj = that.anlifenxi[0]
                                                that.checkedExamId = that.anlifenxi[0].subjectId
                                                if (that.anlifenxi[0].subjectList.length > 0) {
                                                    that.smallQuestionObj = that.anlifenxi[0].subjectList[0]
                                                    that.smallQuestionIndex = that.anlifenxi[0].subjectList[0].subjectId
                                                } else {
                                                    that.smallQuestionObj = that.anlifenxi[0]
                                                    that.smallQuestionIndex = that.anlifenxi[0].subjectId
                                                }
                                            } else {
                                                if (that.jisuan.length > 0) { //10
                                                    that.pageExamObj = that.jisuan[0]
                                                    that.checkedExamId = that.jisuan[0].subjectId
                                                    if (that.jisuan[0].subjectList.length > 0) {
                                                        that.smallQuestionObj = that.jisuan[0].subjectList[0]
                                                        that.smallQuestionIndex = that.jisuan[0].subjectList[0].subjectId
                                                    } else {
                                                        that.smallQuestionObj = that.jisuan[0]
                                                        that.smallQuestionIndex = that.jisuan[0].subjectId
                                                    }
                                                } else {
                                                    if (that.zonghefenxi.length > 0) { //11
                                                        that.pageExamObj = that.zonghefenxi[0]
                                                        that.checkedExamId = that.zonghefenxi[0].subjectId
                                                        if (that.zonghefenxi[0].subjectList.length > 0) {
                                                            that.smallQuestionObj = that.zonghefenxi[0].subjectList[0]
                                                            that.smallQuestionIndex = that.zonghefenxi[0].subjectList[0].subjectId
                                                        } else {
                                                            that.smallQuestionObj = that.zonghefenxi[0]
                                                            that.smallQuestionIndex = that.zonghefenxi[0].subjectId
                                                        }
                                                    } else {
                                                        that.$confirm('您已经做到最后一题了是否提交呢', '提示', {
                                                            confirmButtonText: '确定',
                                                            cancelButtonText: '取消',
                                                            type: 'warning'
                                                        }).then(() => {
                                                            that.submit()
                                                        }).catch(() => {

                                                        });
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else { // 不是
                            // 下一题
                            that.pageExamObj = that.budingxiang[that.indexIng + 1] // 获取小一题内容
                            that.checkedExamId = that.budingxiang[that.indexIng + 1].subjectId // 左侧的当选状态
                            if (that.budingxiang[that.indexIng + 1].subjectList.length > 0) { // 判断下一题是否有小题
                                that.smallQuestionIndex = that.budingxiang[that.indexIng + 1].subjectList[0].subjectId // 有的话赋值列表的第一个
                                that.smallQuestionObj = that.budingxiang[that.indexIng + 1].subjectList[0]
                            } else {
                                // 没有的话，赋值父的
                                that.smallQuestionIndex = that.budingxiang[that.indexIng + 1].subjectId
                                that.smallQuestionObj = that.budingxiang[that.indexIng + 1]
                            }
                            that.indexIng = that.indexIng + 1
                        }
                    } else { // 不是最后一小题  循环小题列表
                        for (var i = 0; i < that.pageExamObj.subjectList.length; i++) {
                            console.log('smallQuestionIndex', that.smallQuestionIndex)
                            // 判断当前小题是哪个小题
                            if (that.smallQuestionIndex == that.pageExamObj.subjectList[i].subjectId) {
                                // 是的话赋值
                                that.budingxiang[that.indexIng].subjectList[i].doneAnswerIds = answerId
                                that.smallQuestionIndex = that.budingxiang[that.indexIng].subjectList[Number(i) + 1].subjectId
                                that.smallQuestionObj = that.budingxiang[that.indexIng].subjectList[Number(i) + 1]
                                return
                            }
                        }
                    }
                } else {
                    that.budingxiang[that.indexIng].doneAnswerIds = answerId
                    if (that.indexIng + 1 >= that.budingxiang.length) { // 是
                        console.log('最后一题')
                        that.indexIng = 0
                        if (that.jisuanfenxi.length > 0) { //5
                            console.log('====最后一题')
                            console.log(that.jisuanfenxi[0])
                            console.log(that.jisuanfenxi);
                            // debugger;
                            that.pageExamObj = that.jisuanfenxi[0]
                            that.checkedExamId = that.jisuanfenxi[0].subjectId
                            if (that.jisuanfenxi[0].subjectList.length > 0) {
                                that.smallQuestionObj = that.jisuanfenxi[0].subjectList[0]
                                that.smallQuestionIndex = that.jisuanfenxi[0].subjectList[0].subjectId
                            } else {
                                that.smallQuestionObj = that.jisuanfenxi[0]
                                that.smallQuestionIndex = that.jisuanfenxi[0].subjectId
                            }
                        } else {
                            if (that.synthesisList.length > 0) { //6
                                console.log('====最后一题')
                                console.log(that.synthesisList[0])
                                console.log(that.synthesisList);
                                // debugger;
                                that.pageExamObj = that.synthesisList[0]
                                that.checkedExamId = that.synthesisList[0].subjectId
                                if (that.synthesisList[0].subjectList.length > 0) {
                                    that.smallQuestionObj = that.synthesisList[0].subjectList[0]
                                    that.smallQuestionIndex = that.synthesisList[0].subjectList[0].subjectId
                                } else {
                                    that.smallQuestionObj = that.synthesisList[0]
                                    that.smallQuestionIndex = that.synthesisList[0].subjectId
                                }

                            } else {
                                if (that.shortAnswerList.length > 0) { //7
                                    that.pageExamObj = that.shortAnswerList[0]
                                    that.checkedExamId = that.shortAnswerList[0].subjectId
                                    if (that.shortAnswerList[0].subjectList.length > 0) {
                                        that.smallQuestionObj = that.shortAnswerList[0].subjectList[0]
                                        that.smallQuestionIndex = that.shortAnswerList[0].subjectList[0].subjectId
                                    } else {
                                        that.smallQuestionObj = that.shortAnswerList[0]
                                        that.smallQuestionIndex = that.shortAnswerList[0].subjectId
                                    }
                                } else {
                                    if (that.jisuanhuida.length > 0) { //8
                                        that.pageExamObj = that.jisuanhuida[0]
                                        that.checkedExamId = that.jisuanhuida[0].subjectId
                                        if (that.jisuanhuida[0].subjectList.length > 0) {
                                            that.smallQuestionObj = that.jisuanhuida[0].subjectList[0]
                                            that.smallQuestionIndex = that.jisuanhuida[0].subjectList[0].subjectId
                                        } else {
                                            that.smallQuestionObj = that.jisuanhuida[0]
                                            that.smallQuestionIndex = that.jisuanhuida[0].subjectId
                                        }
                                    } else {
                                        if (that.anlifenxi.length > 0) { //9
                                            that.pageExamObj = that.anlifenxi[0]
                                            that.checkedExamId = that.anlifenxi[0].subjectId
                                            if (that.anlifenxi[0].subjectList.length > 0) {
                                                that.smallQuestionObj = that.anlifenxi[0].subjectList[0]
                                                that.smallQuestionIndex = that.anlifenxi[0].subjectList[0].subjectId
                                            } else {
                                                that.smallQuestionObj = that.anlifenxi[0]
                                                that.smallQuestionIndex = that.anlifenxi[0].subjectId
                                            }
                                        } else {
                                            if (that.jisuan.length > 0) { //10
                                                that.pageExamObj = that.jisuan[0]
                                                that.checkedExamId = that.jisuan[0].subjectId
                                                if (that.jisuan[0].subjectList.length > 0) {
                                                    that.smallQuestionObj = that.jisuan[0].subjectList[0]
                                                    that.smallQuestionIndex = that.jisuan[0].subjectList[0].subjectId
                                                } else {
                                                    that.smallQuestionObj = that.jisuan[0]
                                                    that.smallQuestionIndex = that.jisuan[0].subjectId
                                                }
                                            } else {
                                                if (that.zonghefenxi.length > 0) { //11
                                                    that.pageExamObj = that.zonghefenxi[0]
                                                    that.checkedExamId = that.zonghefenxi[0].subjectId
                                                    if (that.zonghefenxi[0].subjectList.length > 0) {
                                                        that.smallQuestionObj = that.zonghefenxi[0].subjectList[0]
                                                        that.smallQuestionIndex = that.zonghefenxi[0].subjectList[0].subjectId
                                                    } else {
                                                        that.smallQuestionObj = that.zonghefenxi[0]
                                                        that.smallQuestionIndex = that.zonghefenxi[0].subjectId
                                                    }
                                                } else {
                                                    that.$confirm('您已经做到最后一题了是否提交呢', '提示', {
                                                        confirmButtonText: '确定',
                                                        cancelButtonText: '取消',
                                                        type: 'warning'
                                                    }).then(() => {
                                                        that.submit()
                                                    }).catch(() => {

                                                    });
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else { // 不是
                        // 下一题
                        that.pageExamObj = that.budingxiang[that.indexIng + 1] // 获取小一题内容
                        that.checkedExamId = that.budingxiang[that.indexIng + 1].subjectId // 左侧的当选状态
                        if (that.budingxiang[that.indexIng + 1].subjectList.length > 0) { // 判断下一题是否有小题
                            that.smallQuestionIndex = that.budingxiang[that.indexIng + 1].subjectList[0].subjectId // 有的话赋值列表的第一个
                            that.smallQuestionObj = that.budingxiang[that.indexIng + 1].subjectList[0]
                        } else {
                            // 没有的话，赋值父的
                            that.smallQuestionIndex = that.budingxiang[that.indexIng + 1].subjectId
                            that.smallQuestionObj = that.budingxiang[that.indexIng + 1]
                        }
                        that.indexIng = that.indexIng + 1
                    }
                }


            } else if (that.pageExamObj.classify == "5") {
                // that.jisuanfenxi[that.indexIng].doneAnswer=answerText
                that.score = ''
                if (that.pageExamObj.subjectList.length > 0) { // 如果有小题
                    that.jisuanfenxi[that.indexIng].doneAnswer = answerText
                    if (that.smallQuestionIndex == that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId) { // 是 赋值
                        that.jisuanfenxi[that.indexIng].subjectList[that.pageExamObj.subjectList.length - 1].doneAnswer = answerText
                        // 判断是否为这个类型的最后一题
                        if (that.indexIng + 1 >= that.jisuanfenxi.length) { // 是
                            console.log('最后一题')
                            that.indexIng = 0
                            if (that.synthesisList.length > 0) { //6
                                console.log('====最后一题')
                                console.log(that.synthesisList[0])
                                console.log(that.synthesisList);
                                // debugger;
                                that.pageExamObj = that.synthesisList[0]
                                that.checkedExamId = that.synthesisList[0].subjectId
                                if (that.synthesisList[0].subjectList.length > 0) {
                                    that.smallQuestionObj = that.synthesisList[0].subjectList[0]
                                    that.smallQuestionIndex = that.synthesisList[0].subjectList[0].subjectId
                                } else {
                                    that.smallQuestionObj = that.synthesisList[0]
                                    that.smallQuestionIndex = that.synthesisList[0].subjectId
                                }

                            } else {
                                if (that.shortAnswerList.length > 0) { //7
                                    that.pageExamObj = that.shortAnswerList[0]
                                    that.checkedExamId = that.shortAnswerList[0].subjectId
                                    if (that.shortAnswerList[0].subjectList.length > 0) {
                                        that.smallQuestionObj = that.shortAnswerList[0].subjectList[0]
                                        that.smallQuestionIndex = that.shortAnswerList[0].subjectList[0].subjectId
                                    } else {
                                        that.smallQuestionObj = that.shortAnswerList[0]
                                        that.smallQuestionIndex = that.shortAnswerList[0].subjectId
                                    }
                                } else {
                                    if (that.jisuanhuida.length > 0) { //8
                                        that.pageExamObj = that.jisuanhuida[0]
                                        that.checkedExamId = that.jisuanhuida[0].subjectId
                                        if (that.jisuanhuida[0].subjectList.length > 0) {
                                            that.smallQuestionObj = that.jisuanhuida[0].subjectList[0]
                                            that.smallQuestionIndex = that.jisuanhuida[0].subjectList[0].subjectId
                                        } else {
                                            that.smallQuestionObj = that.jisuanhuida[0]
                                            that.smallQuestionIndex = that.jisuanhuida[0].subjectId
                                        }
                                    } else {
                                        if (that.anlifenxi.length > 0) { //9
                                            that.pageExamObj = that.anlifenxi[0]
                                            that.checkedExamId = that.anlifenxi[0].subjectId
                                            if (that.anlifenxi[0].subjectList.length > 0) {
                                                that.smallQuestionObj = that.anlifenxi[0].subjectList[0]
                                                that.smallQuestionIndex = that.anlifenxi[0].subjectList[0].subjectId
                                            } else {
                                                that.smallQuestionObj = that.anlifenxi[0]
                                                that.smallQuestionIndex = that.anlifenxi[0].subjectId
                                            }
                                        } else {
                                            if (that.jisuan.length > 0) { //10
                                                that.pageExamObj = that.jisuan[0]
                                                that.checkedExamId = that.jisuan[0].subjectId
                                                if (that.jisuan[0].subjectList.length > 0) {
                                                    that.smallQuestionObj = that.jisuan[0].subjectList[0]
                                                    that.smallQuestionIndex = that.jisuan[0].subjectList[0].subjectId
                                                } else {
                                                    that.smallQuestionObj = that.jisuan[0]
                                                    that.smallQuestionIndex = that.jisuan[0].subjectId
                                                }
                                            } else {
                                                if (that.zonghefenxi.length > 0) { //11
                                                    that.pageExamObj = that.zonghefenxi[0]
                                                    that.checkedExamId = that.zonghefenxi[0].subjectId
                                                    if (that.zonghefenxi[0].subjectList.length > 0) {
                                                        that.smallQuestionObj = that.zonghefenxi[0].subjectList[0]
                                                        that.smallQuestionIndex = that.zonghefenxi[0].subjectList[0].subjectId
                                                    } else {
                                                        that.smallQuestionObj = that.zonghefenxi[0]
                                                        that.smallQuestionIndex = that.zonghefenxi[0].subjectId
                                                    }
                                                } else {
                                                    that.$confirm('您已经做到最后一题了是否提交呢', '提示', {
                                                        confirmButtonText: '确定',
                                                        cancelButtonText: '取消',
                                                        type: 'warning'
                                                    }).then(() => {
                                                        that.submit()
                                                    }).catch(() => {

                                                    });
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else { // 不是
                            // 下一题
                            that.pageExamObj = that.jisuanfenxi[that.indexIng + 1] // 获取小一题内容
                            that.checkedExamId = that.jisuanfenxi[that.indexIng + 1].subjectId // 左侧的当选状态
                            if (that.jisuanfenxi[that.indexIng + 1].subjectList.length > 0) { // 判断下一题是否有小题
                                that.smallQuestionIndex = that.jisuanfenxi[that.indexIng + 1].subjectList[0].subjectId // 有的话赋值列表的第一个
                                that.smallQuestionObj = that.jisuanfenxi[that.indexIng + 1].subjectList[0]
                            } else {
                                // 没有的话，赋值父的
                                that.smallQuestionIndex = that.jisuanfenxi[that.indexIng + 1].subjectId
                                that.smallQuestionObj = that.jisuanfenxi[that.indexIng + 1]
                            }
                            that.indexIng = that.indexIng + 1
                        }
                    } else { // 不是最后一小题  循环小题列表
                        for (var i = 0; i < that.pageExamObj.subjectList.length; i++) {
                            console.log('smallQuestionIndex', that.smallQuestionIndex)
                            // 判断当前小题是哪个小题
                            if (that.smallQuestionIndex == that.pageExamObj.subjectList[i].subjectId) {
                                // 是的话赋值
                                that.jisuanfenxi[that.indexIng].subjectList[i].doneAnswer = answerText
                                that.smallQuestionIndex = that.jisuanfenxi[that.indexIng].subjectList[Number(i) + 1].subjectId
                                that.smallQuestionObj = that.jisuanfenxi[that.indexIng].subjectList[Number(i) + 1]
                                return
                            }
                        }
                    }
                } else {
                    that.jisuanfenxi[that.indexIng].doneAnswer = answerText
                    // 判断当前没有小题  直接获取下一大题的内容
                    if (that.indexIng + 1 >= that.jisuanfenxi.length) { // 是
                        console.log('最后一题')
                        that.indexIng = 0
                        if (that.synthesisList.length > 0) { //6
                            // debugger;
                            that.pageExamObj = that.synthesisList[0]
                            that.checkedExamId = that.synthesisList[0].subjectId
                            if (that.synthesisList[0].subjectList.length > 0) {
                                that.smallQuestionObj = that.synthesisList[0].subjectList[0]
                                that.smallQuestionIndex = that.synthesisList[0].subjectList[0].subjectId
                            } else {
                                that.smallQuestionObj = that.synthesisList[0]
                                that.smallQuestionIndex = that.synthesisList[0].subjectId
                            }
                        } else {
                            if (that.shortAnswerList.length > 0) { //7
                                that.pageExamObj = that.shortAnswerList[0]
                                that.checkedExamId = that.shortAnswerList[0].subjectId
                                if (that.shortAnswerList[0].subjectList.length > 0) {
                                    that.smallQuestionObj = that.shortAnswerList[0].subjectList[0]
                                    that.smallQuestionIndex = that.shortAnswerList[0].subjectList[0].subjectId
                                } else {
                                    that.smallQuestionObj = that.shortAnswerList[0]
                                    that.smallQuestionIndex = that.shortAnswerList[0].subjectId
                                }
                            } else {
                                if (that.jisuanhuida.length > 0) { //8
                                    that.pageExamObj = that.jisuanhuida[0]
                                    that.checkedExamId = that.jisuanhuida[0].subjectId
                                    if (that.jisuanhuida[0].subjectList.length > 0) {
                                        that.smallQuestionObj = that.jisuanhuida[0].subjectList[0]
                                        that.smallQuestionIndex = that.jisuanhuida[0].subjectList[0].subjectId
                                    } else {
                                        that.smallQuestionObj = that.jisuanhuida[0]
                                        that.smallQuestionIndex = that.jisuanhuida[0].subjectId
                                    }
                                } else {
                                    if (that.anlifenxi.length > 0) { //9
                                        that.pageExamObj = that.anlifenxi[0]
                                        that.checkedExamId = that.anlifenxi[0].subjectId
                                        if (that.anlifenxi[0].subjectList.length > 0) {
                                            that.smallQuestionObj = that.anlifenxi[0].subjectList[0]
                                            that.smallQuestionIndex = that.anlifenxi[0].subjectList[0].subjectId
                                        } else {
                                            that.smallQuestionObj = that.anlifenxi[0]
                                            that.smallQuestionIndex = that.anlifenxi[0].subjectId
                                        }
                                    } else {
                                        if (that.jisuan.length > 0) { //10
                                            that.pageExamObj = that.jisuan[0]
                                            that.checkedExamId = that.jisuan[0].subjectId
                                            if (that.jisuan[0].subjectList.length > 0) {
                                                that.smallQuestionObj = that.jisuan[0].subjectList[0]
                                                that.smallQuestionIndex = that.jisuan[0].subjectList[0].subjectId
                                            } else {
                                                that.smallQuestionObj = that.jisuan[0]
                                                that.smallQuestionIndex = that.jisuan[0].subjectId
                                            }
                                        } else {
                                            if (that.zonghefenxi.length > 0) { //11
                                                that.pageExamObj = that.zonghefenxi[0]
                                                that.checkedExamId = that.zonghefenxi[0].subjectId
                                                if (that.zonghefenxi[0].subjectList.length > 0) {
                                                    that.smallQuestionObj = that.zonghefenxi[0].subjectList[0]
                                                    that.smallQuestionIndex = that.zonghefenxi[0].subjectList[0].subjectId
                                                } else {
                                                    that.smallQuestionObj = that.zonghefenxi[0]
                                                    that.smallQuestionIndex = that.zonghefenxi[0].subjectId
                                                }
                                            } else {
                                                that.$confirm('您已经做到最后一题了是否提交呢', '提示', {
                                                    confirmButtonText: '确定',
                                                    cancelButtonText: '取消',
                                                    type: 'warning'
                                                }).then(() => {
                                                    that.submit()
                                                }).catch(() => {

                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else { // 不是
                        // 下一题
                        that.pageExamObj = that.jisuanfenxi[that.indexIng + 1] // 获取小一题内容
                        that.checkedExamId = that.jisuanfenxi[that.indexIng + 1].subjectId // 左侧的当选状态
                        if (that.jisuanfenxi[that.indexIng + 1].subjectList.length > 0) { // 判断下一题是否有小题
                            that.smallQuestionIndex = that.jisuanfenxi[that.indexIng + 1].subjectList[0].subjectId // 有的话赋值列表的第一个
                            that.smallQuestionObj = that.jisuanfenxi[that.indexIng + 1].subjectList[0]
                        } else {
                            // 没有的话，赋值父的
                            that.smallQuestionIndex = that.jisuanfenxi[that.indexIng + 1].subjectId
                            that.smallQuestionObj = that.jisuanfenxi[that.indexIng + 1]
                        }
                        that.indexIng = that.indexIng + 1
                    }
                }
            } else if (that.pageExamObj.classify == '6') {
                that.score = ''
                if (that.pageExamObj.subjectList.length > 0) { // 如果有小题
                    console.log(typeof (that.pageExamObj.subjectList.length))
                    console.log(that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId)
                    that.synthesisList[that.indexIng].doneAnswer = answerText
                    if (that.smallQuestionIndex == that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId) { // 是 赋值
                        that.synthesisList[that.indexIng].subjectList[that.pageExamObj.subjectList.length - 1].doneAnswer = answerText
                        // 判断是否为这个类型的最后一题
                        if (that.indexIng + 1 >= that.synthesisList.length) { // 是
                            console.log('最后一题')
                            that.indexIng = 0
                            if (that.shortAnswerList.length > 0) { //7
                                // debugger;
                                that.pageExamObj = that.shortAnswerList[0]
                                that.checkedExamId = that.shortAnswerList[0].subjectId
                                if (that.shortAnswerList[0].subjectList.length > 0) {
                                    that.smallQuestionObj = that.shortAnswerList[0].subjectList[0]
                                    that.smallQuestionIndex = that.shortAnswerList[0].subjectList[0].subjectId
                                } else {
                                    that.smallQuestionObj = that.shortAnswerList[0]
                                    that.smallQuestionIndex = that.shortAnswerList[0].subjectId
                                }
                            } else {
                                if (that.jisuanhuida.length > 0) { //8
                                    that.pageExamObj = that.jisuanhuida[0]
                                    that.checkedExamId = that.jisuanhuida[0].subjectId
                                    if (that.jisuanhuida[0].subjectList.length > 0) {
                                        that.smallQuestionObj = that.jisuanhuida[0].subjectList[0]
                                        that.smallQuestionIndex = that.jisuanhuida[0].subjectList[0].subjectId
                                    } else {
                                        that.smallQuestionObj = that.jisuanhuida[0]
                                        that.smallQuestionIndex = that.jisuanhuida[0].subjectId
                                    }
                                } else {
                                    if (that.anlifenxi.length > 0) { //9
                                        that.pageExamObj = that.anlifenxi[0]
                                        that.checkedExamId = that.anlifenxi[0].subjectId
                                        if (that.anlifenxi[0].subjectList.length > 0) {
                                            that.smallQuestionObj = that.anlifenxi[0].subjectList[0]
                                            that.smallQuestionIndex = that.anlifenxi[0].subjectList[0].subjectId
                                        } else {
                                            that.smallQuestionObj = that.anlifenxi[0]
                                            that.smallQuestionIndex = that.anlifenxi[0].subjectId
                                        }
                                    } else {
                                        if (that.jisuan.length > 0) { //10
                                            that.pageExamObj = that.jisuan[0]
                                            that.checkedExamId = that.jisuan[0].subjectId
                                            if (that.jisuan[0].subjectList.length > 0) {
                                                that.smallQuestionObj = that.jisuan[0].subjectList[0]
                                                that.smallQuestionIndex = that.jisuan[0].subjectList[0].subjectId
                                            } else {
                                                that.smallQuestionObj = that.jisuan[0]
                                                that.smallQuestionIndex = that.jisuan[0].subjectId
                                            }
                                        } else {
                                            if (that.zonghefenxi.length > 0) { //11
                                                that.pageExamObj = that.zonghefenxi[0]
                                                that.checkedExamId = that.zonghefenxi[0].subjectId
                                                if (that.zonghefenxi[0].subjectList.length > 0) {
                                                    that.smallQuestionObj = that.zonghefenxi[0].subjectList[0]
                                                    that.smallQuestionIndex = that.zonghefenxi[0].subjectList[0].subjectId
                                                } else {
                                                    that.smallQuestionObj = that.zonghefenxi[0]
                                                    that.smallQuestionIndex = that.zonghefenxi[0].subjectId
                                                }
                                            } else {
                                                that.$confirm('您已经做到最后一题了是否提交呢', '提示', {
                                                    confirmButtonText: '确定',
                                                    cancelButtonText: '取消',
                                                    type: 'warning'
                                                }).then(() => {
                                                    that.submit()
                                                }).catch(() => {

                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        } else { // 不是
                            // 下一题
                            that.pageExamObj = that.synthesisList[that.indexIng + 1] // 获取小一题内容
                            that.checkedExamId = that.synthesisList[that.indexIng + 1].subjectId // 左侧的当选状态
                            if (that.synthesisList[that.indexIng + 1].subjectList.length > 0) { // 判断下一题是否有小题
                                that.smallQuestionIndex = that.synthesisList[that.indexIng + 1].subjectList[0].subjectId // 有的话赋值列表的第一个
                                that.smallQuestionObj = that.synthesisList[that.indexIng + 1].subjectList[0]
                            } else {
                                // 没有的话，赋值父的
                                that.smallQuestionIndex = that.synthesisList[that.indexIng + 1].subjectId
                                that.smallQuestionObj = that.synthesisList[that.indexIng + 1]
                            }
                            that.indexIng = that.indexIng + 1
                        }
                    } else { // 不是最后一小题  循环小题列表
                        for (var i = 0; i < that.pageExamObj.subjectList.length; i++) {
                            console.log('smallQuestionIndex', that.smallQuestionIndex)
                            // 判断当前小题是哪个小题
                            if (that.smallQuestionIndex == that.pageExamObj.subjectList[i].subjectId) {
                                // 是的话赋值
                                that.synthesisList[that.indexIng].subjectList[i].doneAnswer = answerText
                                that.smallQuestionIndex = that.synthesisList[that.indexIng].subjectList[Number(i) + 1].subjectId
                                that.smallQuestionObj = that.synthesisList[that.indexIng].subjectList[Number(i) + 1]
                                return
                            }
                        }
                    }
                } else {
                    that.synthesisList[that.indexIng].doneAnswer = answerText
                    // 判断当前没有小题  直接获取下一大题的内容
                    if (that.indexIng + 1 >= that.synthesisList.length) { // 是
                        console.log('最后一题')
                        that.indexIng = 0
                        if (that.shortAnswerList.length > 0) { //7
                            // debugger;
                            that.pageExamObj = that.shortAnswerList[0]
                            that.checkedExamId = that.shortAnswerList[0].subjectId
                            if (that.shortAnswerList[0].subjectList.length > 0) {
                                that.smallQuestionObj = that.shortAnswerList[0].subjectList[0]
                                that.smallQuestionIndex = that.shortAnswerList[0].subjectList[0].subjectId
                            } else {
                                that.smallQuestionObj = that.shortAnswerList[0]
                                that.smallQuestionIndex = that.shortAnswerList[0].subjectId
                            }

                        } else {
                            if (that.jisuanhuida.length > 0) { //8
                                that.pageExamObj = that.jisuanhuida[0]
                                that.checkedExamId = that.jisuanhuida[0].subjectId
                                if (that.jisuanhuida[0].subjectList.length > 0) {
                                    that.smallQuestionObj = that.jisuanhuida[0].subjectList[0]
                                    that.smallQuestionIndex = that.jisuanhuida[0].subjectList[0].subjectId
                                } else {
                                    that.smallQuestionObj = that.jisuanhuida[0]
                                    that.smallQuestionIndex = that.jisuanhuida[0].subjectId
                                }
                            } else {
                                if (that.anlifenxi.length > 0) { //9
                                    that.pageExamObj = that.anlifenxi[0]
                                    that.checkedExamId = that.anlifenxi[0].subjectId
                                    if (that.anlifenxi[0].subjectList.length > 0) {
                                        that.smallQuestionObj = that.anlifenxi[0].subjectList[0]
                                        that.smallQuestionIndex = that.anlifenxi[0].subjectList[0].subjectId
                                    } else {
                                        that.smallQuestionObj = that.anlifenxi[0]
                                        that.smallQuestionIndex = that.anlifenxi[0].subjectId
                                    }
                                } else {
                                    if (that.jisuan.length > 0) { //10
                                        that.pageExamObj = that.jisuan[0]
                                        that.checkedExamId = that.jisuan[0].subjectId
                                        if (that.jisuan[0].subjectList.length > 0) {
                                            that.smallQuestionObj = that.jisuan[0].subjectList[0]
                                            that.smallQuestionIndex = that.jisuan[0].subjectList[0].subjectId
                                        } else {
                                            that.smallQuestionObj = that.jisuan[0]
                                            that.smallQuestionIndex = that.jisuan[0].subjectId
                                        }
                                    } else {
                                        if (that.zonghefenxi.length > 0) { //11
                                            that.pageExamObj = that.zonghefenxi[0]
                                            that.checkedExamId = that.zonghefenxi[0].subjectId
                                            if (that.zonghefenxi[0].subjectList.length > 0) {
                                                that.smallQuestionObj = that.zonghefenxi[0].subjectList[0]
                                                that.smallQuestionIndex = that.zonghefenxi[0].subjectList[0].subjectId
                                            } else {
                                                that.smallQuestionObj = that.zonghefenxi[0]
                                                that.smallQuestionIndex = that.zonghefenxi[0].subjectId
                                            }
                                        } else {
                                            that.$confirm('您已经做到最后一题了是否提交呢', '提示', {
                                                confirmButtonText: '确定',
                                                cancelButtonText: '取消',
                                                type: 'warning'
                                            }).then(() => {
                                                that.submit()
                                            }).catch(() => {

                                            });
                                        }
                                    }
                                }
                            }
                        }
                    } else { // 不是
                        // 下一题
                        that.pageExamObj = that.synthesisList[that.indexIng + 1] // 获取小一题内容
                        that.checkedExamId = that.synthesisList[that.indexIng + 1].subjectId // 左侧的当选状态
                        if (that.synthesisList[that.indexIng + 1].subjectList.length > 0) { // 判断下一题是否有小题
                            that.smallQuestionIndex = that.synthesisList[that.indexIng + 1].subjectList[0].subjectId // 有的话赋值列表的第一个
                            that.smallQuestionObj = that.synthesisList[that.indexIng + 1].subjectList[0]
                        } else {
                            // 没有的话，赋值父的
                            that.smallQuestionIndex = that.synthesisList[that.indexIng + 1].subjectId
                            that.smallQuestionObj = that.synthesisList[that.indexIng + 1]
                        }
                        that.indexIng = that.indexIng + 1
                    }
                }

            } else if (that.pageExamObj.classify == '7') {
                that.score = ''
                if (that.pageExamObj.subjectList.length > 0) { // 如果有小题
                    that.shortAnswerList[that.indexIng].doneAnswer = answerText
                    if (that.smallQuestionIndex == that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId) { // 是 赋值
                        that.shortAnswerList[that.indexIng].subjectList[that.pageExamObj.subjectList.length - 1].doneAnswer = answerText
                        // 判断是否为这个类型的最后一题
                        if (that.indexIng + 1 >= that.shortAnswerList.length) { // 是
                            console.log('最后一题')
                            that.indexIng = 0
                            if (that.jisuanhuida.length > 0) { //8
                                // debugger;
                                that.pageExamObj = that.jisuanhuida[0]
                                that.checkedExamId = that.jisuanhuida[0].subjectId
                                if (that.jisuanhuida[0].subjectList.length > 0) {
                                    that.smallQuestionObj = that.jisuanhuida[0].subjectList[0]
                                    that.smallQuestionIndex = that.jisuanhuida[0].subjectList[0].subjectId
                                } else {
                                    that.smallQuestionObj = that.jisuanhuida[0]
                                    that.smallQuestionIndex = that.jisuanhuida[0].subjectId
                                }
                            } else {
                                if (that.anlifenxi.length > 0) { //9
                                    that.pageExamObj = that.anlifenxi[0]
                                    that.checkedExamId = that.anlifenxi[0].subjectId
                                    if (that.anlifenxi[0].subjectList.length > 0) {
                                        that.smallQuestionObj = that.anlifenxi[0].subjectList[0]
                                        that.smallQuestionIndex = that.anlifenxi[0].subjectList[0].subjectId
                                    } else {
                                        that.smallQuestionObj = that.anlifenxi[0]
                                        that.smallQuestionIndex = that.anlifenxi[0].subjectId
                                    }
                                } else {
                                    if (that.jisuan.length > 0) { //10
                                        that.pageExamObj = that.jisuan[0]
                                        that.checkedExamId = that.jisuan[0].subjectId
                                        if (that.jisuan[0].subjectList.length > 0) {
                                            that.smallQuestionObj = that.jisuan[0].subjectList[0]
                                            that.smallQuestionIndex = that.jisuan[0].subjectList[0].subjectId
                                        } else {
                                            that.smallQuestionObj = that.jisuan[0]
                                            that.smallQuestionIndex = that.jisuan[0].subjectId
                                        }
                                    } else {
                                        if (that.zonghefenxi.length > 0) { //11
                                            that.pageExamObj = that.zonghefenxi[0]
                                            that.checkedExamId = that.zonghefenxi[0].subjectId
                                            if (that.zonghefenxi[0].subjectList.length > 0) {
                                                that.smallQuestionObj = that.zonghefenxi[0].subjectList[0]
                                                that.smallQuestionIndex = that.zonghefenxi[0].subjectList[0].subjectId
                                            } else {
                                                that.smallQuestionObj = that.zonghefenxi[0]
                                                that.smallQuestionIndex = that.zonghefenxi[0].subjectId
                                            }
                                        } else {
                                            that.$confirm('您已经做到最后一题了是否提交呢', '提示', {
                                                confirmButtonText: '确定',
                                                cancelButtonText: '取消',
                                                type: 'warning'
                                            }).then(() => {
                                                that.submit()
                                            }).catch(() => {

                                            });
                                        }
                                    }
                                }
                            }
                        } else { // 不是
                            // 下一题
                            that.pageExamObj = that.shortAnswerList[that.indexIng + 1] // 获取小一题内容
                            that.checkedExamId = that.shortAnswerList[that.indexIng + 1].subjectId // 左侧的当选状态
                            if (that.shortAnswerList[that.indexIng + 1].subjectList.length > 0) { // 判断下一题是否有小题
                                that.smallQuestionIndex = that.shortAnswerList[that.indexIng + 1].subjectList[0].subjectId // 有的话赋值列表的第一个
                                that.smallQuestionObj = that.shortAnswerList[that.indexIng + 1].subjectList[0]
                            } else {
                                // 没有的话，赋值父的
                                that.smallQuestionIndex = that.shortAnswerList[that.indexIng + 1].subjectId
                                that.smallQuestionObj = that.shortAnswerList[that.indexIng + 1]
                            }
                            that.indexIng = that.indexIng + 1
                        }
                    } else { // 不是最后一小题  循环小题列表
                        for (var i = 0; i < that.pageExamObj.subjectList.length; i++) {
                            console.log('smallQuestionIndex', that.smallQuestionIndex)
                            // 判断当前小题是哪个小题
                            if (that.smallQuestionIndex == that.pageExamObj.subjectList[i].subjectId) {
                                // 是的话赋值
                                that.shortAnswerList[that.indexIng].subjectList[i].doneAnswer = answerText
                                that.smallQuestionIndex = that.shortAnswerList[that.indexIng].subjectList[Number(i) + 1].subjectId
                                that.smallQuestionObj = that.shortAnswerList[that.indexIng].subjectList[Number(i) + 1]
                                return
                            }
                        }
                    }
                } else {
                    that.shortAnswerList[that.indexIng].doneAnswer = answerText
                    // 判断当前没有小题  直接获取下一大题的内容
                    if (that.indexIng + 1 >= that.shortAnswerList.length) { // 是
                        console.log('最后一题')
                        that.indexIng = 0
                        if (that.jisuanhuida.length > 0) { //8
                            // debugger;
                            that.pageExamObj = that.jisuanhuida[0]
                            that.checkedExamId = that.jisuanhuida[0].subjectId
                            if (that.jisuanhuida[0].subjectList.length > 0) {
                                that.smallQuestionObj = that.jisuanhuida[0].subjectList[0]
                                that.smallQuestionIndex = that.jisuanhuida[0].subjectList[0].subjectId
                            } else {
                                that.smallQuestionObj = that.jisuanhuida[0]
                                that.smallQuestionIndex = that.jisuanhuida[0].subjectId
                            }
                        } else {
                            if (that.anlifenxi.length > 0) { //9
                                that.pageExamObj = that.anlifenxi[0]
                                that.checkedExamId = that.anlifenxi[0].subjectId
                                if (that.anlifenxi[0].subjectList.length > 0) {
                                    that.smallQuestionObj = that.anlifenxi[0].subjectList[0]
                                    that.smallQuestionIndex = that.anlifenxi[0].subjectList[0].subjectId
                                } else {
                                    that.smallQuestionObj = that.anlifenxi[0]
                                    that.smallQuestionIndex = that.anlifenxi[0].subjectId
                                }
                            } else {
                                if (that.jisuan.length > 0) { //10
                                    that.pageExamObj = that.jisuan[0]
                                    that.checkedExamId = that.jisuan[0].subjectId
                                    if (that.jisuan[0].subjectList.length > 0) {
                                        that.smallQuestionObj = that.jisuan[0].subjectList[0]
                                        that.smallQuestionIndex = that.jisuan[0].subjectList[0].subjectId
                                    } else {
                                        that.smallQuestionObj = that.jisuan[0]
                                        that.smallQuestionIndex = that.jisuan[0].subjectId
                                    }
                                } else {
                                    if (that.zonghefenxi.length > 0) { //11
                                        that.pageExamObj = that.zonghefenxi[0]
                                        that.checkedExamId = that.zonghefenxi[0].subjectId
                                        if (that.zonghefenxi[0].subjectList.length > 0) {
                                            that.smallQuestionObj = that.zonghefenxi[0].subjectList[0]
                                            that.smallQuestionIndex = that.zonghefenxi[0].subjectList[0].subjectId
                                        } else {
                                            that.smallQuestionObj = that.zonghefenxi[0]
                                            that.smallQuestionIndex = that.zonghefenxi[0].subjectId
                                        }
                                    } else {
                                        that.$confirm('您已经做到最后一题了是否提交呢', '提示', {
                                            confirmButtonText: '确定',
                                            cancelButtonText: '取消',
                                            type: 'warning'
                                        }).then(() => {
                                            that.submit()
                                        }).catch(() => {

                                        });
                                    }
                                }
                            }
                        }
                    } else { // 不是
                        // 下一题
                        that.pageExamObj = that.shortAnswerList[that.indexIng + 1] // 获取小一题内容
                        that.checkedExamId = that.shortAnswerList[that.indexIng + 1].subjectId // 左侧的当选状态
                        if (that.shortAnswerList[that.indexIng + 1].subjectList.length > 0) { // 判断下一题是否有小题
                            that.smallQuestionIndex = that.shortAnswerList[that.indexIng + 1].subjectList[0].subjectId // 有的话赋值列表的第一个
                            that.smallQuestionObj = that.shortAnswerList[that.indexIng + 1].subjectList[0]
                        } else {
                            // 没有的话，赋值父的
                            that.smallQuestionIndex = that.shortAnswerList[that.indexIng + 1].subjectId
                            that.smallQuestionObj = that.shortAnswerList[that.indexIng + 1]
                        }
                        that.indexIng = that.indexIng + 1
                    }
                }
            } else if (that.pageExamObj.classify == '8') {
                that.score = ''
                if (that.pageExamObj.subjectList.length > 0) { // 如果有小题
                    that.jisuanhuida[that.indexIng].doneAnswer = answerText
                    if (that.smallQuestionIndex == that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId) { // 是 赋值
                        that.jisuanhuida[that.indexIng].subjectList[that.pageExamObj.subjectList.length - 1].doneAnswer = answerText
                        // 判断是否为这个类型的最后一题
                        if (that.indexIng + 1 >= that.jisuanhuida.length) { // 是
                            console.log('最后一题')
                            that.indexIng = 0

                            if (that.anlifenxi.length > 0) { //9
                                that.pageExamObj = that.anlifenxi[0]
                                that.checkedExamId = that.anlifenxi[0].subjectId
                                if (that.anlifenxi[0].subjectList.length > 0) {
                                    that.smallQuestionObj = that.anlifenxi[0].subjectList[0]
                                    that.smallQuestionIndex = that.anlifenxi[0].subjectList[0].subjectId
                                } else {
                                    that.smallQuestionObj = that.anlifenxi[0]
                                    that.smallQuestionIndex = that.anlifenxi[0].subjectId
                                }
                            } else {
                                if (that.jisuan.length > 0) { //10
                                    that.pageExamObj = that.jisuan[0]
                                    that.checkedExamId = that.jisuan[0].subjectId
                                    if (that.jisuan[0].subjectList.length > 0) {
                                        that.smallQuestionObj = that.jisuan[0].subjectList[0]
                                        that.smallQuestionIndex = that.jisuan[0].subjectList[0].subjectId
                                    } else {
                                        that.smallQuestionObj = that.jisuan[0]
                                        that.smallQuestionIndex = that.jisuan[0].subjectId
                                    }
                                } else {
                                    if (that.zonghefenxi.length > 0) { //11
                                        that.pageExamObj = that.zonghefenxi[0]
                                        that.checkedExamId = that.zonghefenxi[0].subjectId
                                        if (that.zonghefenxi[0].subjectList.length > 0) {
                                            that.smallQuestionObj = that.zonghefenxi[0].subjectList[0]
                                            that.smallQuestionIndex = that.zonghefenxi[0].subjectList[0].subjectId
                                        } else {
                                            that.smallQuestionObj = that.zonghefenxi[0]
                                            that.smallQuestionIndex = that.zonghefenxi[0].subjectId
                                        }
                                    } else {
                                        that.$confirm('您已经做到最后一题了是否提交呢', '提示', {
                                            confirmButtonText: '确定',
                                            cancelButtonText: '取消',
                                            type: 'warning'
                                        }).then(() => {
                                            that.submit()
                                        }).catch(() => {

                                        });
                                    }
                                }
                            }
                        } else { // 不是
                            // 下一题
                            that.pageExamObj = that.jisuanhuida[that.indexIng + 1] // 获取小一题内容
                            that.checkedExamId = that.jisuanhuida[that.indexIng + 1].subjectId // 左侧的当选状态
                            if (that.jisuanhuida[that.indexIng + 1].subjectList.length > 0) { // 判断下一题是否有小题
                                that.smallQuestionIndex = that.jisuanhuida[that.indexIng + 1].subjectList[0].subjectId // 有的话赋值列表的第一个
                                that.smallQuestionObj = that.jisuanhuida[that.indexIng + 1].subjectList[0]
                            } else {
                                // 没有的话，赋值父的
                                that.smallQuestionIndex = that.jisuanhuida[that.indexIng + 1].subjectId
                                that.smallQuestionObj = that.jisuanhuida[that.indexIng + 1]
                            }
                            that.indexIng = that.indexIng + 1
                        }
                    } else { // 不是最后一小题  循环小题列表
                        for (var i = 0; i < that.pageExamObj.subjectList.length; i++) {
                            console.log('smallQuestionIndex', that.smallQuestionIndex)
                            // 判断当前小题是哪个小题
                            if (that.smallQuestionIndex == that.pageExamObj.subjectList[i].subjectId) {
                                // 是的话赋值
                                that.jisuanhuida[that.indexIng].subjectList[i].doneAnswer = answerText
                                that.smallQuestionIndex = that.jisuanhuida[that.indexIng].subjectList[Number(i) + 1].subjectId
                                that.smallQuestionObj = that.jisuanhuida[that.indexIng].subjectList[Number(i) + 1]
                                return
                            }
                        }
                    }
                } else {
                    that.jisuanhuida[that.indexIng].doneAnswer = answerText
                    // 判断当前没有小题  直接获取下一大题的内容
                    if (that.indexIng + 1 >= that.jisuanhuida.length) { // 是
                        console.log('最后一题')
                        that.indexIng = 0
                        if (that.anlifenxi.length > 0) { //9
                            that.pageExamObj = that.anlifenxi[0]
                            that.checkedExamId = that.anlifenxi[0].subjectId
                            if (that.anlifenxi[0].subjectList.length > 0) {
                                that.smallQuestionObj = that.anlifenxi[0].subjectList[0]
                                that.smallQuestionIndex = that.anlifenxi[0].subjectList[0].subjectId
                            } else {
                                that.smallQuestionObj = that.anlifenxi[0]
                                that.smallQuestionIndex = that.anlifenxi[0].subjectId
                            }
                        } else {
                            if (that.jisuan.length > 0) { //10
                                that.pageExamObj = that.jisuan[0]
                                that.checkedExamId = that.jisuan[0].subjectId
                                if (that.jisuan[0].subjectList.length > 0) {
                                    that.smallQuestionObj = that.jisuan[0].subjectList[0]
                                    that.smallQuestionIndex = that.jisuan[0].subjectList[0].subjectId
                                } else {
                                    that.smallQuestionObj = that.jisuan[0]
                                    that.smallQuestionIndex = that.jisuan[0].subjectId
                                }
                            } else {
                                if (that.zonghefenxi.length > 0) { //11
                                    that.pageExamObj = that.zonghefenxi[0]
                                    that.checkedExamId = that.zonghefenxi[0].subjectId
                                    if (that.zonghefenxi[0].subjectList.length > 0) {
                                        that.smallQuestionObj = that.zonghefenxi[0].subjectList[0]
                                        that.smallQuestionIndex = that.zonghefenxi[0].subjectList[0].subjectId
                                    } else {
                                        that.smallQuestionObj = that.zonghefenxi[0]
                                        that.smallQuestionIndex = that.zonghefenxi[0].subjectId
                                    }
                                } else {
                                    that.$confirm('您已经做到最后一题了是否提交呢', '提示', {
                                        confirmButtonText: '确定',
                                        cancelButtonText: '取消',
                                        type: 'warning'
                                    }).then(() => {
                                        that.submit()
                                    }).catch(() => {

                                    });
                                }
                            }
                        }
                    } else { // 不是
                        // 下一题
                        that.pageExamObj = that.jisuanhuida[that.indexIng + 1] // 获取小一题内容
                        that.checkedExamId = that.jisuanhuida[that.indexIng + 1].subjectId // 左侧的当选状态
                        if (that.jisuanhuida[that.indexIng + 1].subjectList.length > 0) { // 判断下一题是否有小题
                            that.smallQuestionIndex = that.jisuanhuida[that.indexIng + 1].subjectList[0].subjectId // 有的话赋值列表的第一个
                            that.smallQuestionObj = that.jisuanhuida[that.indexIng + 1].subjectList[0]
                        } else {
                            // 没有的话，赋值父的
                            that.smallQuestionIndex = that.jisuanhuida[that.indexIng + 1].subjectId
                            that.smallQuestionObj = that.jisuanhuida[that.indexIng + 1]
                        }
                        that.indexIng = that.indexIng + 1
                    }
                }
            } else if (that.pageExamObj.classify == '9') {
                that.score = ''
                if (that.pageExamObj.subjectList.length > 0) { // 如果有小题
                    that.anlifenxi[that.indexIng].doneAnswer = answerText
                    if (that.smallQuestionIndex == that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId) { // 是 赋值
                        that.anlifenxi[that.indexIng].subjectList[that.pageExamObj.subjectList.length - 1].doneAnswer = answerText
                        // 判断是否为这个类型的最后一题
                        if (that.indexIng + 1 >= that.anlifenxi.length) { // 是
                            console.log('最后一题')
                            that.indexIng = 0
                            if (that.jisuan.length > 0) { //10
                                that.pageExamObj = that.jisuan[0]
                                that.checkedExamId = that.jisuan[0].subjectId
                                if (that.jisuan[0].subjectList.length > 0) {
                                    that.smallQuestionObj = that.jisuan[0].subjectList[0]
                                    that.smallQuestionIndex = that.jisuan[0].subjectList[0].subjectId
                                } else {
                                    that.smallQuestionObj = that.jisuan[0]
                                    that.smallQuestionIndex = that.jisuan[0].subjectId
                                }
                            } else {
                                if (that.zonghefenxi.length > 0) { //11
                                    that.pageExamObj = that.zonghefenxi[0]
                                    that.checkedExamId = that.zonghefenxi[0].subjectId
                                    if (that.zonghefenxi[0].subjectList.length > 0) {
                                        that.smallQuestionObj = that.zonghefenxi[0].subjectList[0]
                                        that.smallQuestionIndex = that.zonghefenxi[0].subjectList[0].subjectId
                                    } else {
                                        that.smallQuestionObj = that.zonghefenxi[0]
                                        that.smallQuestionIndex = that.zonghefenxi[0].subjectId
                                    }
                                } else {
                                    that.$confirm('您已经做到最后一题了是否提交呢', '提示', {
                                        confirmButtonText: '确定',
                                        cancelButtonText: '取消',
                                        type: 'warning'
                                    }).then(() => {
                                        that.submit()
                                    }).catch(() => {

                                    });
                                }
                            }
                        } else { // 不是
                            // 下一题
                            that.pageExamObj = that.anlifenxi[that.indexIng + 1] // 获取小一题内容
                            that.checkedExamId = that.anlifenxi[that.indexIng + 1].subjectId // 左侧的当选状态
                            if (that.anlifenxi[that.indexIng + 1].subjectList.length > 0) { // 判断下一题是否有小题
                                that.smallQuestionIndex = that.anlifenxi[that.indexIng + 1].subjectList[0].subjectId // 有的话赋值列表的第一个
                                that.smallQuestionObj = that.anlifenxi[that.indexIng + 1].subjectList[0]
                            } else {
                                // 没有的话，赋值父的
                                that.smallQuestionIndex = that.anlifenxi[that.indexIng + 1].subjectId
                                that.smallQuestionObj = that.anlifenxi[that.indexIng + 1]
                            }
                            that.indexIng = that.indexIng + 1
                        }
                    } else { // 不是最后一小题  循环小题列表
                        for (var i = 0; i < that.pageExamObj.subjectList.length; i++) {
                            console.log('smallQuestionIndex', that.smallQuestionIndex)
                            // 判断当前小题是哪个小题
                            if (that.smallQuestionIndex == that.pageExamObj.subjectList[i].subjectId) {
                                // 是的话赋值
                                that.anlifenxi[that.indexIng].subjectList[i].doneAnswer = answerText
                                that.smallQuestionIndex = that.anlifenxi[that.indexIng].subjectList[Number(i) + 1].subjectId
                                that.smallQuestionObj = that.anlifenxi[that.indexIng].subjectList[Number(i) + 1]
                                return
                            }
                        }
                    }
                } else {
                    that.anlifenxi[that.indexIng].doneAnswer = answerText
                    // 判断当前没有小题  直接获取下一大题的内容
                    if (that.indexIng + 1 >= that.anlifenxi.length) { // 是
                        console.log('最后一题')
                        that.indexIng = 0

                        if (that.jisuan.length > 0) { //10
                            that.pageExamObj = that.jisuan[0]
                            that.checkedExamId = that.jisuan[0].subjectId
                            if (that.jisuan[0].subjectList.length > 0) {
                                that.smallQuestionObj = that.jisuan[0].subjectList[0]
                                that.smallQuestionIndex = that.jisuan[0].subjectList[0].subjectId
                            } else {
                                that.smallQuestionObj = that.jisuan[0]
                                that.smallQuestionIndex = that.jisuan[0].subjectId
                            }
                        } else {
                            if (that.zonghefenxi.length > 0) { //11
                                that.pageExamObj = that.zonghefenxi[0]
                                that.checkedExamId = that.zonghefenxi[0].subjectId
                                if (that.zonghefenxi[0].subjectList.length > 0) {
                                    that.smallQuestionObj = that.zonghefenxi[0].subjectList[0]
                                    that.smallQuestionIndex = that.zonghefenxi[0].subjectList[0].subjectId
                                } else {
                                    that.smallQuestionObj = that.zonghefenxi[0]
                                    that.smallQuestionIndex = that.zonghefenxi[0].subjectId
                                }
                            } else {
                                that.$confirm('您已经做到最后一题了是否提交呢', '提示', {
                                    confirmButtonText: '确定',
                                    cancelButtonText: '取消',
                                    type: 'warning'
                                }).then(() => {
                                    that.submit()
                                }).catch(() => {

                                });
                            }
                        }
                    } else { // 不是
                        // 下一题
                        that.pageExamObj = that.anlifenxi[that.indexIng + 1] // 获取小一题内容
                        that.checkedExamId = that.anlifenxi[that.indexIng + 1].subjectId // 左侧的当选状态
                        if (that.anlifenxi[that.indexIng + 1].subjectList.length > 0) { // 判断下一题是否有小题
                            that.smallQuestionIndex = that.anlifenxi[that.indexIng + 1].subjectList[0].subjectId // 有的话赋值列表的第一个
                            that.smallQuestionObj = that.anlifenxi[that.indexIng + 1].subjectList[0]
                        } else {
                            // 没有的话，赋值父的
                            that.smallQuestionIndex = that.anlifenxi[that.indexIng + 1].subjectId
                            that.smallQuestionObj = that.anlifenxi[that.indexIng + 1]
                        }
                        that.indexIng = that.indexIng + 1
                    }
                }
            } else if (that.pageExamObj.classify == '10') {
                that.score = ''
                if (that.pageExamObj.subjectList.length > 0) { // 如果有小题
                    if (that.smallQuestionIndex == that.pageExamObj.subjectList[that.pageExamObj.subjectList.length - 1].subjectId) { // 是 赋值
                        that.jisuan[that.indexIng].subjectList[that.pageExamObj.subjectList.length - 1].doneAnswer = answerText
                        // 判断是否为这个类型的最后一题
                        if (that.indexIng + 1 >= that.jisuan.length) { // 是
                            console.log('最后一题')
                            that.indexIng = 0
                            if (that.zonghefenxi.length > 0) { //11
                                that.pageExamObj = that.zonghefenxi[0]
                                that.checkedExamId = that.zonghefenxi[0].subjectId
                                if (that.zonghefenxi[0].subjectList.length > 0) {
                                    that.smallQuestionObj = that.zonghefenxi[0].subjectList[0]
                                    that.smallQuestionIndex = that.zonghefenxi[0].subjectList[0].subjectId
                                } else {
                                    that.smallQuestionObj = that.zonghefenxi[0]
                                    that.smallQuestionIndex = that.zonghefenxi[0].subjectId
                                }
                            } else {
                                that.$confirm('您已经做到最后一题了是否提交呢', '提示', {
                                    confirmButtonText: '确定',
                                    cancelButtonText: '取消',
                                    type: 'warning'
                                }).then(() => {
                                    that.submit()
                                }).catch(() => {

                                });
                            }
                        } else { // 不是
                            // 下一题
                            that.pageExamObj = that.jisuan[that.indexIng + 1] // 获取小一题内容
                            that.checkedExamId = that.jisuan[that.indexIng + 1].subjectId // 左侧的当选状态
                            if (that.jisuan[that.indexIng + 1].subjectList.length > 0) { // 判断下一题是否有小题
                                that.smallQuestionIndex = that.jisuan[that.indexIng + 1].subjectList[0].subjectId // 有的话赋值列表的第一个
                                that.smallQuestionObj = that.jisuan[that.indexIng + 1].subjectList[0]
                            } else {
                                // 没有的话，赋值父的
                                that.smallQuestionIndex = that.jisuan[that.indexIng + 1].subjectId
                                that.smallQuestionObj = that.jisuan[that.indexIng + 1]
                            }
                            that.indexIng = that.indexIng + 1
                        }
                    } else { // 不是最后一小题  循环小题列表
                        for (var i = 0; i < that.pageExamObj.subjectList.length; i++) {
                            console.log('smallQuestionIndex', that.smallQuestionIndex)
                            // 判断当前小题是哪个小题
                            if (that.smallQuestionIndex == that.pageExamObj.subjectList[i].subjectId) {
                                // 是的话赋值
                                that.jisuan[that.indexIng].subjectList[i].doneAnswer = answerText
                                that.smallQuestionIndex = that.jisuan[that.indexIng].subjectList[Number(i) + 1].subjectId
                                that.smallQuestionObj = that.jisuan[that.indexIng].subjectList[Number(i) + 1]
                                return
                            }
                        }
                    }
                } else {
                    that.jisuan[that.indexIng].doneAnswer = answerText
                    // 判断当前没有小题  直接获取下一大题的内容
                    if (that.indexIng + 1 >= that.jisuan.length) { // 是
                        console.log('最后一题')
                        that.indexIng = 0
                        if (that.zonghefenxi.length > 0) { //11
                            that.pageExamObj = that.zonghefenxi[0]
                            that.checkedExamId = that.zonghefenxi[0].subjectId
                            if (that.zonghefenxi[0].subjectList.length > 0) {
                                that.smallQuestionObj = that.zonghefenxi[0].subjectList[0]
                                that.smallQuestionIndex = that.zonghefenxi[0].subjectList[0].subjectId
                            } else {
                                that.smallQuestionObj = that.zonghefenxi[0]
                                that.smallQuestionIndex = that.zonghefenxi[0].subjectId
                            }
                        } else {
                            that.$confirm('您已经做到最后一题了是否提交呢', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                that.submit()
                            }).catch(() => {

                            });
                        }
                    } else { // 不是
                        // 下一题
                        that.pageExamObj = that.jisuan[that.indexIng + 1] // 获取小一题内容
                        that.checkedExamId = that.jisuan[that.indexIng + 1].subjectId // 左侧的当选状态
                        if (that.jisuan[that.indexIng + 1].subjectList.length > 0) { // 判断下一题是否有小题
                            that.smallQuestionIndex = that.jisuan[that.indexIng + 1].subjectList[0].subjectId // 有的话赋值列表的第一个
                            that.smallQuestionObj = that.jisuan[that.indexIng + 1].subjectList[0]
                        } else {
                            // 没有的话，赋值父的
                            that.smallQuestionIndex = that.jisuan[that.indexIng + 1].subjectId
                            that.smallQuestionObj = that.jisuan[that.indexIng + 1]
                        }
                        that.indexIng = that.indexIng + 1
                    }
                }
            } else if (that.pageExamObj.classify == '11') {
                that.score = ''
                if (that.indexIng + 1 >= that.zonghefenxi.length) {
                    that.$confirm('您已经做到最后一题了是否提交呢', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        that.submit()
                    }).catch(() => {

                    });
                } else {
                    that.pageExamObj = that.zonghefenxi[that.indexIng + 1]
                    that.checkedExamId = that.zonghefenxi[that.indexIng + 1].subjectId
                    if (that.zonghefenxi[that.indexIng + 1].subjectList.length > 0) { // 判断下一题是否有小题
                        that.smallQuestionIndex = that.zonghefenxi[that.indexIng + 1].subjectList[0].subjectId // 有的话赋值列表的第一个
                        that.smallQuestionObj = that.zonghefenxi[that.indexIng + 1].subjectList[0]
                    } else {
                        // 没有的话，赋值父的
                        that.smallQuestionIndex = that.zonghefenxi[that.indexIng + 1].subjectId
                        that.smallQuestionObj = that.zonghefenxi[that.indexIng + 1]
                    }
                    that.indexIng = that.indexIng + 1
                }
            }
            that.$nextTick(()=>{
                that.update_size();
            })
        },
        // 交卷
        submit() {
            this.tab_save_subject();
            var that = this
            if (that.tabIndex == '1' || that.tabIndex == '2') {
                var obj = {
                    nodeId: that.examInfo.nodeId,
                    nodeType: that.tabIndex,
                }

                var num = null
                obj.nodeId && assignment_exercise(obj).then(res => {
                    if (res.data.code == 0) {
                        if (that.tabIndex == '1' || that.tabIndex == '2') {
                            num = 1
                        } else {
                            num = 2
                        }
                        var testReportInfo = {
                            examInfo: that.examInfo,
                            num: num,
                            twoIindex: that.twoIindex,
                            tabIndex: that.tabIndex,
                            firstId: that.firstId,
                            secondId: that.secondId,
                        }
                        localStorage.setItem("testReport", JSON.stringify(testReportInfo))
                        that.$router.push({
                            path: '/tikuReport',
                            query: {
                                nodeId: that.examInfo.nodeId,
                                nodeType: that.tabIndex,
                                // testPaperId: that.examInfo.testPaperId,
                            }
                        });
                        // that.$router.push({
                        //   path: '/testReport',
                        //   query: {
                        //     examInfo: JSON.stringify(that.examInfo),
                        //     nodeType: that.tabIndex,
                        //     nodeId: that.examInfo.nodeId,
                        //     num: num,
                        //     twoIindex: that.twoIindex,
                        //     firstId: that.firstId,
                        //     secondId: that.secondId,
                        //     tabIndex: that.tabIndex
                        //   }
                        // })
                        // that.$router.go(-1)
                    } else {
                        that.$message.error(res.data.msg)
                    }
                }).catch(error => {
                    console.log(error)
                })
            } else {
                if (that.tabIndex == '1' || that.tabIndex == '2') {
                    num = 1
                } else {
                    num = 2
                }
                var obj = {
                    testPaperId: that.examInfo.testPaperId
                    // nodeId:that.examInfo.nodeId,
                    // nodeType:that.tabIndex,
                }
                assignment_paper(obj).then(res => {
                    if (res.data.code == 0) {
                        var testReportInfo = {
                            examInfo: that.examInfo,
                            num: num,
                            twoIindex: that.twoIindex,
                            tabIndex: that.tabIndex,
                            firstId: that.firstId,
                            secondId: that.secondId,
                            testPaperId: that.examInfo.testPaperId,
                        }
                        localStorage.setItem("testReport", JSON.stringify(testReportInfo))
                        that.$router.push({
                            path: '/tikuReport',
                            query: {
                                // nodeId : item.nodeId,
                                // nodeType : that.tabIndex,
                                testPaperId: that.examInfo.testPaperId,
                            }
                        });
                        // that.$router.push({
                        //   path: '/testReport',
                        //   query: {
                        //     testPaperId: that.examInfo.testPaperId,
                        //     // item:JSON.stringify(item),
                        //     examInfo: JSON.stringify(that.examInfo),
                        //     num: num,
                        //     twoIindex: that.twoIindex,
                        //     firstId: that.firstId,
                        //     secondId: that.secondId,
                        //     tabIndex: that.tabIndex
                        //   }
                        // })
                        // that.$router.go(-1)
                    } else {
                        that.$message.error(res.data.msg)
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        // 获取解析题列表
        getAnalysisList() {
            var obj = {
                nodeId: this.examInfo.nodeId,
                nodeType: this.tabIndex,
                type: 2
            }
            analysis_list(obj).then(res => {
                console.log(res)
                if (res.data.code == 0) {
                    this.analysisTestList = res.data.data
                } else {
                    this.$message.error(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 时间计时
        timer() { //定义计时函数
            this.second = Number(this.second) + 1
            this.second_time = Number(this.second_time) + 1 //秒
            if (this.second >= 60) {
                this.second = 0
                this.minute = this.minute + 1 //分钟
            }

            if (this.minute >= 60) {
                this.minute = 0
                this.hour = this.hour + 1 //小时
            }
            this.str = this.toDub(this.hour) + ':' + this.toDub(this.minute) + ':' + this.toDub(this.second) /*+""+this.toDubms(this.ms)+"毫秒"*/
            // document.getElementById('mytime').innerHTML=h+"时"+m+"分"+s+"秒"+ms+"毫秒";
        },
        // 倒计时
        deltimer() {
            if (this.testSecond == 0 && this.testMinute == 0 && this.testHour == 0) {
                clearInterval(this.time)

                this.submit()
            } else {
                this.testSecond_time = Number(this.testSecond_time) + 1 //秒
                this.testSecond = Number(this.testSecond) - 1
                if (this.testSecond <= 0) {
                    this.testSecond = 59
                    if (this.testMinute > 0) {
                        this.testMinute = this.testMinute - 1 //分钟   
                    }
                }

                if (this.testMinute <= 0 && this.testHour > 0) {
                    this.testMinute = 59
                    this.testHour = this.testHour - 1 //小时
                }
                this.str = this.toDub(this.testHour) + ':' + this.toDub(this.testMinute) + ':' + this.toDub(this.testSecond) /*+""+this.toDubms(this.ms)+"毫秒"*/
                // document.getElementById('mytime').innerHTML=h+"时"+m+"分"+s+"秒"+ms+"毫秒";
            }
        },
        toDub(n) { //补0操作
            if (n < 10) {
                return '0' + n
            } else {
                return '' + n
            }
        },
    }
}
</script>

<style lang="less" scoped>
 #update_font_size {
    /deep/ font{
        size:4px;
    }
 } 
</style>
<style>

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.textWrap {
    word-wrap: break-word;
    white-space: pre-wrap;
}

.subjectTitlePic {
    max-height: 100px;
}

.examText_main_right_synthesis_question_text {
    margin-left: 1em;
}

.examText_main_right_operate_other p span {
    margin-left: 0px !important;
}

input {
    width: 50px;
    height: 50px;
    border: 1px solid black;
    line-height: 50px;
    text-align: center;
    border-radius: 10px;
    /* background-color: gainsboro; */
    outline: none;
}

table {
    /* background-color: #b3d7ff; */
    /* margin: auto; */
}

.el-popover {
    width: 212px !important;
}

.answerPic {
    width: 100px;
    max-height: 100px;
}

.f-v-c {
    display: flex;
    align-items: center;
}

.f-v-c .el-checkbox__label {
    display: flex;
    align-items: center;
}
</style>